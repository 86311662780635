import React, { useState, useEffect } from 'react';
import { Input, Form, Card, notification, Icon } from 'antd';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import AntdButton from 'component/elements/AntdButton';
import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_PASSWORD_MUTATION } from 'apollo/user/mutations';
import { removeUserData, removeToken } from 'helper';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #202541, #647af5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div``;

const FormTitle = styled.div`
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  color: #4d4d4d;
`;

const ChangePasswordForm = ({ form }) => {
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState<any>(null);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined);
  const [err, setErr] = useState<string>();
  const [load, setLoad] = useState<boolean>();
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let token = queryParams.get('token');
      // console.log('token ', token);
      setToken(token);
    }
  }, [location]);
  const onChange = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const handleSubmit = () => {
    setLoad(true);
    form
      .validateFields()
      .then(done => {
        changePassword({
          variables: {
            token: token,
            password: password,
          },
        })
          .then(async result => {
            console.log('done>>>>>>', result);
            setLoad(false);
            if (result.data.changePassword) {
              notification.success({
                message: 'Success',
                description: 'Password changed successfully. Please wait redirecting to login page.',
              });
              if (token) {
                setTimeout(function () {
                  removeToken();
                  removeUserData();
                  history.push('/login');
                }, 2000);
              }
            } else {
            }
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
            }
            setLoad(false);
          });
      })
      .catch(err => {
        console.log('err', err);
        setLoad(false);
      });
  };
  return (
    <LoginWrapper>
      <FormWrapper>
        <Card
          title={<FormTitle>Change Password</FormTitle>}
          style={{
            width: '400px',
            borderRadius: '15px',
            backgroundColor: '#fff',
          }}
        >
          <p style={{ color: '#f00', marginBottom: '10px' }}>
            <strong>{err}</strong>
          </p>
          <Form layout="vertical" form={form} name="login">
            <Form.Item hasFeedback label="Password" labelAlign="left">
              {form.getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Enter your password' },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(
                <Input.Password
                  placeholder="Enter new password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  onChange={e => onChange(e.target.value, setPassword)}
                />
              )}
            </Form.Item>
            <Form.Item hasFeedback label="Confirm Password" labelAlign="left">
              {form.getFieldDecorator('confirmPassword', {
                rules: [
                  { required: true, message: 'Enter your password' },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  placeholder="Renter password"
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  onPressEnter={handleSubmit}
                  onChange={e => onChange(e.target.value, setConfirmPassword)}
                />
              )}
            </Form.Item>
            {/* <Form.Item
              hasFeedback
              label="Password"
              name="password"
              labelAlign="left"
              rules={[{ required: true, message: 'Enter your new password' }]}
            >
              <Input.Password
                placeholder="Enter new password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={e => onChange(e.target.value, setPassword)}
                onPressEnter={handleSubmit}
              />
            </Form.Item> */}
            {/* <Form.Item
              dependencies={['password']}
              hasFeedback
              label="Confirm Password"
              name="confirmPassword"
              labelAlign="left"
              rules={[
                { required: true, message: 'Renter your password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Renter password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onPressEnter={handleSubmit}
                onChange={e => onChange(e.target.value, setConfirmPassword)}
              />
            </Form.Item> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AntdButton type="primary" onClick={handleSubmit} loading={load} style={{ marginLeft: '1em' }}>
                Submit
              </AntdButton>
            </div>
          </Form>
        </Card>
      </FormWrapper>
    </LoginWrapper>
  );
};
const ChangePassword = Form.create()(ChangePasswordForm);

export default ChangePassword;
