import React from 'react';
import AntdTable from 'component/elements';
import { Button, Popconfirm, Icon } from 'antd';
import { getUserData } from 'helper';

const ActivityTable = ({ onEdit, onDelete, activities, tableLoading }) => {
  const loggedInUser = JSON.parse(getUserData());

  const columns = [
    {
      key: 'name',
      title: 'Activity',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.name}
        </Button>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    ...(loggedInUser.role === 'superadmin'
      ? [
          {
            key: 'organizationName',
            title: 'Org Name',
            render: record => <span>{record.organization && record.organization.name}</span>,
          },
        ]
      : [{}]),
    {
      key: 'displayOrder',
      title: 'Display Order',
      render: record => <span>{record.displayOrder || 'Not Set'}</span>,
      sorter: (a, b) => a.displayOrder - b.displayOrder,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'showOnHome',
      title: 'Show On Home',
      render: record => <>{record.showOnHome ? <Icon type="check" style={{ color: 'green' }} /> : <Icon type="close" style={{ color: 'red' }} />}</>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <span>
            <Button type="primary" onClick={() => onEdit(record)}>
              Edit
            </Button>
          </span>
          <span style={{ marginLeft: '1em' }}>
            <Popconfirm title="Are you sure delete this activity?" onConfirm={() => onDelete(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];

  return <AntdTable loading={tableLoading} pagination={false} rowKey={record => record._id} columns={columns} dataSource={activities} />;
};

export default ActivityTable;
