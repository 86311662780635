import { gql } from 'apollo-boost';

export const ACTIVITY_FRAGMENT = gql`
  fragment Activity on Activity {
    _id
    name
    displayOrder
    organizationId
    showOnHome
    organization {
      _id
      name
    }
    equipments
    createdAt
    updatedAt
  }
`;
