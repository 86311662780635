const config = {
  locale: window.localStorage && window.localStorage.getItem ? window.localStorage.getItem('locale') : 'en',
  IS_CONSOLE_ALLOWED: true,
  WEBSITE_URL: 'https://techdurbar.com',
  BASE_URL: process.env.API_ENDPOINT || 'https://videoapi.tpasc.ca',
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT || 'https://videoapi.tpasc.ca/graphql',
  // GRAPHQL_ENDPOINT: 'https://8ff7cb811bab.ngrok.io/graphql',
  // GRAPHQL_ENDPOINT: 'http://localhost:5000/graphql',
  GRAPHQL_SUBSCRIPTION_ENDPOINT: 'wss://traffic.tpasc.ca/subscriptions',
};

if (process.env.NODE_ENV === 'production') {
  config.IS_CONSOLE_ALLOWED = false;
  //   config.GRAPHQL_ENDPOINT = "https://app.dawwati.com/graphql";
  //   config.GRAPHQL_SUBSCRIPTION_ENDPOINT = "wss://app.dawwati.com/subscriptions";
}
// console.log(process.env); // eslint-disable-line
if (process.env.REACT_APP_SERVER_TEST) {
  config.GRAPHQL_ENDPOINT = 'http://localhost:5000/graphql';
  config.BASE_URL = 'http://localhost:5000';
  config.GRAPHQL_SUBSCRIPTION_ENDPOINT = 'ws://localhost:5000/subscriptions';
}

export default config;
