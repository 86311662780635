import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const ALL_SHARED_VIDEOS_QUERY = gql`
  query allSharedVideos(
    $paginationInput: PaginationInput
    $videosPageFilterInput: VideosPageFilterInput
    $sorterInput: SorterInput
    $organizationId: String
  ) {
    allSharedVideos(
      paginationInput: $paginationInput
      videosPageFilterInput: $videosPageFilterInput
      sorterInput: $sorterInput
      organizationId: $organizationId
    ) {
      pagination {
        page
        limit
        total
      }
      videos {
        ...Video
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
