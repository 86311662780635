import { gql } from 'apollo-boost';

export const COMMENTS_QUERY = gql`
  query comments($commentsFilterInput: CommentsFilterInput, $paginationInput: PaginationInput, $sorterInput: SorterInput, $organizationId: String) {
    comments(
      commentsFilterInput: $commentsFilterInput
      paginationInput: $paginationInput
      sorterInput: $sorterInput
      organizationId: $organizationId
    ) {
      pagination {
        page
        limit
        total
      }
      comments {
        _id
        content
        totalLikes
        totaldisLikes
        isLiked
        isdisLiked
        name
        picUrl
        video {
          _id
          title
        }
        organizationId
        createdAt
      }
    }
  }
`;
