import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Layout from '../../component/Layout';
import { Switch, Route } from 'react-router-dom';
import Error404 from '../Error404';
import ErrorLog from '../../view/Main/errorLog';
import OrganizationSettingsContainer from 'view/Main/Settings/OrganizationSettings';
import { ME_QUERY } from 'apollo/user/queries';
import LoadingSpinner from 'component/Loader';
import VideoUploadContainer from 'view/Main/videoUpload';
import ActivityContainer from 'view/Main/Activity';
import TrainerContainer from 'view/Main/Trainer';
import VideosContainer from 'view/Main/videos';
import CalendarContainer from 'view/Main/Calendar';
import UsersContainer from 'view/Main/Users/Users';
import AnalyticsContainer from 'view/Main/analytics/analytics';
import OrganizationsContainer from 'view/Main/Organizations/Organizations';
import VideoEditContainer from 'view/Main/VideoEdit/VideoEdit';
import SharedVideosContainer from 'view/Main/SharedVideos/SharedVideos';
import MyPortalContainer from 'view/Main/MyPortal';
import MyVideosContainer from 'view/Main/MyVideos/MyVideos';
import AllVideosContainer from 'view/Main/AllVideos';
import WaiverContainer from 'view/Main/Waiver';
import CommentsContainer from 'view/Main/Comments/Comments';
import OrganizationBillingContainer from 'view/Main/OrganizationBilling';
import MembershipContainer from 'view/Main/Membership';
import AttemptedLoginsContainer from 'view/Main/AttemptedLogins';
import GodContainer from 'view/Main/God';
import OrgAnnouncements from 'view/Main/OrgAnnouncements/OrgAnnouncements';

interface Props {}

const AllComponents = {
  VideosContainer,
  VideoUploadContainer,
  VideoEditContainer,
  ActivityContainer,
  TrainerContainer,
  OrganizationSettingsContainer,
  ErrorLog,
  Error404,
  CalendarContainer,
  UsersContainer,
  AnalyticsContainer,
  OrganizationsContainer,
  SharedVideosContainer,
  MyPortalContainer,
  MyVideosContainer,
  AllVideosContainer,
  WaiverContainer,
  CommentsContainer,
  OrganizationBillingContainer,
  MembershipContainer,
  AttemptedLoginsContainer,
};

const GodRouterDefs = () => {
  return (
    <Switch>
      <Route path="/videos" component={VideosContainer} />
      <Route path="/sharedvideos" component={SharedVideosContainer} />
      <Route path="/videoupload" component={VideoUploadContainer} />
      <Route path="/attemptedlogins" component={AttemptedLoginsContainer} />
      <Route path="/activity" component={ActivityContainer} />
      <Route path="/trainer" component={TrainerContainer} />
      <Route path="/calendar" component={CalendarContainer} />
      <Route path="/users" component={UsersContainer} />
      <Route path="/analytics" component={AnalyticsContainer} />
      <Route path="/organizations" component={OrganizationsContainer} />
      <Route path="/comments" component={CommentsContainer} />
      <Route path="/organizationBilling/:organizationId" component={OrganizationBillingContainer} />
      <Route path="/membership" component={MembershipContainer} />
      {/* <Route path="/videoedit" component={VideoEditContainer} /> */}
      <Route path="/god" component={GodContainer} />
      <Route path="/error_logs" component={ErrorLog} />
      <Route exact path="/" component={VideosContainer} />
      <Route component={Error404} />
    </Switch>
  );
};
const SuperadminRouterDefs = () => {
  return (
    <Switch>
      <Route path="/videos" component={VideosContainer} />
      <Route path="/sharedvideos" component={SharedVideosContainer} />
      <Route path="/videoupload" component={VideoUploadContainer} />
      <Route path="/attemptedlogins" component={AttemptedLoginsContainer} />
      {/* <Route path="/videoedit" component={VideoEditContainer} /> */}
      <Route path="/activity" component={ActivityContainer} />
      <Route path="/trainer" component={TrainerContainer} />
      <Route path="/calendar" component={CalendarContainer} />
      <Route path="/users" component={UsersContainer} />
      <Route path="/analytics" component={AnalyticsContainer} />
      <Route path="/organizations" component={OrganizationsContainer} />
      <Route path="/comments" component={CommentsContainer} />
      <Route path="/organizationBilling/:organizationId" component={OrganizationBillingContainer} />
      <Route path="/membership" component={MembershipContainer} />
      <Route path="/organnouncements" component={OrgAnnouncements} />
      <Route path="/error_logs" component={ErrorLog} />
      <Route exact path="/" component={VideosContainer} />
      <Route component={Error404} />
    </Switch>
  );
};
const AdminRouterDefs = () => {
  return (
    <Switch>
      <Route path="/myvideos" component={MyVideosContainer} />
      <Route path="/myportal" component={MyPortalContainer} />
      <Route path="/allvideos" component={AllVideosContainer} />
      <Route path="/videoupload" component={VideoUploadContainer} />
      <Route path="/attemptedlogins" component={AttemptedLoginsContainer} />
      {/* <Route path="/videoedit" component={VideoEditContainer} /> */}
      <Route path="/activity" component={ActivityContainer} />
      <Route path="/trainer" component={TrainerContainer} />
      <Route path="/calendar" component={CalendarContainer} />
      <Route path="/settings" component={OrganizationSettingsContainer} />
      <Route path="/users" component={UsersContainer} />
      <Route path="/waiver" component={WaiverContainer} />
      <Route path="/analytics" component={AnalyticsContainer} />
      <Route path="/comments" component={CommentsContainer} />
      <Route path="/membership" component={MembershipContainer} />
      <Route path="/error_logs" component={ErrorLog} />
      <Route exact path="/" component={MyVideosContainer} />
      <Route component={Error404} />
    </Switch>
  );
};

// const StaffRouterDefs = () => {
//   return (
//     <Switch>
//       <Route path="/settings" component={OrganizationSettingsContainer} />
//       <Route path="/error_logs" component={ErrorLog} />
//       <Route exact path="/" component={FlaggedBookingsContainer} />
//       <Route component={Error404} />
//     </Switch>
//   );
// };

const ProtectedRoutes = (props: Props) => {
  const [skip, setSkip] = useState(false);
  const [menu, setMenu] = useState([]);

  const { loading, error, data } = useQuery(ME_QUERY, { skip });

  const makeRoutes = enabledRoutes => {
    return (
      <Switch>
        {enabledRoutes.map(
          (route, index) => route.component && <Route key={route.title} path={route.path} exact component={AllComponents[route.component]} />
        )}
        <Route component={Error404} />
      </Switch>
    );
  };
  // useEffect(() => {
  //   console.log("-----Protected Routes-----", data);
  //   if (!loading && !!data) {
  //     console.log("--data---", data);
  //     // setSkip(true);
  //   }
  // }, [data, loading]);

  if (loading) return <LoadingSpinner />;
  if (error) return `Problem`;

  return (
    <Layout menu={menu}>
      {data.me.role == 'god'
        ? GodRouterDefs()
        : data.me.role == 'superadmin'
        ? SuperadminRouterDefs()
        : data.me.role == 'admin'
        ? AdminRouterDefs()
        : data.me.enabledRoutes && makeRoutes(data.me.enabledRoutes)}
    </Layout>
  );
};

export default ProtectedRoutes;
