import React, { useEffect, useState } from 'react';
import { LayoutProps } from './interface';
import { Layout as AntdLayout } from 'antd';
import Sidebar from './Sidebar';
import Header from './Header';
import { StyledLayout, StyledContent } from './LayoutStyles';
import Scrollbar from 'react-smooth-scrollbar';
import { useLazyQuery } from '@apollo/react-hooks';
import { ORGANIZATION_QUERY } from 'apollo/organization/queries';
import { getUserData } from 'helper';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [collapse, setCollapse] = useState(false);
  const [user, setUser] = useState(JSON.parse(getUserData()));
  const [organization, setOrganization] = useState<any>(null);
  const [getOrganization] = useLazyQuery(ORGANIZATION_QUERY, {
    onCompleted: data => {
      setOrganization(data.organization);
    },
  });
  useEffect(() => {
    if (user && user.role != 'superadmin' && user.role !== 'god') {
      getOrganization();
    }
  }, [user]);
  return (
    <StyledLayout>
      {(organization || user.role == 'superadmin' || user.role == 'god') && (
        <>
          <Sidebar
            collapse={collapse}
            organization={organization}
            // menu={menu}
          />
          <AntdLayout>
            <Header toggleSidebar={setCollapse} collapse={collapse} organization={organization} user={user} />
            <Scrollbar className="content-scrollbar" style={{ height: '100%' }}>
              <StyledContent>{children}</StyledContent>
            </Scrollbar>
          </AntdLayout>
        </>
      )}
    </StyledLayout>
  );
};

export default Layout;
