import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, message, notification } from 'antd';
import AddActivityModal from 'component/AddActivityModal/AddActivityModal';
import ActivityTable from 'component/ActivityTable/ActivityTable';
import { getUserData } from 'helper';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import DragSortingTable from 'component/DragSortingTable/DragSortingTable';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CHANGE_DISPLAY_ORDER_MUTATION, DELETE_ACTIVITY_MUTATION } from 'apollo/activity/mutations';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import LoadingSpinner from 'component/Loader';

const ActivityContainer = () => {
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [totalActivity, setTotalActivity] = useState(0);
  const [isCreateActivity, setIsCreateActivity] = useState(true);
  const [currentActivity, setCurrentActivity] = useState(false);
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [editOrder, setEditOrder] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const sortTableRef = useRef();

  const [changeOrderMutation] = useMutation(CHANGE_DISPLAY_ORDER_MUTATION, {
    refetchQueries: ['activities'],
    awaitRefetchQueries: true,
  });
  const [deleteActivity] = useMutation(DELETE_ACTIVITY_MUTATION, { refetchQueries: ['activities'] });
  const onDeleteActivity = id => {
    message.loading({ content: 'Deleting activity.', key: 'deleteMessage', duration: 0 });
    deleteActivity({ variables: { _id: id } })
      .then(data => {
        // message.success({ content: 'Activity Deleted Successfully', key: 'deleteMessage', duration: 2 });
        message.destroy();
        notification.success({
          message: 'Activity Deleted',
          duration: 0,
          description: 'Activity was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        notification.error({
          message: 'Activity Delete Failed',
          duration: 0,
          description: 'Activity was not deleted.',
        });
        // message.error({ content: 'Activity Delete Failed', key: 'deleteMessage', duration: 2 });
      });
  };

  const { loading, error, data } = useQuery(ACTIVITIES_QUERY, {
    variables: {
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
  });
  useEffect(() => {
    if (data && data.activities) {
      setTotalActivity(data.activities.length);
    }
  }, [data]);

  const openAddActivityModal = () => {
    setShowAddActivityModal(true);
  };

  const handleActivityModalCancel = () => {
    setIsCreateActivity(true);
    setCurrentActivity(false);
    setShowAddActivityModal(false);
  };

  const onEditActivity = selectedActivity => {
    setIsCreateActivity(false);
    setCurrentActivity(selectedActivity);
    openAddActivityModal();
  };

  if (loading) return <LoadingSpinner />;

  const onEditApplyOrder = () => {
    if (!editOrder) {
      setEditOrder(true);
    }
    if (editOrder) {
      let dd: any = null;
      if (sortTableRef) {
        dd = (sortTableRef as any).current.getData();
      }
      setTableLoading(true);
      const input = dd.map((item, index) => ({ _id: item._id, displayOrder: index + 1, showOnHome: item.showOnHome }));
      setEditOrder(false);
      changeOrderMutation({
        variables: {
          input,
        },
      }).then(mutationRes => {
        setTableLoading(false);
      });
    }
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          {!editOrder && (
            <Button type="primary" onClick={openAddActivityModal}>
              Add New Activity
            </Button>
          )}
          {user.role !== 'superadmin' && (
            <Button style={{ marginLeft: editOrder ? 0 : 15 }} type="primary" onClick={onEditApplyOrder}>
              {editOrder ? 'Apply' : 'Edit Order'}
            </Button>
          )}
          {editOrder && (
            <Button style={{ marginLeft: 15 }} type="danger" onClick={() => setEditOrder(false)}>
              {editOrder && 'Cancel'}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={22} offset={1}>
          {editOrder ? (
            <DragSortingTable ref={sortTableRef as any} data={data.activities} tableLoading={tableLoading} />
          ) : (
            <ActivityTable tableLoading={tableLoading} onEdit={onEditActivity} activities={data.activities} onDelete={onDeleteActivity} />
          )}
        </Col>
      </Row>
      <AddActivityModal
        modalVisible={showAddActivityModal}
        isCreate={isCreateActivity}
        currentItem={currentActivity}
        handleOk={handleActivityModalCancel}
        handleCancel={handleActivityModalCancel}
        totalActivity={totalActivity}
      />
    </>
  );
};
export default ActivityContainer;
