import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const ORGANIZATION_UPDATE_MUTATION = gql`
  mutation organizationUpdate($_id: ID!, $input: OrganizationUpdateInput!) {
    organizationUpdate(_id: $_id, input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
