import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditCustomBubbleModal from 'component/AddEditCustomBubblesModal/AddEditCustomBubblesModal';

const CustomBubblesTable = ({ customBubbles, onAdd, onDelete, onEdit }) => {
  const [showAddEditCustomBubbleModal, setShowAddEditCustomBubbleModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedCustomBubble, setSelectedCustomBubble] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'type',
      title: 'Type',
      render: record => <p>{record.bubbleType}</p>,
    },
    {
      key: 'name',
      title: 'Name',
      render: record => <p>{record.bubbleType === 'tag' ? record.tag : record.bubbleContent?.name}</p>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedCustomBubble(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditCustomBubbleModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditCustomBubbleModal = () => {
    setSelectedCustomBubble(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditCustomBubbleModal(false);
  };

  const onAddCustomBubble = customBubble => {
    const { bubbleType, bubbleContent } = customBubble;
    const temp = {
      bubbleType: bubbleType,
      ...(bubbleType === 'tag'
        ? { tag: bubbleContent }
        : {
            modelType: bubbleType,
            bubbleContent: {
              _id: bubbleContent.key,
              name: bubbleContent.label,
            },
          }),
    };
    onAdd(temp);
    closeAddEditCustomBubbleModal();
  };
  const onEditCustomBubble = customBubble => {
    const { bubbleType, bubbleContent } = customBubble;
    const temp = {
      bubbleType: bubbleType,
      ...(bubbleType === 'tag'
        ? { tag: bubbleContent }
        : {
            modelType: bubbleType,
            bubbleContent: {
              _id: bubbleContent.key,
              name: bubbleContent.label,
            },
          }),
    };
    onEdit(temp, selectedIndex);
    closeAddEditCustomBubbleModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditCustomBubbleModal(true)}>
        Add Custom Bubble
      </Button>
      <AntdTable
        pagination={false}
        rowKey={record => `${record.bubbleType}${record.bubbleType == 'tag' ? record.tag : record.bubbleContent?._id}`}
        columns={columns}
        dataSource={customBubbles}
        size="small"
      />
      <AddEditCustomBubbleModal
        visible={showAddEditCustomBubbleModal}
        currentItem={selectedCustomBubble}
        isCreate={isCreate}
        onAdd={onAddCustomBubble}
        onEdit={onEditCustomBubble}
        onCancel={closeAddEditCustomBubbleModal}
      />
    </div>
  );
};

export default CustomBubblesTable;
