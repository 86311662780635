import { gql } from 'apollo-boost';

export const ANALYTICS_VIDEO_REPORT_PERMONTH_QUERY = gql`
  query analyticsVideoReportPermonth($startDate: String!, $endDate: String!, $organizationId: String, $analyticsFilterInput: AnalyticsFilterInput) {
    analyticsVideoReportPermonth(
      startDate: $startDate
      endDate: $endDate
      organizationId: $organizationId
      analyticsFilterInput: $analyticsFilterInput
    )
  }
`;
