import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const ORGANIZATIONS_QUERY = gql`
  query organizations {
    organizations {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
