import { gql } from 'apollo-boost';

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on Organization {
    _id
    name
    provider
    email
    country
    city
    status
    logo
    subdomain
    customData
    settings
    phoneNumber
    description
    theme
    isProduction
    createdAt
    updatedAt
  }
`;
