import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, Select, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { CREATE_TRAINER_MUTATION, UPDATE_TRAINER_MUTATION } from 'apollo/trainer/mutations';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { getUserData } from 'helper';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';

const { Option } = Select;

interface TrainerModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddTrainerModalComponent: React.FC<TrainerModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [createTrainer] = useMutation(CREATE_TRAINER_MUTATION, {
    update(cache, { data: { createTrainer } }) {
      const cachedData: any = cache.readQuery({ query: TRAINERS_QUERY });
      cache.writeQuery({
        query: TRAINERS_QUERY,
        data: { trainers: [...cachedData.trainers, createTrainer] },
      });
    },
    refetchQueries: ['trainers'],
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => setOrganizations(data.organizations),
  });
  const [organizations, setOrganizations] = useState<any>(null);
  const [self, setSelf] = useState(JSON.parse(getUserData()) || null);
  const [updateTrainer] = useMutation(UPDATE_TRAINER_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [trainerParam, setTrainerParam] = useState({
    name: '',
    trainerPicUrl: '',
    trainerDetailUrl: '',
    trainerEmail: '',
    organizationId: '',
  });

  useEffect(() => {
    if (currentItem) {
      setTrainerParam(prev => ({
        ...prev,
        name: currentItem.name,
        trainerPicUrl: currentItem.trainerPicUrl || '',
        trainerDetailUrl: currentItem.trainerDetailUrl || '',
        trainerEmail: currentItem.trainerEmail || '',
        organizationId: currentItem.organizationId,
      }));
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setTrainerParam({
      name: '',
      trainerPicUrl: '',
      trainerDetailUrl: '',
      organizationId: '',
      trainerEmail: '',
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        input: {
          name: values.name,
          trainerDetailUrl: values.trainerDetailUrl,
          trainerPicUrl: values.trainerPicUrl,
          trainerEmail: values.trainerEmail,
        },
        ...(self && self.role === 'superadmin' ? (isCreate ? { organizationId: values.organizationId } : {}) : {}),
      };
      if (isCreate) {
        createTrainer({
          variables: {
            ...params,
          },
        })
          .then(mutationResult => {
            setIsLoading(false);
            notification.success({
              message: 'Trainer Created',
              duration: 0,
              description: 'Trainer was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Trainer Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateTrainer({
          variables: {
            ...params,
            _id: currentItem._id,
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            notification.success({
              message: 'Trainer Updated',
              duration: 0,
              description: 'Trainer was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Trainer Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Trainer' : 'Update Trainer Detail'}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: trainerParam.name,
            rules: [
              {
                required: true,
                message: 'Please input the name of trainer!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Trainer Email">
          {getFieldDecorator('trainerEmail', {
            initialValue: trainerParam.trainerEmail,
            rules: [
              {
                type: 'email',
                message: 'Please input email of the trainer!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Trainer Detail Url">
          {getFieldDecorator('trainerDetailUrl', {
            initialValue: trainerParam.trainerDetailUrl,
            rules: [
              {
                type: 'url',
                message: 'Please input the correct url!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Trainer Picture Url">
          {getFieldDecorator('trainerPicUrl', {
            initialValue: trainerParam.trainerPicUrl,
            rules: [
              {
                type: 'url',
                message: 'Please input the correct url!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        {self.role === 'superadmin' && isCreate ? (
          <Form.Item label="Organization">
            {getFieldDecorator('organizationId', {
              initialValue: trainerParam.organizationId,
              rules: [
                {
                  required: true,
                  message: 'Please input the organization of user!',
                },
              ],
            })(
              <Select>
                {organizations &&
                  organizations.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        <Row style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? 'Add' : 'Update'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddTrainerModal = Form.create<TrainerModalProps>()(AddTrainerModalComponent);
export default AddTrainerModal;
