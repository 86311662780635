import api from '../../axios/Api';

export default class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }
  upload(file) {
    return new Promise((resolve, reject) => {
      this.loader.file.then(file => {
        api
          .uploadFile(file)
          .then(response => {
            if (!response.data?.result?.url) {
              if (response.data?.error) {
                return reject(response.data?.error);
              }
              return reject('Server error');
            }
            resolve({
              default: response.data.result.url,
            });
          })
          .catch(error => {
            reject('Error' + error.toString());
            console.log('Uploader Error : ', error);
          });
      });
    });
  }
}
