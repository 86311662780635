import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditCustomButtonsModal from 'component/AddEditCustomButtonsModal/AddEditCustomButtonsModal';

const CustomButtonsTable = ({ customButtons, onAdd, onDelete, onEdit }) => {
  const [showAddEditCustomButtonsModal, setShowAddEditCustomButtonsModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedCustomButton, setSelectedCustomButton] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'title',
      title: 'Title',
      render: record => <p>{record.title}</p>,
    },
    {
      key: 'url',
      title: 'URL',
      render: record => <p>{record.url}</p>,
    },
    {
      key: 'icon',
      title: 'Icon',
      render: record => <p>{record.icon}</p>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedCustomButton(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditCustomButtonsModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditCustomButtonsModal = () => {
    setSelectedCustomButton(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditCustomButtonsModal(false);
  };

  const onAddCustomButton = customButton => {
    onAdd(customButton);
    closeAddEditCustomButtonsModal();
  };
  const onEditCustomButton = customButton => {
    onEdit(customButton, selectedIndex);
    closeAddEditCustomButtonsModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditCustomButtonsModal(true)}>
        Add Custom Button
      </Button>
      <AntdTable
        pagination={false}
        rowKey={record => `${record.title}${record.url}${record.action}`}
        columns={columns}
        dataSource={customButtons}
        size="small"
      />
      <AddEditCustomButtonsModal
        visible={showAddEditCustomButtonsModal}
        currentItem={selectedCustomButton}
        isCreate={isCreate}
        onAdd={onAddCustomButton}
        onEdit={onEditCustomButton}
        onCancel={closeAddEditCustomButtonsModal}
      />
    </div>
  );
};

export default CustomButtonsTable;
