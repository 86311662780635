import React, { useRef, useState } from 'react';
import { Button, Icon, Input, DatePicker, Popconfirm } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
import { getUserData } from 'helper';

const PAGE_LIMIT = 10;

const CommentsVideoTable = ({ tableLoading, setTableLoading, comments, paginationData, handleTableChange, commentsSorter, onDeleteComment }) => {
  const loggedInUser = JSON.parse(getUserData());
  let searchInputRef: any = null;

  /** search filter  */
  const getSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ref => (searchInputRef = ref)}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = clearFilters => {
    clearFilters();
  };

  const columns = [
    {
      key: 'content',
      title: 'Content',
      render: record => <p style={{ margin: 0 }}>{record.content}</p>,
      ...getSearchProps('content'),
    },
    {
      key: 'name',
      title: 'By',
      render: record => <span>{record.name}</span>,
      ...getSearchProps('name'),
    },
    {
      key: 'videoTitle',
      title: 'Video',
      render: record => <span>{record.video?.title}</span>,
    },
    // ...(loggedInUser.role === 'superadmin'
    //   ? [
    //       {
    //         key: 'organizationName',
    //         title: 'Org Name',
    //         render: record => <span>{record.organization && record.organization.name}</span>,
    //       },
    //     ]
    //   : [{}]),
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: commentsSorter.field == 'createdAt' ? commentsSorter.order : false,
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Popconfirm title="Are you sure delete this comment?" onConfirm={() => onDeleteComment(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];

  return (
    <AntdTable
      loading={tableLoading}
      rowKey={record => record._id}
      columns={columns}
      onChange={handleTableChange}
      dataSource={comments}
      scroll={{ x: true }}
      pagination={{
        position: 'bottom',
        pageSize: PAGE_LIMIT,
        total: paginationData.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        // onChange: handlePageChange,
      }}
    />
  );
};

export default CommentsVideoTable;
