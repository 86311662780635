import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
