import { Icon, Tooltip } from 'antd';
import React from 'react';

const LabelwTooltip = ({ label, tooltipText }) => {
  return (
    <div style={{ display: 'inline' }}>
      <label style={{ fontSize: '14px', marginRight: 5 }}>{label}</label>
      <Tooltip title={tooltipText}>
        <Icon type="info-circle" style={{ fontSize: '20px', color: '#40A9FF' }} />
      </Tooltip>
    </div>
  );
};

export default LabelwTooltip;
