import gql from 'graphql-tag';
import { VIDEO_FRAGMENT } from 'apollo/video/videoFragmet';

export const USER_LIKED_VIDEOS_QUERY = gql`
  query userLikedVideos($_id: ID) {
    userLikedVideos(_id: $_id) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
