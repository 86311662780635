import { gql } from 'apollo-boost';

export const MEMBERSHIP_FRAGMENT = gql`
  fragment Membership on Membership {
    _id
    membershipName
    membershipExternalId
    organizationId
    organization {
      _id
      name
    }
    createdAt
    updatedAt
  }
`;
