import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Icon, Modal, Form, Input, Select, notification, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { USER_CREATE_MUTATION, USER_UPDATE_MUTATION } from 'apollo/user/mutations';
import { USERS_QUERY, USER_ROLES_QUERY } from 'apollo/user/queries';
import { getUserData } from 'helper';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const { Option } = Select;
interface UserModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddEditUserModalComponent: React.FC<UserModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  useQuery(USER_ROLES_QUERY, {
    onCompleted: data => {
      setUserRoles(data.getUserRoles);
    },
  });
  useQuery(TRAINERS_QUERY, {
    variables: {
      organizationId: currentItem?.organizationId || form.getFieldValue('organizationId'),
    },
    onCompleted: data => {
      setTrainers(data.trainers);
    },
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => setOrganizations(data.organizations),
  });
  const [createUser] = useMutation(USER_CREATE_MUTATION, {
    update: (cache, { data: { userCreate } }) => {
      const cachedData: any = cache.readQuery({
        query: USERS_QUERY,
        variables: {
          organizationId: null,
        },
      });
      cache.writeQuery({
        query: USERS_QUERY,
        data: { users: [...cachedData.users, userCreate] },
      });
    },
    refetchQueries: ['users'],
  });
  const [userRoles, setUserRoles] = useState<any>(null);
  const [organizations, setOrganizations] = useState<any>(null);
  const [self, setSelf] = useState(JSON.parse(getUserData()) || null);
  const [trainers, setTrainers] = useState<any>(null);
  const [updateUser] = useMutation(USER_UPDATE_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [userParam, setUserParam] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    password: undefined,
    role: undefined,
    organizationId: undefined,
    adHoc: true,
    allowMultipleLogin: false,
    trainerId: undefined,
  });

  // useEffect(() => {
  //   if (self && self.role === 'superadmin' && isCreate) {
  //     getOrganizations();
  //   }
  // }, []);

  useEffect(() => {
    if (currentItem) {
      setUserParam(prev => ({
        ...prev,
        firstName: currentItem.firstName,
        lastName: currentItem.lastName,
        email: currentItem.email,
        password: currentItem.password,
        role: currentItem.role,
        organizationId: currentItem.organizationId || undefined,
        adHoc: currentItem.adHoc,
        allowMultipleLogin: currentItem.allowMultipleLogin,
        trainerId: currentItem.trainerId || undefined,
      }));
    } else {
      resetForm();
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setUserParam({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined,
      role: undefined,
      organizationId: undefined,
      adHoc: true,
      allowMultipleLogin: false,
      trainerId: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          adHoc: userParam.adHoc,
          allowMultipleLogin: userParam.allowMultipleLogin,
          ...(isCreate ? { password: values.password } : {}),
          role: values.role,
          ...(values.role == 'trainer' ? { trainerId: values.trainerId || null } : {}),
        },
        ...(self && self.role === 'superadmin' ? (isCreate ? { organizationId: values.organizationId } : {}) : {}),
      };
      if (isCreate) {
        createUser({
          variables: {
            ...params,
          },
        })
          .then(mutationResult => {
            setIsLoading(false);
            notification.success({
              message: 'User Created',
              duration: 0,
              description: 'User was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'User Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateUser({
          variables: {
            ...params,
            _id: currentItem._id,
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            notification.success({
              message: 'User Updated',
              duration: 0,
              description: 'User was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'User Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  const onAdHocChange = val => {
    // console.log('status', val);
    setUserParam(prev => ({ ...prev, adHoc: val }));
  };
  const onAllowMultipleLoginChange = val => {
    // console.log('status', val);
    setUserParam(prev => ({ ...prev, allowMultipleLogin: val }));
  };
  return (
    <Modal
      title={isCreate ? 'Add New User' : 'Update Existing User'}
      footer={[
        <Button type="primary" onClick={handleSubmit} key="addUpdate" loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="First Name">
          {getFieldDecorator('firstName', {
            initialValue: userParam.firstName,
            rules: [
              {
                required: true,
                message: 'Please input the first name of user!',
              },
            ],
          })(<Input placeholder="Enter first name of the user" />)}
        </Form.Item>
        <Form.Item label="Last Name">
          {getFieldDecorator('lastName', {
            initialValue: userParam.lastName,
            rules: [
              {
                required: true,
                message: 'Please input the last name of user!',
              },
            ],
          })(<Input placeholder="Enter last name of the user" />)}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: userParam.email,
            rules: [
              {
                required: true,
                message: 'Please input the email of user!',
              },
            ],
          })(<Input placeholder="Enter email of the user" />)}
        </Form.Item>
        {isCreate && (
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              initialValue: userParam.password,
              rules: [
                {
                  required: true,
                  message: 'Please input the password of user!',
                },
              ],
            })(<Input placeholder="Enter password for the user" />)}
          </Form.Item>
        )}
        {self.role === 'superadmin' && isCreate ? (
          <Form.Item label="Organization">
            {getFieldDecorator('organizationId', {
              initialValue: userParam.organizationId,
              rules: [
                {
                  required: true,
                  message: 'Please input the organization of user!',
                },
              ],
            })(
              <Select placeholder="Select organization of the user">
                {organizations &&
                  organizations.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        {self.role === 'superadmin' || self.role == 'admin' ? (
          <Form.Item label="User Role">
            {getFieldDecorator('role', {
              initialValue: userParam.role,
              rules: [
                {
                  required: true,
                  message: 'Please select the role of user!',
                },
              ],
            })(
              <Select placeholder="Select role of the user">
                {userRoles &&
                  userRoles.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        {form.getFieldValue('role') == 'trainer' && (self.role === 'superadmin' || self.role == 'admin') && (
          <Form.Item label="Link Trainer">
            {getFieldDecorator('trainerId', {
              initialValue: userParam.trainerId,
            })(
              <Select placeholder="Select a trainer to link">
                <Option key="nolink" value={undefined}>
                  No link
                </Option>
                {trainers &&
                  trainers.map((item, index) => (
                    <Option key={item.name + item._id + index} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
        {((currentItem && currentItem.role !== 'superadmin' && !isCreate) || isCreate) && (
          <Form.Item label="Access to Video Library?">
            {getFieldDecorator('adHoc', {
              valuePropName: 'checked',
              initialValue: userParam.adHoc,
            })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={onAdHocChange} />)}
          </Form.Item>
        )}
        <Form.Item label="Allow Login from multiple device">
          {getFieldDecorator('allowMultipleLogin', {
            valuePropName: 'checked',
            initialValue: userParam.allowMultipleLogin,
          })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={onAllowMultipleLoginChange} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditUserModal = Form.create<UserModalProps>()(AddEditUserModalComponent);
export default AddEditUserModal;
