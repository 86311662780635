import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../userfragment';

export const USERS_ATTEMPTED_LOGIN_QUERY = gql`
  query usersAttemptedLogin($organizationId: String, $paginationInput: PaginationInput, $filterInput: UserFilterInput) {
    usersAttemptedLogin(paginationInput: $paginationInput, filterInput: $filterInput, organizationId: $organizationId) {
      pagination {
        total
        limit
        page
      }
      users {
        ...User
      }
    }
  }
  ${USER_FRAGMENT}
`;
