import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const VIDEO_QUERY = gql`
  query video($_id: ID!) {
    video(_id: $_id) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
