import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const ORGANIZATION_CREATE_MUTATION = gql`
  mutation organizationCreate($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
