import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditAnnouncementsModal from 'component/AddEditAnnouncementsModal/AddEditAnnouncementsModal';
import moment from 'moment';

const AnnouncementsTable = ({ announcements, onAdd, onDelete, onEdit }) => {
  const [showAddEditAnnouncementsModal, setShowAddEditAnnouncementsModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'description',
      title: 'Description',
      render: record => <p style={{ margin: 0 }}>{record.description}</p>,
    },
    {
      key: 'start',
      title: 'Start Date',
      render: record => <p style={{ margin: 0 }}>{moment(record.start).format('DD MMM YYYY')}</p>,
    },
    {
      key: 'end',
      title: 'End Date',
      render: record => <p style={{ margin: 0 }}>{moment(record.end).format('DD MMM YYYY')}</p>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedAnnouncement(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditAnnouncementsModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditAnnouncementsModal = () => {
    setSelectedAnnouncement(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditAnnouncementsModal(false);
  };

  const onAddAnnouncement = announcement => {
    onAdd(announcement);
    closeAddEditAnnouncementsModal();
  };
  const onEditAnnouncement = announcement => {
    onEdit(announcement, selectedIndex);
    closeAddEditAnnouncementsModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditAnnouncementsModal(true)}>
        Add Announcement
      </Button>
      <AntdTable
        pagination={false}
        rowKey={record => `${record.title}${record.url}${record.action}`}
        columns={columns}
        dataSource={announcements}
        size="small"
      />
      <AddEditAnnouncementsModal
        visible={showAddEditAnnouncementsModal}
        currentItem={selectedAnnouncement}
        isCreate={isCreate}
        onAdd={onAddAnnouncement}
        onEdit={onEditAnnouncement}
        onCancel={closeAddEditAnnouncementsModal}
      />
    </div>
  );
};

export default AnnouncementsTable;
