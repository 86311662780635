import React, { useState } from 'react';
import { Row, Col, Button, Card, Select, message } from 'antd';
import MembershipTable from 'component/MembershipTable/MembershipTable';
import { getUserData } from 'helper';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import AddMembershipModal from 'component/AddMembershipModal/AddMembershipModal';
import LabelwTooltip from 'component/LabelwTooltip/LabelwTooltip';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ORGANIZATION_QUERY } from 'apollo/organization/queries';
import Loader from 'react-loader-spinner';
import { ORGANIZATION_SETTINGS_UPDATE_MUTATION } from 'apollo/organization/mutations';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';

const { Option } = Select;

const MembershipContainer = () => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [whitelistResourceId, setWhitelistResourceId] = useState([]);
  const [defaultVideoMemberships, setdefaultVideoMemberships] = useState([]);
  const [showAddMembershipModal, setShowAddMembershipModal] = useState(false);
  const [isCreateMembership, setIsCreateMembership] = useState(true);
  const [currentMembership, setCurrentMembership] = useState(false);
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [updateOrgSettings] = useMutation(ORGANIZATION_SETTINGS_UPDATE_MUTATION, { refetchQueries: ['organization'] });
  const { loading: membershipLoading, error, data } = useQuery(MEMBERSHIPS_QUERY, {
    variables: { organizationId: selectedOrg == 'all' || !selectedOrg ? null : selectedOrg },
  });
  useQuery(ORGANIZATION_QUERY, {
    onCompleted: data => {
      if (data.organization) {
        const { organization } = data;
        setOrganization(organization);
        setWhitelistResourceId((organization.settings && organization.settings.whitelistResourceId) || []);
        setdefaultVideoMemberships((organization.settings && organization.settings.defaultVideoMemberships) || []);
      }
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
    fetchPolicy: 'network-only',
  });

  const openAddMembershipModal = () => {
    setShowAddMembershipModal(true);
  };

  const handleMembershipModalCancel = () => {
    setIsCreateMembership(true);
    setCurrentMembership(false);
    setShowAddMembershipModal(false);
  };

  const onEditMembership = selectedMembership => {
    setIsCreateMembership(false);
    setCurrentMembership(selectedMembership);
    openAddMembershipModal();
  };

  const onWhitelistResourceIdChange = val => {
    setWhitelistResourceId(val);
  };

  const saveResourceId = () => {
    setIsLoading(true);
    const input = {
      whitelistResourceId: whitelistResourceId || [],
    };
    updateOrgSettings({
      variables: {
        _id: organization._id,
        input: input,
      },
    })
      .then(mutationResult => {
        // console.log('updated', mutationResult.data.organizationUpdate);
        setIsLoading(false);
        message.success('Updated Succesfully.');
      })
      .catch(e => {
        setIsLoading(false);
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        message.error(errorMessage);
      });
  };
  const saveDefaultMembership = () => {
    setIsLoading(true);
    const input = {
      defaultVideoMemberships: defaultVideoMemberships || [],
    };
    updateOrgSettings({
      variables: {
        _id: organization._id,
        input: input,
      },
    })
      .then(mutationResult => {
        // console.log('updated', mutationResult.data.organizationUpdate);
        setIsLoading(false);
        message.success('Updated Succesfully.');
      })
      .catch(e => {
        setIsLoading(false);
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        message.error(errorMessage);
      });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      {/* <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          <Card
            style={{ marginBottom: '24px' }}
            title={
              <LabelwTooltip
                label="White List Resource IDs"
                tooltipText="Add Ids for the packages/services that will get access to video platform."
              />
            }
          >
            {loading ? (
              <div style={{ padding: 20, textAlign: 'center' }}>
                <Loader type="TailSpin" color="#202541" />
              </div>
            ) : (
              <>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  value={whitelistResourceId}
                  onChange={val => onWhitelistResourceIdChange(val)}
                  placeholder="Enter an whitelist resource id"
                />
                <Button loading={isLoading} style={{ marginTop: 20 }} type="primary" onClick={saveResourceId}>
                  Save
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row> */}
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          <Card
            style={{ marginBottom: '24px' }}
            title={<LabelwTooltip label="Default Video Membership" tooltipText="Add Default Membership to inlude in your videos." />}
          >
            {loading ? (
              <div style={{ padding: 20, textAlign: 'center' }}>
                <Loader type="TailSpin" color="#202541" />
              </div>
            ) : (
              <>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={defaultVideoMemberships}
                  onChange={val => setdefaultVideoMemberships(val)}
                  placeholder="Enter default membership"
                >
                  {data &&
                    data.memberships &&
                    data.memberships.map(item => (
                      <Option value={item._id} key={item._id}>
                        {item.membershipName}
                      </Option>
                    ))}
                </Select>
                <Button loading={isLoading} style={{ marginTop: 20 }} type="primary" onClick={saveDefaultMembership}>
                  Save
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          <Button type="primary" onClick={openAddMembershipModal}>
            Add New Membership
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={15} offset={1}>
          <MembershipTable
            memberships={data && data.memberships}
            organization={selectedOrg == 'all' ? null : selectedOrg}
            onEdit={onEditMembership}
          />
        </Col>
      </Row>
      <AddMembershipModal
        modalVisible={showAddMembershipModal}
        isCreate={isCreateMembership}
        currentItem={currentMembership}
        handleOk={handleMembershipModalCancel}
        handleCancel={handleMembershipModalCancel}
      />
    </>
  );
};
export default MembershipContainer;
