import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const VIDEOS_STORAGE_QUERY = gql`
  query videosStorage($organizationId: String!) {
    videosStorage(organizationId: $organizationId) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
