import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import CommentList from 'component/CommentList/CommentList';
import { COMMENTS_IN_VIDEO_QUERY } from 'apollo/comment/queries';
import { Modal } from 'antd';
import Loader from 'react-loader-spinner';

const CommentsModal = ({ video, visible, closeModal }) => {
  const [comments, setComments] = useState<any>(null);
  const [getComments] = useLazyQuery(COMMENTS_IN_VIDEO_QUERY, {
    onCompleted: data => {
      // console.log('data', data);
      if (data.commentsInVideo) {
        setComments(data.commentsInVideo);
      } else {
        setComments([]);
      }
    },
    onError: err => {
      console.error('err', err);
      setComments([]);
      // setComments(data.commentsInVideo);
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (video) {
      getComments({
        variables: {
          _id: video && video._id,
        },
      });
    }
  }, [video]);
  if (!video) return null;
  return (
    <Modal
      title="Comments"
      visible={visible}
      onCancel={() => {
        closeModal();
        setComments(null);
      }}
      footer={null}
    >
      {comments ? (
        <CommentList comments={comments} setComments={setComments} canReply={true} videoDetails={video} />
      ) : (
        <Loader type="TailSpin" color="#202541" />
      )}
    </Modal>
  );
};

export default CommentsModal;
