import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { USERS_ATTEMPTED_LOGIN_QUERY } from 'apollo/user/queries';
import LoadingSpinner from 'component/Loader';
import { getUserData } from 'helper';
import { Row, Col } from 'antd';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import AttemptedLoginsTable from 'component/AttemptedLoginsTable/AttemptedLoginsTable';

const PAGE_LIMIT = 50;

const AttemptedLoginsContainer = () => {
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false as any);
  const [usersFilter, setUsersFilter] = useState<any>({});
  const [users, setUsers] = useState<null | Array<Object>>(null);
  const { fetchMore, data } = useQuery(USERS_ATTEMPTED_LOGIN_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      filterInput: usersFilter,
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.usersAttemptedLogin) {
        // console.log('users onCompletre', data);
        setUsers(data.usersAttemptedLogin.users);
        setPaginationData(data.usersAttemptedLogin.pagination);
        setLoading(false);
        setTableLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.error('err are', err);
    },
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (data) {
      setUsers(data.usersAttemptedLogin.users);
      // setPaginationData(data.publishedSharedVideos.pagination);
      // setLoading(false);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let userFilterInput = {};
    if (filters.firstName && filters.firstName.length) userFilterInput['firstName'] = filters.firstName[0];
    if (filters.lastName && filters.lastName.length) userFilterInput['lastName'] = filters.lastName[0];
    if (filters.email && filters.email.length) userFilterInput['email'] = filters.email[0];
    if (filters.role && filters.role.length) userFilterInput['role'] = filters.role;
    setUsersFilter(userFilterInput);
    fetch(paginationInput, userFilterInput);
  };

  const fetch = (paginationInput, userFilterInput: any = null, sorterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', userFilterInput);
    // console.log('sortterinput', sorterInput);
    setTableLoading(true);
    // fetchMore({
    //   variables: {
    //     paginationInput,
    //     ...(userFilterInput && { userFilterInput: userFilterInput }),
    //     ...(sorterInput && { sorterInput: sorterInput }),
    //     organizationId: selectedOrg == 'all' ? null : selectedOrg,
    //   },
    //   updateQuery: (prev, { fetchMoreResult }: any) => {
    //     // console.log('fetchmore results', fetchMoreResult);
    //     if (!fetchMoreResult) return prev;
    //     setUsers(fetchMoreResult.users.users);
    //     setPaginationData(fetchMoreResult.users.pagination);
    //     setTableLoading(false);
    //   },
    // });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <AttemptedLoginsTable
            users={users}
            paginationData={paginationData}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            handleTableChange={handleTableChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default AttemptedLoginsContainer;
