import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_VIDEO_MUTATION } from 'apollo/video/mutations';
import { message, Select } from 'antd';
const { Option } = Select;

const VideoStatusSelect = ({ video, setTableLoading }) => {
  const [changeStatus] = useMutation(UPDATE_VIDEO_MUTATION);
  const [status, setStatus] = useState(video.status);
  useEffect(() => {
    if (video) setStatus(video.status);
  }, [video]);
  const onStatusChange = val => {
    if (video.status !== val) {
      setTableLoading(true);
      changeStatus({
        variables: {
          _id: video._id,
          input: {
            status: val,
            isLive: video.isLive,
          },
        },
      })
        .then(result => {
          setStatus(val);
          setTableLoading(false);
          message.success('Updated Succesfully.');
        })
        .catch(err => {
          setTableLoading(false);
          const errorMessage = err.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
          console.error('status change error', err);
        });
    }
  };
  return (
    <Select value={status} onChange={onStatusChange}>
      <Option value="published">Published</Option>
      <Option value="notPublished">Not Published</Option>
    </Select>
  );
};

export default VideoStatusSelect;
