import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Tooltip, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { VIDEOS_PAGE_FILTER_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import { useHistory } from 'react-router-dom';
import { DELETE_VIDEO_MUTATION } from 'apollo/video/mutations/deleteVideo';
import EditVideoModal from 'component/EditVideoModal/EditVideoModal';
import VideoPlayerModal from 'component/VideoPlayerModal/VideoPlayerModal';
import VideoTable from 'component/VideoTable/VideoTable';
import VideoLogsModal from 'component/VideoLogsModal/VideoLogsModal';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import { getUserData } from 'helper';
import CommentsModal from 'component/CommentsModal/CommentsModal';

const PAGE_LIMIT = 10;

interface VideoUploadProps {}

const VideosContainer: React.FC<VideoUploadProps> = ({}) => {
  const history = useHistory();
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false as any);
  const [videosFilter, setVideosFilter] = useState<any>({});
  const [videosSorter, setVideosSorter] = useState<any>({
    field: 'createdAt',
    order: 'descend',
  });

  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [showVideoLogsModal, setShowVideoLogsModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [deleteVideo] = useMutation(DELETE_VIDEO_MUTATION);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  // const { loading, error, data } = useQuery(VIDEOS_QUERY);
  const { fetchMore, data } = useQuery(VIDEOS_PAGE_FILTER_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      videosPageFilterInput: videosFilter,
      sorterInput: videosSorter,
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.videosPageFilter) {
        setVideos(data.videosPageFilter.videos);
        setPaginationData(data.videosPageFilter.pagination);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      // console.log('videos', data);
      setVideos(data.videosPageFilter.videos);
      // setPaginationData(data.videosPageFilter.pagination);
      // setLoading(false);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  const openEditVideoModal = selectedVideo => {};
  const onEditVideo = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowEditVideoModal(true);
  };
  const closeEditVideoModal = () => {
    setCurrentItem(null);
    setShowEditVideoModal(false);
  };

  const openVideoPlayerModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoPlayerModal(true);
  };
  const closeVideoPlayerModal = () => {
    setCurrentItem(null);
    setShowVideoPlayerModal(false);
  };

  const openVideoLogsModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoLogsModal(true);
  };
  const closeVideoLogsModal = () => {
    setCurrentItem(null);
    setShowVideoLogsModal(false);
  };

  const openCommentsModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowCommentsModal(true);
  };

  const handleGoliveUpload = goLive => {
    history.push({
      pathname: 'videoUpload',
      search: `?goLive=${goLive ? '1' : '0'}`,
      state: { goLive: goLive },
    });
  };
  const onDeleteVideo = id => {
    message.loading({ content: 'Deleting video.', key: 'deleteMessage', duration: 0 });
    setTableLoading(true);
    deleteVideo({ variables: { _id: id } })
      .then(data => {
        if (videos) {
          let temp = videos;
          let index = temp.findIndex((i: any) => i._id == id);
          temp.splice(index, 1);
          setVideos(temp);
        }
        message.destroy();
        setTableLoading(false);
        notification.success({
          message: 'Video Deleted',
          duration: 0,
          description: 'Video was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        setTableLoading(false);
        notification.error({
          message: 'Video Delete Failed',
          duration: 0,
          description: 'Video was not deleted.',
        });
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let videosPageFilterInput = {};
    if (filters.title && filters.title.length) videosPageFilterInput['search'] = filters.title[0];
    if (filters.trainer && filters.trainer.length) videosPageFilterInput['trainer'] = filters.trainer;
    if (filters.activity && filters.activity.length) videosPageFilterInput['activity'] = filters.activity;
    if (filters.premiereAt && filters.premiereAt.length)
      videosPageFilterInput['premiereAtRange'] = {
        start: filters.premiereAt[0].startOf('day').toISOString(),
        end: filters.premiereAt[0].endOf('day').toISOString(),
      };
    if (filters.createdAt && filters.createdAt.length)
      videosPageFilterInput['createdAtRange'] = {
        start: filters.createdAt[0].startOf('day').toISOString(),
        end: filters.createdAt[0].endOf('day').toISOString(),
      };
    setVideosFilter(videosPageFilterInput);
    let sorterInput = {};
    sorterInput['field'] = sorter.columnKey;
    sorterInput['order'] = sorter.order ? sorter.order : 'descend';
    setVideosSorter(sorterInput);
    fetch(paginationInput, videosPageFilterInput, sorterInput);
  };

  const fetch = (paginationInput, videosPageFilterInput: any = null, sorterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', videosPageFilterInput);
    // console.log('sortterinput', sorterInput);
    setTableLoading(true);
    fetchMore({
      variables: {
        paginationInput,
        ...(videosPageFilterInput && { videosPageFilterInput: videosPageFilterInput }),
        ...(sorterInput && { sorterInput: sorterInput }),
        organizationId: selectedOrg == 'all' ? null : selectedOrg,
      },
      updateQuery: (prev, { fetchMoreResult }: any) => {
        // console.log('fetchmore results', fetchMoreResult);
        if (!fetchMoreResult) return prev;
        setVideos(fetchMoreResult.videosPageFilter.videos);
        setPaginationData(fetchMoreResult.videosPageFilter.pagination);
        setTableLoading(false);
      },
    });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={2} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Live Stream" key="Live Stream">
              <Button type="primary" onClick={() => handleGoliveUpload(true)}>
                Go Live
              </Button>
            </Tooltip>
          </div>
        </Col>
        <Col span={2} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Upload New Video" key="Upload New Video">
              <Button type="primary" onClick={() => handleGoliveUpload(false)}>
                Upload
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <VideoTable
            onEditVideo={onEditVideo}
            onDeleteVideo={onDeleteVideo}
            videos={videos}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            openVideoPlayerModal={openVideoPlayerModal}
            openEditVideoModal={openEditVideoModal}
            paginationData={paginationData}
            handleTableChange={handleTableChange}
            openVideoLogsModal={openVideoLogsModal}
            openCommentsModal={openCommentsModal}
            videosSorter={videosSorter}
          />
        </Col>
      </Row>
      <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} />
      <VideoPlayerModal visible={showVideoPlayerModal} video={currentItem} closeModal={closeVideoPlayerModal} />
      <VideoLogsModal visible={showVideoLogsModal} video={currentItem} closeModal={closeVideoLogsModal} />
      <CommentsModal video={currentItem} visible={showCommentsModal} closeModal={() => setShowCommentsModal(false)} />
    </>
  );
};
export default VideosContainer;
