import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  ANALYTICS_VIDEO_REPORT_PERDAY_QUERY,
  ANALYTICS_VIDEO_REPORT_PERMONTH_QUERY,
  ANALYTICS_VIDEO_REPORT_PERWEEK_QUERY,
} from 'apollo/analyticsReport/queries';
import moment from 'moment';
import { notification } from 'antd';
import AnalyticsFilterSelect from 'component/AnalyticsFilterSelect/AnalyticsFilterSelect';
import { getRandomColor } from 'helper/getRandomColor';

const ViewersLineChart = ({ organization }) => {
  const [rawData, setRawData] = useState<any>(null);
  const [allData, setAllData] = useState<any>(null);
  const [viewersData, setViewersData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [getType, setGetType] = useState('daily');
  const [startDate, setStartDate] = useState(moment().subtract('months', 1));
  const [endDate, setEndDate] = useState(moment());
  const [trainer, setTrainer] = useState<any>([]);
  const [activity, setActivity] = useState<any>([]);
  const [getViewersPermonth] = useLazyQuery(ANALYTICS_VIDEO_REPORT_PERMONTH_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      let labels: any = [];
      let allDataTemp: any = [];
      if (data.analyticsVideoReportPermonth) {
        setRawData(data.analyticsVideoReportPermonth);
        data.analyticsVideoReportPermonth.forEach((item: any) => {
          labels.push(moment(item.date).format('MMM-YYYY'));
          allDataTemp.push(item.videoStarts.reduce((a, b) => a + b, 0));
        });
      }
      const temp = {
        label: 'All',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: allDataTemp,
      };
      setAllData(temp);
      setViewersData({
        labels: labels,
        datasets: [temp],
      });
      setActivity([]);
      setTrainer([]);
    },
    onError: err => {
      console.log('err', err);
      setFetching(false);
      setLoading(false);
      setActivity([]);
      setTrainer([]);
    },
  });
  const [getViewersPerday] = useLazyQuery(ANALYTICS_VIDEO_REPORT_PERDAY_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      let labels: any = [];
      let allDataTemp: any = [];
      if (data.analyticsVideoReportPerday) {
        setRawData(data.analyticsVideoReportPerday);
        data.analyticsVideoReportPerday.forEach((item: any) => {
          labels.push(moment(item.date).format('DD-MMM'));
          allDataTemp.push(item.videoStarts.reduce((a, b) => a + b, 0));
        });
      }
      const temp = {
        label: 'All',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: allDataTemp,
      };
      setAllData(temp);
      setViewersData({
        labels: labels,
        datasets: [temp],
      });
      setActivity([]);
      setTrainer([]);
    },
    onError: err => {
      console.log('err', err);
      setFetching(false);
      setLoading(false);
      setActivity([]);
      setTrainer([]);
    },
  });
  const [getViewersPerweek] = useLazyQuery(ANALYTICS_VIDEO_REPORT_PERWEEK_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      let labels: any = [];
      let allDataTemp: any = [];
      if (data.analyticsVideoReportPerweek) {
        setRawData(data.analyticsVideoReportPerweek);
        data.analyticsVideoReportPerweek.forEach((item: any) => {
          labels.push(moment(item.date, 'YYYYww').format('DD-MMM'));
          allDataTemp.push(item.videoStarts.reduce((a, b) => a + b, 0));
        });
      }
      const temp = {
        label: 'All',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: allDataTemp,
      };
      setAllData(temp);
      setViewersData({
        labels: labels,
        datasets: [temp],
      });
      setActivity([]);
      setTrainer([]);
    },
    onError: err => {
      console.log('err', err);
      setFetching(false);
      setLoading(false);
      setActivity([]);
      setTrainer([]);
    },
  });
  useEffect(() => {
    setLoading(true);
    getViewersPerday({
      variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), organizationId: organization },
    });
    return () => {};
  }, [organization]);

  const handleChange = value => {
    setGetType(value);
  };
  const onStartChange = value => {
    setStartDate(value);
  };
  const onEndChange = value => {
    setEndDate(value);
  };
  const checkDatasets = (by, checklist, list) => {
    const prevDatasets = [...viewersData.datasets];
    for (const item of checklist) {
      let exists = false;
      for (const prevData of prevDatasets) {
        if (prevData.label == item) {
          exists = prevData;
        }
      }
      if (!exists) {
        const dataset =
          rawData &&
          rawData.map(d => {
            let starts = 0;
            d[by].forEach((b, ind) => {
              if (b == item) {
                starts += d.videoStarts[ind];
              }
            });
            return starts;
          });
        const color = getRandomColor();
        list.push({
          label: item,
          fill: false,
          lineTension: 0.1,
          backgroundColor: color,
          borderColor: color,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: color,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataset,
        });
      } else {
        list.push(exists);
      }
    }
  };
  const onTrainerChange = value => {
    setTrainer(value);
    const temp = [allData];
    checkDatasets('trainer', value, temp);
    checkDatasets('activity', activity, temp);
    setViewersData(prev => ({ ...prev, datasets: temp }));
  };
  const onActivityChange = value => {
    setActivity(value);
    const temp = [allData];
    checkDatasets('trainer', trainer, temp);
    checkDatasets('activity', value, temp);
    setViewersData(prev => ({ ...prev, datasets: temp }));
  };

  const handleApply = () => {
    setFetching(true);
    if (!startDate || !endDate) {
      setFetching(false);
      notification.error({
        message: 'Date Error',
        description: 'Start Date or End Date not selected',
      });
      return;
    }
    let analyticsFilter = {};
    // if (trainer && trainer != 'all') {
    //   analyticsFilter['trainer'] = trainer;
    // }
    // if (activity && activity != 'all') {
    //   analyticsFilter['activity'] = activity;
    // }
    if (getType === 'daily') {
      if (startDate > endDate) {
        setFetching(false);
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greater than end date.',
        });
        return;
      }
      getViewersPerday({
        variables: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          organizationId: organization,
          analyticsFilterInput: analyticsFilter,
        },
      });
    }
    if (getType === 'monthly') {
      if (startDate.startOf('month') > endDate.endOf('month')) {
        setFetching(false);
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greater than end date.',
        });
        return;
      }
      getViewersPermonth({
        variables: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          organizationId: organization,
          analyticsFilterInput: analyticsFilter,
        },
      });
    }
    if (getType === 'weekly') {
      if (startDate.startOf('week') > endDate.endOf('week')) {
        setFetching(false);
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greater than end date.',
        });
        return;
      }
      getViewersPerweek({
        variables: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          organizationId: organization,
          analyticsFilterInput: analyticsFilter,
        },
      });
    }
  };

  if (loading)
    return (
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Loader type="TailSpin" color="#202541" />
      </div>
    );
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Views</h2>
      <AnalyticsFilterSelect
        organization={organization}
        getType={getType}
        startDate={startDate}
        endDate={endDate}
        trainer={trainer}
        activity={activity}
        handleChange={handleChange}
        onStartChange={onStartChange}
        onEndChange={onEndChange}
        onTrainerChange={onTrainerChange}
        onActivityChange={onActivityChange}
        fetching={fetching}
        handleApply={handleApply}
      />
      <Line data={viewersData} />
    </div>
  );
};

export default ViewersLineChart;
