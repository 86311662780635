import { gql } from 'apollo-boost';

export const TRAINER_FRAGMENT = gql`
  fragment Trainer on Trainer {
    _id
    name
    trainerPicUrl
    trainerDetailUrl
    trainerEmail
    organizationId
    organization {
      _id
      name
    }
    createdAt
    updatedAt
  }
`;
