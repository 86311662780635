import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const ORGANIZATION_SETTINGS_UPDATE_MUTATION = gql`
  mutation organizationSettingsUpdate($_id: ID!, $input: OrganizationSettingsUpdateInput!) {
    organizationSettingsUpdate(_id: $_id, input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
