import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Col, message, notification, Row, Tooltip } from 'antd';
import { DELETE_VIDEO_MUTATION } from 'apollo/video/mutations';
import { SHARED_VIDEOS_QUERY } from 'apollo/video/queries';
import EditVideoModal from 'component/EditVideoModal/EditVideoModal';
import LoadingSpinner from 'component/Loader';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import SharedVideosTable from 'component/SharedVideosTable/SharedVideosTable';
import VideoLogsModal from 'component/VideoLogsModal/VideoLogsModal';
import VideoPlayerModal from 'component/VideoPlayerModal/VideoPlayerModal';
import { getUserData } from 'helper';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const SharedVideosContainer = () => {
  const history = useHistory();
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [tableLoading, setTableLoading] = useState(false);
  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [showVideoLogsModal, setShowVideoLogsModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);
  const [deleteVideo] = useMutation(DELETE_VIDEO_MUTATION);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  // const { fetchMore, data } = useQuery(VIDEOS_PAGE_FILTER_QUERY, {
  //   variables: {
  //     paginationInput: {
  //       limit: PAGE_LIMIT,
  //       page: currentPage,
  //     },
  //     videosPageFilterInput: videosFilter,
  //     sorterInput: videosSorter,
  //     organizationId: selectedOrg == 'all' ? null : selectedOrg,
  //   },
  //   onCompleted: data => {
  //     if (data && data.videosPageFilter) {
  //       setVideos(data.videosPageFilter.videos);
  //       setPaginationData(data.videosPageFilter.pagination);
  //       setLoading(false);
  //     }
  //   },
  //   onError: err => {
  //     setLoading(false);
  //     console.log('err are', err);
  //   },
  //   fetchPolicy: 'cache-and-network',
  // });
  const { fetchMore, data } = useQuery(SHARED_VIDEOS_QUERY, {
    variables: {
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.sharedVideos) {
        setVideos(data.sharedVideos);
        // setPaginationData(data.sharedVideos.pagination);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      // console.log('videos', data);
      setVideos(data.sharedVideos);
      // setPaginationData(data.videosPageFilter.pagination);
      // setLoading(false);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  const onEditVideo = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowEditVideoModal(true);
    // history.push({
    //   pathname: '/videoedit',
    //   search: `?vid=${selectedVideo._id}`,
    // });
  };

  const openVideoPlayerModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoPlayerModal(true);
  };
  const closeVideoPlayerModal = () => {
    setCurrentItem(null);
    setShowVideoPlayerModal(false);
  };

  const openVideoLogsModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoLogsModal(true);
  };
  const closeVideoLogsModal = () => {
    setCurrentItem(null);
    setShowVideoLogsModal(false);
  };
  const closeEditVideoModal = () => {
    setCurrentItem(null);
    setShowEditVideoModal(false);
  };
  const onDeleteVideo = id => {
    message.loading({ content: 'Deleting video.', key: 'deleteMessage', duration: 0 });
    setTableLoading(true);
    deleteVideo({ variables: { _id: id } })
      .then(data => {
        if (videos) {
          let temp = videos;
          let index = temp.findIndex((i: any) => i._id == id);
          temp.splice(index, 1);
          setVideos(temp);
        }
        message.destroy();
        setTableLoading(false);
        notification.success({
          message: 'Video Deleted',
          duration: 0,
          description: 'Video was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        setTableLoading(false);
        notification.error({
          message: 'Video Delete Failed',
          duration: 0,
          description: 'Video was not deleted.',
        });
      });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <SharedVideosTable
            onEditVideo={onEditVideo}
            onDeleteVideo={onDeleteVideo}
            videos={videos}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            openVideoPlayerModal={openVideoPlayerModal}
            openVideoLogsModal={openVideoLogsModal}
          />
        </Col>
      </Row>
      <VideoPlayerModal visible={showVideoPlayerModal} video={currentItem} closeModal={closeVideoPlayerModal} />
      <VideoLogsModal visible={showVideoLogsModal} video={currentItem} closeModal={closeVideoLogsModal} />
      <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} />
    </>
  );
};

export default SharedVideosContainer;
