import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const UPDATE_SHARE_INFO_MUTATION = gql`
  mutation updateShareInfo($_id: ID!, $input: VideoShareInfoInput!) {
    updateShareInfo(_id: $_id, input: $input) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
