import React, { useRef, useState } from 'react';
import { Button, Tooltip, Input, Icon, notification, Tag } from 'antd';
import moment from 'moment';
import AntdTable from 'component/elements';
import { USER_ROLES_QUERY } from 'apollo/user/queries';
import { SEND_RESET_PASSWORD_MUTATION } from 'apollo/user/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DropdownActions from 'component/DropdownActions/DropdownActions';
const PAGE_LIMIT = 50;

const UsersTable = ({
  users,
  tableLoading,
  openAddEditUserModal,
  openMembershipModal,
  openEditUserAccessModal,
  onDelete,
  paginationData,
  handleTableChange,
  userSorter,
  setTableLoading,
  onLikedVideos,
  onWatchHistory,
}) => {
  let searchInputRef: any = null;
  const [userRoles, setUserRoles] = useState<any>(null);
  const [sendResetPassword] = useMutation(SEND_RESET_PASSWORD_MUTATION);
  useQuery(USER_ROLES_QUERY, {
    onCompleted: data => {
      setUserRoles(data.getUserRoles);
    },
  });

  const getSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ref => (searchInputRef = ref)}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = clearFilters => {
    clearFilters();
  };

  const onPasswordReset = user => {
    setTableLoading(true);
    sendResetPassword({
      variables: {
        _id: user._id,
      },
    })
      .then(result => {
        if (result.data && result.data.sendResetPassword) {
          const { type, payload } = result.data.sendResetPassword;
          if (type == 'url') {
            if (payload) {
              window.location.assign(payload);
            } else {
              notification.error({
                message: type,
                description: 'No Url provided by location. Contact Your location.',
              });
            }
          } else if (type == 'message') {
            notification.info({
              message: type,
              description: payload,
            });
          }
        }
        setTableLoading(false);
      })
      .catch(err => {
        setTableLoading(false);
        console.error('send reset pw error', err);
        const errorMessage = err.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Send Reset Password Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      render: record => <span>{record.firstName}</span>,
      ...getSearchProps('firstName'),
    },
    {
      key: 'lastName',
      title: 'Last Name',
      render: record => <span>{record.lastName}</span>,
      ...getSearchProps('lastName'),
    },

    {
      key: 'email',
      title: 'Email',
      render: record => {
        return <span>{record.email}</span>;
      },
      ...getSearchProps('email'),
    },
    {
      key: 'name',
      title: 'Organization Name',
      render: record => {
        return <span>{record.organization && record.organization.name}</span>;
      },
    },
    {
      key: 'role',
      title: 'Role',
      render: record => {
        return <span>{record.role}</span>;
      },
      filters:
        userRoles &&
        userRoles.map(item => ({
          text: item,
          value: item,
        })),
      filterMultiple: true,
    },
    {
      key: 'accessType',
      title: 'Access',
      render: record => {
        const access: any = [];
        if (record.adHoc) {
          access.push(<Tag color="purple">Ad Hoc</Tag>);
        }
        let packages = record.externalUser?.customData?.packages || [];
        let whitelistIds = record.organization?.settings?.whitelistResourceId || [];
        const common = packages.filter(item => whitelistIds.includes(item.membershipId));
        if (common && common.length > 0) {
          access.push(<Tag color="blue">Has Membership</Tag>);
        }
        return <div>{access.map(item => item)}</div>;
      },
    },
    {
      key: 'waiverFormUrl',
      title: 'Waiver',
      render: record => {
        if (record.waiverFormUrl) {
          return <Button onClick={() => window.open(record.waiverFormUrl, '_blank')}>View</Button>;
        }
      },
    },
    {
      key: 'lastSessionAt',
      title: 'Last Session',
      render: record => moment(record.lastSessionAt).format('Do MMMM, hh:mm a'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: userSorter.field == 'lastSessionAt' ? userSorter.order : false,
      sorter: true,
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: userSorter.field == 'createdAt' ? userSorter.order : false,
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <DropdownActions
          menuItem={[
            ...(record.externalId
              ? [
                  {
                    key: 'viewMembership',
                    title: 'View Membership',
                    onClick: () => openMembershipModal(record),
                  },
                ]
              : []),
            {
              key: 'edit',
              title: 'Edit',
              onClick: () => openAddEditUserModal(record),
            },
            {
              key: 'resetPw',
              title: 'Send Reset Password',
              onClick: () => onPasswordReset(record),
            },
            {
              key: 'likedVideos',
              title: 'Liked Videos',
              onClick: () => onLikedVideos(record),
            },
            {
              key: 'watchHistory',
              title: 'Watch Timeline',
              onClick: () => onWatchHistory(record),
            },
            ...(record.role == 'staff' || record.role == 'trainer'
              ? [
                  {
                    key: 'editAccess',
                    title: 'Edit Access',
                    onClick: () => openEditUserAccessModal(record),
                  },
                ]
              : []),
            {
              key: 'delete',
              title: 'Delete',
              onClick: () => onDelete(record),
            },
          ]}
        />
      ),
    },
  ];
  return (
    <div>
      <AntdTable
        scroll={{ x: true }}
        loading={tableLoading}
        dataSource={users}
        onChange={handleTableChange}
        pagination={{
          position: 'bottom',
          pageSize: PAGE_LIMIT,
          total: paginationData.total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          // onChange: handlePageChange,
        }}
        rowKey={record => record._id}
        columns={columns}
      />
    </div>
  );
};

export default UsersTable;
