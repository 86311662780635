import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditModerationModal from 'component/AddEditModerationModal/AddEditModerationModal';

const ModerationTable = ({ moderations, organization, onAdd, onDelete, onEdit }) => {
  // const [activities, setActivities] = useState<any>(null);
  // const [trainers, setTrainers] = useState<any>(null);
  // const [organizations, setOrganizations] = useState<any>(null);
  const [showAddEditModerationModal, setShowAddEditModerationModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedModeration, setSelectedModeration] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  // useQuery(ORGANIZATIONS_QUERY, {
  //   onCompleted: data => {
  //     setOrganizations(data.organizations);
  //   },
  // });
  // useQuery(ACTIVITIES_QUERY, {
  //   onCompleted: data => {
  //     setActivities(data.activities);
  //   },
  // });
  // useQuery(TRAINERS_QUERY, {
  //   onCompleted: data => {
  //     setTrainers(data.trainers);
  //   },
  // });
  const columns = [
    {
      key: 'from',
      title: 'From',
      render: record => <p style={{ margin: 0, padding: 0 }}>{record.from.name}</p>,
    },
    {
      key: 'by',
      title: 'By',
      render: record => <p style={{ margin: 0, padding: 0 }}>{record.by}</p>,
    },
    {
      key: 'shareById',
      title: 'Share By Id',
      render: record => (
        <p style={{ margin: 0, padding: 0 }}>
          {record.by == 'all' ? '-' : record.by == 'trainer' ? record.shareByTrainer?.name : record.shareByActivity?.name}
        </p>
      ),
    },
    {
      key: 'action',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedModeration(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditModerationModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditModerationModal = () => {
    setSelectedModeration(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditModerationModal(false);
  };

  const onAddModeration = moderation => {
    const { from, by, shareByTrainer, shareByActivity } = moderation;
    const temp = {
      from: {
        _id: from.key,
        name: from.label,
      },
      by: by,
      ...(shareByTrainer && { shareByTrainer: { _id: shareByTrainer.key, name: shareByTrainer.label } }),
      ...(shareByActivity && { shareByActivity: { _id: shareByActivity.key, name: shareByActivity.label } }),
    };
    onAdd(temp);
    closeAddEditModerationModal();
  };
  const onEditModeration = moderation => {
    const { from, by, shareByTrainer, shareByActivity } = moderation;
    const temp = {
      from: {
        _id: from.key,
        name: from.label,
      },
      by: by,
      ...(shareByTrainer && { shareByTrainer: { _id: shareByTrainer.key, name: shareByTrainer.label } }),
      ...(shareByActivity && { shareByActivity: { _id: shareByActivity.key, name: shareByActivity.label } }),
    };
    onEdit(temp, selectedIndex);
    closeAddEditModerationModal();
  };

  return (
    <>
      {/* {organizations && activities && trainers ? ( */}
      <div>
        <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditModerationModal(true)}>
          Add Moderation
        </Button>
        <AntdTable
          pagination={false}
          rowKey={record => `${record.from._id}${record.by}${record.shareByTrainer?._id}${record.shareByActivity?._id}`}
          columns={columns}
          dataSource={moderations}
          size="small"
        />
        <AddEditModerationModal
          ownOrganization={organization}
          visible={showAddEditModerationModal}
          currentItem={selectedModeration}
          isCreate={isCreate}
          onAdd={onAddModeration}
          onEdit={onEditModeration}
          onCancel={closeAddEditModerationModal}
        />
      </div>
      {/* ) : (
        <Loader type="TailSpin" color={'#202541'} />
      )} */}
    </>
  );
};

export default ModerationTable;
