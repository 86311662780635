import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Switch, notification, Button, Checkbox, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UPDATE_SHARE_INFO_MUTATION, UPDATE_VIDEO_MUTATION } from 'apollo/video/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';

interface VideoShareInfoProps extends FormComponentProps {
  video: any;
}

const VideoShareInfoForm = ({ form, video }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState<any>(null);
  const [updateShareInfo] = useMutation(UPDATE_SHARE_INFO_MUTATION, { refetchQueries: ['video'] });
  const [shareVideoParam, setShareVideoParam] = useState<any>({
    shared: false,
    sharedTo: [],
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      setOrganizations(data.organizations);
    },
  });

  useEffect(() => {
    if (video) {
      setShareVideoParam(prev => ({
        ...prev,
        shared: !!video.shared,
        sharedTo: video.sharedTo || [],
      }));
    }
  }, [video]);

  const { getFieldDecorator } = form;

  const onSharedChange = val => {
    // console.log('trainer', val);
    setShareVideoParam(prev => ({ ...prev, shared: val }));
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        shared: shareVideoParam.shared,
        sharedTo: shareVideoParam.shared ? values.sharedTo : [],
      };
      updateShareInfo({
        variables: {
          _id: video._id,
          input: {
            ...params,
          },
        },
      })
        .then(updatedResult => {
          setIsLoading(false);
          notification.success({
            message: 'Video Share Info Updated',
            duration: 0,
            description: 'Video share info was updated successfully.',
          });
        })
        .catch(e => {
          setIsLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Video Share Info Update Failed',
            duration: 0,
            description: errorMessage,
          });
        });
    });
  };

  const options =
    organizations &&
    organizations.map(item => {
      if (item._id !== video.organizationId) {
        return (
          <Col key={item._id} span={24}>
            <Checkbox value={item._id}>{item.name}</Checkbox>
          </Col>
        );
      }
    });

  return (
    <div>
      <h4>Share Info</h4>
      <Form layout="vertical">
        <Form.Item label="Share">
          {getFieldDecorator('shared', {
            initialValue: { checked: shareVideoParam.shared },
            rules: [
              // {
              //   required: true,
              //   message: 'Please input the title of Video!',
              // },
            ],
          })(<Switch checked={shareVideoParam.shared} onChange={onSharedChange} />)}
        </Form.Item>
        <Form.Item label="Share to:">
          {getFieldDecorator('sharedTo', {
            initialValue: shareVideoParam.sharedTo,
            rules: [
              // {
              //   required: true,
              //   message: 'Please input the title of Video!',
              // },
            ],
          })(
            <Checkbox.Group
              onChange={val => {
                setShareVideoParam(prev => ({ ...prev, sharedTo: val }));
              }}
            >
              <Row>{options}</Row>
            </Checkbox.Group>
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'right' }}>
            <Button loading={isLoading} type="primary" style={{ marginRight: 10 }} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
const VideoShareInfoEdit = Form.create<VideoShareInfoProps>()(VideoShareInfoForm);

export default VideoShareInfoEdit;
