import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_MUTATION } from 'apollo/user/mutations';
import AntdButton from '../../../component/elements/AntdButton';
import { setToken, setUserData } from '../../../helper';
import { Form, Card, Input, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ForgotPwModal from 'component/ForgotPwModal/ForgotPwModal';

interface Props extends FormComponentProps {}

const LoginForm = (props: Props) => {
  const { form } = props;
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [err, setErr] = useState<string>();
  const [load, setLoad] = useState<boolean>();
  const [loginUser] = useMutation(LOGIN_MUTATION);
  const [showForgotPwModal, setShowForgotPwModal] = useState(false);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleLogin = () => {
    setLoad(true);
    form.validateFields(err => {
      if (err) setLoad(false);
      if (!err) {
        try {
          loginUser({
            variables: {
              email,
              password,
            },
          })
            .then(async result => {
              console.log('---datasss----', result);
              if (result.data && result.data.login) {
                await setToken(result.data.login.jwt);
                await setUserData(JSON.stringify(result.data.login.user));
                window.location.reload();
                setLoad(false);
              }
            })
            .catch(error => {
              if (error.graphQLErrors && error.graphQLErrors.length) {
                const errMessage = error.graphQLErrors[0].message;
                setErr(errMessage);
                setLoad(false);
              }
            });
          // instance.post('/admin/login', {
          //     email: email,
          //     password: password
          // }).then(res => {
          //     setToken(res.data);
          //     window.location.reload();
          //     setLoad(false);
          // }).catch(res => {
          //     let err = res.response.data;
          //     setErr(err);
          //     setErr(err);
          // });
        } catch (res) {
          let err = res.response.data;
          setErr(err);
          setLoad(false);
        }
      }
    });
  };

  const handleLoginInput = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };

  return (
    <LoginWrapper>
      <FormWrapper>
        <Card
          title={<FormTitle>Account Login</FormTitle>}
          style={{
            width: '400px',
            borderRadius: '15px',
            backgroundColor: '#fff',
          }}
        >
          <p style={{ color: '#f00', marginBottom: '10px' }}>
            <strong>{err}</strong>
          </p>
          <Form {...layout}>
            <Form.Item label="Email" labelAlign="left">
              {form.getFieldDecorator('admin-email', {
                rules: [{ required: true, message: 'Enter your Email' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  onChange={e => handleLoginInput(e.target.value, setEmail)}
                />
              )}
            </Form.Item>
            <Form.Item label="Password" labelAlign="left">
              {form.getFieldDecorator('admin-password', {
                rules: [{ required: true, message: 'Enter your password' }],
              })(
                <Input.Password
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  onChange={e => handleLoginInput(e.target.value, setPassword)}
                  onPressEnter={handleLogin}
                />
              )}
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <div style={{ marginBottom: 15 }}>
                <a style={{ color: '#647af5' }} onClick={() => setShowForgotPwModal(true)}>
                  Forgot Password
                </a>
              </div>
              <AntdButton type="primary" onClick={handleLogin} loading={load}>
                Login
              </AntdButton>
            </div>
          </Form>
        </Card>
      </FormWrapper>
      <ForgotPwModal visible={showForgotPwModal} onDone={() => setShowForgotPwModal(false)} />
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #202541, #647af5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div``;

const FormTitle = styled.div`
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  color: #4d4d4d;
`;

const Login = Form.create()(LoginForm);

export default Login;
