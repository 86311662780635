import React, { isValidElement, useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Input, message, Select, Radio, Switch, DatePicker, notification, Button, Upload, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UPDATE_VIDEO_MUTATION } from 'apollo/video/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import Loader from 'react-loader-spinner';
import Api from 'axios/Api';
import styled from 'styled-components';

const equipments = [
  'No Equipment',
  'Balance Board',
  'Barbell',
  'Bench',
  'Bosu',
  'Box/Step',
  'Chair',
  'Dumbbells',
  'Elastic Bands',
  'Exercise Ball',
  'Foam Roller',
  'Kettle Bell',
  'Medicine Ball',
  'Resistence Band',
  'Rope',
  'Speed Ladder',
  'Stick',
  'Tennis Ball',
];
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  &:hover {
    background: #e6f7ff;
  }
`;
interface VideoDetailEditProps extends FormComponentProps {
  video: any;
  setLoading: any;
}

const { TextArea } = Input;
const { Option } = Select;

const VideoDetailEditForm = ({ form, video }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [thumbnail, setThumbnail] = useState<any>([]);
  const [caption, setCaption] = useState<any>([]);
  const [updateVideo] = useMutation(UPDATE_VIDEO_MUTATION, { refetchQueries: ['video'] });
  const [videoParam, setVideoParam] = useState({
    title: undefined,
    description: undefined,
    isLive: false,
    activity: { key: undefined, label: undefined },
    trainer: { key: undefined, label: undefined },
    intensity: '',
    equipments: [],
    tags: [],
    premium: false,
    premiere: false,
    status: 'published',
    allowComments: false,
    premiereAt: null as any,
    thumbnail: undefined,
    captionUrl: undefined,
  });
  useEffect(() => {
    if (video) {
      setVideoParam(prev => ({
        ...prev,
        title: video.title,
        description: video.description,
        activity: { key: video.activity._id, label: video.activity.name },
        intensity: video.intensity,
        equipments: video.equipments,
        tags: video.tags,
        trainer: { key: video.trainer._id, label: video.trainer.name },
        premiere: video.premiere,
        premiereAt: video.premiereAt ? moment(video.premiereAt) : null,
        allowComments: video.allowComments,
        thumbnail: video.thumbnail,
        status: video.status || 'published',
        captionUrl: video.captionUrl,
      }));
    }
  }, [video]);

  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      // console.log('trainers', data.trainers);
      setTrainers(data.trainers);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      // console.log('activities', data);
      setActivities(data.activities);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });

  const { getFieldDecorator } = form;
  const renderEquipments = equipments.map(item => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));
  const onAllowCommentsChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, allowComments: val }));
  };
  const onIntensityChange = val => {
    // console.log('intensity', val);
    setVideoParam(prev => ({ ...prev, intensity: val }));
  };
  const handleEquipmentChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, equipments: val }));
  };
  const handleStatusChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, status: val }));
  };
  const onPremiereChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, premiere: val }));
  };

  const onDateChange = val => {
    setVideoParam(prev => ({ ...prev, premiereAt: val }));
  };
  const handleSubmit = () => {
    form.validateFields(async (formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      // return;
      try {
        let date = values.premiereAt ? moment(values.premiereAt).format('MMM DD, YYYY') : moment(video.createdAt).format('MMM DD, YYYY');
        let title = values.title == undefined || values.title == '' ? `${values.activity.label} - ${date}` : values.title;
        setIsLoading(true);
        let url = null;
        let captionUrl = null;
        if (thumbnail.length > 0) {
          console.log(thumbnail);
          const res = await Api.uploadFile(thumbnail[0]);
          console.log('data', res);
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Video Update Failed',
              duration: 0,
              description: errorMessage,
            });
            return;
          }
          url = data.result.url;
        }
        if (caption.length > 0) {
          // console.log('caption', caption);
          const res = await Api.uploadFile(caption[0]);
          // console.log('data caption', res);
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Video Create Failed',
              duration: 0,
              description: errorMessage,
            });
            return;
          }
          captionUrl = data.result.url;
        }
        const params = {
          title: title,
          description: values.description,
          tags: values.tags,
          isLive: video.isLive,
          premiere: videoParam.premiere,
          status: values.status,
          allowComments: videoParam.allowComments,
          thumbnail: url || videoParam.thumbnail || null,
          premiereAt: videoParam.premiere ? values.premiereAt.toISOString() : null,
          ...(!video.parentVideoId
            ? {
                captionUrl: captionUrl || videoParam.captionUrl || null,
                activity: values.activity.key,
                trainer: values.trainer.key,
                intensity: values.intensity,
                equipments: values.equipments,
              }
            : {}),
        };
        updateVideo({
          variables: {
            _id: video._id,
            input: {
              ...params,
            },
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            setCaption([]);
            notification.success({
              message: 'Video Updated',
              duration: 0,
              description: 'Video was updated successfully.',
            });
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Video Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } catch (error) {
        setIsLoading(false);
        const errorMessage = error.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Video Update Failed',
          duration: 0,
          description: errorMessage,
        });
      }
    });
  };

  const beforeUploadThumbnail = file => {
    // console.log('file', file);
    setThumbnail(prev => [file]);
    getBase64(file, imageUrl => {
      setVideoParam(prev => ({ ...prev, thumbnail: imageUrl }));
    });
    return false;
  };

  const onRemoveThumbnail = file => {
    // console.log('here', file);
    setThumbnail(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, thumbnail: (video && video.thumbnail) || undefined }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUploadCaption = file => {
    // console.log('file', file);
    setCaption(prev => [file]);
    setVideoParam(prev => ({ ...prev, captionUrl: undefined }));
    return false;
  };

  const onRemoveCaption = file => {
    // console.log('here', file);
    setCaption(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, captionUrl: undefined }));
  };

  return (
    <>
      {trainers && activities && video ? (
        <Form layout="vertical">
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              initialValue: videoParam.title,
            })(<Input placeholder="Enter Title" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: videoParam.description,
            })(<TextArea rows={3} placeholder="Enter Description" />)}
          </Form.Item>
          <Form.Item label="Tags">
            {getFieldDecorator('tags', {
              initialValue: videoParam.tags,
            })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags"></Select>)}
          </Form.Item>
          {!video.parentVideoId && (
            <>
              <Form.Item
                label={
                  <div>
                    <label>Thumbnail</label>
                    {!!videoParam.thumbnail && (
                      <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
                        <img
                          alt="video preview"
                          src={videoParam.thumbnail}
                          style={{
                            background: '#fff',
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                          draggable={false}
                        />
                      </div>
                    )}
                  </div>
                }
              >
                {getFieldDecorator('thumbnail', {
                  initialValue: { fileList: thumbnail },
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: 'Please input the name of the organization!',
                  //   },
                  // ],
                })(
                  <Upload accept="image/*" fileList={thumbnail} beforeUpload={beforeUploadThumbnail} onRemove={onRemoveThumbnail}>
                    <Button>
                      <Icon type="upload" /> Upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>
              <Form.Item
                label="Closed Caption"
                extra={
                  videoParam.captionUrl && (
                    <StyledDiv>
                      <div>caption</div>
                      <div>
                        <Button onClick={() => setVideoParam(prev => ({ ...prev, captionUrl: undefined }))} type="link" icon="delete"></Button>
                      </div>
                    </StyledDiv>
                  )
                }
              >
                {getFieldDecorator('closedCaption', {
                  // initialValue: { fileList: thumbnail },
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: 'Please input the name of the organization!',
                  //   },
                  // ],
                })(
                  <Upload accept="text/*,.vtt" fileList={caption} beforeUpload={beforeUploadCaption} onRemove={onRemoveCaption}>
                    {/* <Popconfirm title="Coming Soon"> */}
                    <Button>
                      <Icon type="upload" /> Upload
                    </Button>
                    {/* </Popconfirm> */}
                  </Upload>
                )}
              </Form.Item>
              <Form.Item label="Trainer">
                {getFieldDecorator('trainer', {
                  initialValue: videoParam.trainer,
                  rules: [
                    {
                      required: true,
                      message: 'Please input the trainer!',
                    },
                  ],
                })(
                  <Select
                    labelInValue
                    showSearch
                    placeholder="Select a trainer"
                    optionFilterProp="children"
                    // onChange={this.handleSelectChange}
                    filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {trainers.length > 0 &&
                      trainers.map((item: any) => (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Activity">
                {getFieldDecorator('activity', {
                  initialValue: videoParam.activity,
                  rules: [
                    {
                      required: true,
                      message: 'Please input the actvity!',
                    },
                  ],
                })(
                  <Select
                    labelInValue
                    showSearch
                    placeholder="Select a activity"
                    optionFilterProp="children"
                    // onChange={this.handleSelectChange}
                    filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {activities.length > 0 &&
                      activities.map((item: any) => (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Intensity">
                {getFieldDecorator('intensity', {
                  initialValue: videoParam.intensity,
                  rules: [
                    {
                      required: true,
                      message: 'Please input the actvity!',
                    },
                  ],
                })(
                  <Radio.Group onChange={onIntensityChange}>
                    <Radio.Button value="low">Low</Radio.Button>
                    <Radio.Button value="medium">Medium</Radio.Button>
                    <Radio.Button value="high">High</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="Equiment Needed">
                {getFieldDecorator('equipments', {
                  initialValue: videoParam.equipments,
                })(
                  <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                    {renderEquipments}
                  </Select>
                )}
              </Form.Item>
            </>
          )}
          <Form.Item label="Allow Comments">
            {getFieldDecorator('allowComments', {
              initialValue: { checked: videoParam.allowComments },
              rules: [
                {
                  required: true,
                  message: 'Please input the title of Video!',
                },
              ],
            })(<Switch checked={videoParam.allowComments} onChange={onAllowCommentsChange} />)}
          </Form.Item>
          <Form.Item label="Status">
            {getFieldDecorator('status', {
              initialValue: videoParam.status,
              rules: [
                {
                  required: true,
                  message: 'Please input the title of Video!',
                },
              ],
            })(
              <Select style={{ width: '100%' }} onChange={handleStatusChange}>
                <Option value="published">Publish</Option>
                <Option value="notPublished">Not Publish</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Premiere">
            {getFieldDecorator('premiere', {
              initialValue: { checked: videoParam.premiere },
              rules: [
                {
                  required: true,
                  message: 'Please input the title of Video!',
                },
              ],
            })(<Switch checked={videoParam.premiere} onChange={onPremiereChange} />)}
          </Form.Item>
          {videoParam.premiere && (
            <Form.Item label="Premiere At">
              {getFieldDecorator('premiereAt', {
                initialValue: videoParam.premiereAt,
                rules: [
                  {
                    required: true,
                    message: 'Please input premiere date!',
                  },
                ],
              })(<DatePicker showTime onChange={onDateChange} />)}
            </Form.Item>
          )}
          <Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button loading={isLoading} type="primary" style={{ marginRight: 10 }} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <Loader type="TailSpin" color="#202541" />
      )}
    </>
  );
};
const VideoDetailEdit = Form.create<VideoDetailEditProps>()(VideoDetailEditForm);

export default VideoDetailEdit;
