import React from 'react';
import { StyledHeader } from './HeaderStyles';
import { Row, Col, Icon, Button, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface Props {
  toggleSidebar: Function;
  collapse: boolean;
  organization: any;
  user: any;
}

const Header: React.FC<Props> = ({ toggleSidebar, collapse, organization = null, user = null }) => {
  const dropdownMenu = (
    <div style={{ background: 'white', padding: '1em 0', boxShadow: '0px 5px 10px 5px rgba(0,0,0,0.3)' }}>
      <div style={{ padding: '0 1em', display: 'flex' }}>
        <div>
          <Avatar size={50} icon={<UserOutlined />} src={user && user.avatarUrl} style={{ cursor: 'pointer' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
          <span style={{ fontSize: '1.15rem' }}>
            {user ? user.firstName[0].toUpperCase() + user.firstName.slice(1) : 'first'}{' '}
            {user ? user.lastName[0].toUpperCase() + user.lastName.slice(1) : 'last'}
          </span>
          <span style={{ fontSize: '1.15rem' }}>{user && `${user.role[0].toUpperCase() + user.role.slice(1)}`}</span>
        </div>
      </div>
      {/* <Divider style={{ margin: '0.5em 0' }} />
      <div style={{ padding: '0 1.5em', cursor: 'pointer' }} onClick={logOut}>
        <Button type="text" style={{ padding: 0, verticalAlign: 'middle' }}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <LogoutOutlined style={{ fontSize: '1.5rem', color: '#444' }} />
            <span style={{ margin: 0, marginLeft: 10, fontSize: '1.15rem' }}>Logout</span>
          </div>
        </Button>
      </div> */}
    </div>
  );

  return (
    <StyledHeader>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <span>
            <Button type="link" onClick={() => toggleSidebar(!collapse)}>
              <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} style={{ fontSize: '24px', color: '#444' }} />
            </Button>
          </span>
          <span>
            <Button type="link" onClick={() => document.body.requestFullscreen()}>
              <Icon type="fullscreen" style={{ fontSize: '24px', color: '#444' }} />
            </Button>
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 15 }}>{`${organization && organization.name}`}</span>
          <span>
            <Dropdown overlay={dropdownMenu} placement="bottomRight" trigger={['click']}>
              <Avatar size={40} icon={<UserOutlined />} src={user && user.avatarUrl} style={{ cursor: 'pointer' }} />
            </Dropdown>
          </span>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
