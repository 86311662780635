import { gql } from 'apollo-boost';

export const ANALYTICS_VIDEO_REPORT_PERWEEK_QUERY = gql`
  query analyticsVideoReportPerweek($startDate: String!, $endDate: String!, $organizationId: String, $analyticsFilterInput: AnalyticsFilterInput) {
    analyticsVideoReportPerweek(
      startDate: $startDate
      endDate: $endDate
      organizationId: $organizationId
      analyticsFilterInput: $analyticsFilterInput
    )
  }
`;
