import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const { Option } = Select;

interface AddEditCustomBubbleModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditCustomBubbleModalComponent: React.FC<AddEditCustomBubbleModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [organizations, setOrganizations] = useState<any>(null);
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customBubbleParam, setCustomBubbleParam] = useState<any>({
    bubbleType: undefined,
    bubbleContent: undefined,
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      setOrganizations(data.organizations);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      setTrainers(data.trainers);
    },
  });
  useEffect(() => {
    if (currentItem) {
      setCustomBubbleParam({
        ...customBubbleParam,
        bubbleType: currentItem.bubbleType,
        ...(currentItem.bubbleType === 'tag'
          ? { bubbleContent: currentItem.tag }
          : {
              bubbleContent: {
                key: currentItem.bubbleContent?._id,
                label: currentItem.bubbleContent?.name,
              },
            }),
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setCustomBubbleParam({
      bubbleType: undefined,
      bubbleContent: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const customBubble = {
        bubbleType: values.bubbleType,
        bubbleContent: values.bubbleContent,
      };
      if (isCreate) {
        onAdd(customBubble);
      } else {
        onEdit(customBubble);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Custom Bubble' : 'Edit Custom Bubble'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit} loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Type">
          {getFieldDecorator('bubbleType', {
            initialValue: customBubbleParam.bubbleType,
            rules: [
              {
                required: true,
                message: 'Please select Type!',
              },
            ],
          })(
            <Select
              placeholder="Select an option"
              onChange={val => {
                form.setFields({ bubbleContent: undefined });
                setCustomBubbleParam(prev => ({ ...prev, bubbleType: val, bubbleContent: undefined }));
              }}
            >
              <Option value="organization">Location</Option>
              <Option value="activity">Activity</Option>
              <Option value="trainer">Trainer</Option>
              <Option value="tag">Tag</Option>
            </Select>
          )}
        </Form.Item>
        {customBubbleParam.bubbleType == 'tag' && (
          <Form.Item label="Tag">
            {getFieldDecorator('bubbleContent', {
              initialValue: customBubbleParam.bubbleContent,
              rules: [
                {
                  required: true,
                  message: 'Please Enter Tag!',
                },
              ],
            })(<Input placeholder="Enter a Tag" />)}
          </Form.Item>
        )}
        {customBubbleParam.bubbleType == 'activity' && (
          <Form.Item label="Activity">
            {getFieldDecorator('bubbleContent', {
              initialValue: customBubbleParam.bubbleContent,
              rules: [
                {
                  required: true,
                  message: 'Please select activity!',
                },
              ],
            })(
              <Select placeholder="Select an activity" labelInValue={true}>
                {activities &&
                  activities.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
        {customBubbleParam.bubbleType == 'trainer' && (
          <Form.Item label="Trainer">
            {getFieldDecorator('bubbleContent', {
              initialValue: customBubbleParam.bubbleContent,
              rules: [
                {
                  required: true,
                  message: 'Please select trainer!',
                },
              ],
            })(
              <Select placeholder="Select a trainer" labelInValue={true}>
                {trainers &&
                  trainers.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
        {customBubbleParam.bubbleType == 'organization' && (
          <Form.Item label="Location">
            {getFieldDecorator('bubbleContent', {
              initialValue: customBubbleParam.bubbleContent,
              rules: [
                {
                  required: true,
                  message: 'Please select Location!',
                },
              ],
            })(
              <Select placeholder="Select a Location" labelInValue={true}>
                {organizations &&
                  organizations.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
const AddEditCustomBubbleModal = Form.create<AddEditCustomBubbleModalProps>()(AddEditCustomBubbleModalComponent);
export default AddEditCustomBubbleModal;
