import React from 'react';
import AntdTable from 'component/elements/AntdTable';
import { Button, Table } from 'antd';

const AllBillingTable = ({ data, newUnitPrice, onStorage, onBandwidth }) => {
  const columns = [
    {
      key: 'orgName',
      title: 'Organization Name',
      render: record => record.org.name,
    },
    {
      key: 'storage',
      title: 'Storage',
      render: record => `${record.uploadedSize} GB`,
      footer: data => {
        return <div>Summary: {data.reduce((sum, record) => sum + record.uploadedSize, 0)}</div>;
      },
    },
    {
      key: 'bandwidth',
      title: 'Bandwidth',
      render: record => `${record.videoSizeWatched} GB`,
    },
    {
      key: 'storageUnitPrice',
      title: 'Storage Unit Price',
      render: record => `$${record.storageUnitPrice}`,
    },
    {
      key: 'bandwidthPrice',
      title: 'Bandwidth Unit Price',
      render: record => `$${record.bandwidthUnitPrice}`,
    },
    {
      key: 'basePrice',
      title: 'Base Price',
      render: record => `$${record.totalPrice}`,
    },
    {
      key: 'newPrice',
      title: 'New Price',
      render: record => {
        let totalPrice = 0;
        const { storageUnitPrice, bandwidthUnitPrice } = newUnitPrice;
        const storagePrice = record.uploadedSize * storageUnitPrice || 0;
        const bandwidthPrice = record.videoSizeWatched * bandwidthUnitPrice || 0;
        totalPrice = storagePrice + bandwidthPrice;
        return `$${totalPrice.toFixed(2)}`;
      },
    },
    {
      key: 'action',
      title: 'Action',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button style={{ marginRight: 10 }} onClick={() => onStorage(record.org)}>
            Storage
          </Button>
          <Button onClick={() => onBandwidth(record.org)}>Bandwidth</Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={currentPageData => {
        const totalStorage = currentPageData.reduce((acc, cv) => acc + (Number(cv.uploadedSize) || 0), 0);
        const totalBandwidth = currentPageData.reduce((acc, cv) => acc + (Number(cv.videoSizeWatched) || 0), 0);
        return (
          <div>
            <div>Total Storage: {totalStorage} GB</div>
            <div>Total Bandwidth: {totalBandwidth} GB</div>
          </div>
        );
      }}
      pagination={false}
      rowKey={(record: any) => record?.org?._id}
      dataSource={data}
      columns={columns}
    />
  );
};

export default AllBillingTable;
