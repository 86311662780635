import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { ORGANIZATION_BILLING_INFO_QUERY } from 'apollo/organization/queries';
import moment from 'moment';
import LoadingSpinner from 'component/Loader';
import { NetworkStatus } from 'apollo-boost';
import { Col, Row, DatePicker, Button, Card } from 'antd';
import BillingTable from 'component/BillingTable/BillingTable';
import Api from 'axios/Api';
import download from 'downloadjs';
import { CSVLink } from 'react-csv';

const { RangePicker } = DatePicker;

const OrganizationBillingContainer = () => {
  const params: any = useParams();
  const [info, setInfo] = useState<any>(null);
  const [rawInfo, setRawInfo] = useState<any>(null);
  const [organization, setOrganization] = useState<any>(null);
  const [startDate, setStartDate] = useState(moment().startOf('month').startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const { data, refetch, loading, networkStatus } = useQuery(ORGANIZATION_BILLING_INFO_QUERY, {
    variables: {
      organizationId: params.organizationId,
      dateRange: {
        start: moment().startOf('month').startOf('day').toISOString(),
        end: moment().endOf('day').toISOString(),
      },
    },
    // onCompleted: data => {
    //   console.log('data', data);
    // },
    // onError: error => {
    //   console.error('error', error);
    // },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && data.organizationBillingInfo) {
      // console.log('first data', data);
      const temp: any = [];
      const { organizationBillingInfo } = data;
      setRawInfo(organizationBillingInfo);
      setOrganization(organizationBillingInfo.organization);
      for (const o in organizationBillingInfo) {
        if (o !== 'organization') {
          temp.push({ label: o, value: organizationBillingInfo[o] });
        }
      }
      // console.log('temp', temp);
      setInfo(temp);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner />;
  const refetchInfo = () => {
    refetch({
      organizationId: params.organizationId,
      dateRange: {
        start: moment(startDate).startOf('day').toISOString(),
        end: moment(endDate).endOf('day').toISOString(),
      },
    });
  };

  const onRangeChange = value => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  const printBillingInfo = () => {
    const input = document.getElementById('billing');
    Api.printBilling(input?.outerHTML)
      .then(res => {
        download(res.data, 's.pdf');
      })
      .catch(err => console.log('err', err));
  };

  return (
    <Row style={{ marginTop: '30px' }}>
      <Col offset={1} span={22} style={{}}>
        <div style={{ background: 'white', padding: 20, marginBottom: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label>Date Range:</label>
              <RangePicker
                style={{ marginLeft: 10 }}
                value={[startDate, endDate]}
                disabledDate={(date: any) => {
                  return date && date > moment().endOf('day');
                }}
                onChange={onRangeChange}
              />
              <Button type="primary" style={{ marginLeft: 10 }} onClick={refetchInfo}>
                Apply
              </Button>
            </div>
            <div>
              <CSVLink data={info || [{}]} filename={`billing-${organization?.name.replaceAll(' ', '') || ''}.csv`}>
                <Button>Get CSV</Button>
              </CSVLink>
              {/* <Button style={{ marginLeft: 10 }} onClick={printBillingInfo}>
                Print
              </Button> */}
            </div>
          </div>
          <div id="billing">
            {organization && (
              <div style={{ marginTop: 30 }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Name: </span>
                <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{organization.name}</span>
                <br />
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Email: </span>
                <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{organization.email}</span>
                <br />
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Contact No: </span>
                <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{organization.phoneNumber}</span>
                <br />
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Location: </span>
                <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{`${!!organization.city ? organization.city + ', ' : ''} ${
                  organization.country || ''
                }`}</span>
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <span>From: {moment(rawInfo?.from).format('DD MMM YYYY')}</span>
              <span style={{ marginLeft: 20 }}>To: {moment(rawInfo?.to).format('DD MMM YYYY')}</span>
            </div>
          </div>
        </div>
        {rawInfo && (
          <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
              <Card title="Video Starts" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.videoStarts}</span>
              </Card>
              <Card title="Video Completes" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.videoCompletes}</span>
              </Card>
              <Card title="Time Watched" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{(rawInfo.videoTimeWatched / 60).toFixed(0)} min</span>
              </Card>
              <Card title="Uploaded Size" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.uploadedSize} Gb</span>
              </Card>
              <Card title="Watched Size" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.videoSizeWatched} Gb</span>
              </Card>
              <Card title="Own Video" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.ownVideosCount}</span>
              </Card>
              <Card title="Shared Video" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>{rawInfo.sharedVideosCount}</span>
              </Card>
              <Card title="Storage Price" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>$ {rawInfo.storagePrice}</span>
              </Card>
              <Card title="Bandwidth Price" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>$ {rawInfo.bandwidthPrice}</span>
              </Card>
              <Card title="Total Price" style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, marginRight: 10 }}>
                <span style={{ textAlign: 'center', fontSize: 40 }}>$ {rawInfo.totalPrice}</span>
              </Card>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default OrganizationBillingContainer;
