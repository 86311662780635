import { gql } from 'apollo-boost';
import { MEMBERSHIP_FRAGMENT } from '../membershipFragment';

export const MEMBERSHIP_QUERY = gql`
  query membership($_id: ID!) {
    membership(_id: $_id) {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`;
