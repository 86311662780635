import React, { useState, useEffect } from 'react';
import { Row, Col, message, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import LoadingSpinner from 'component/Loader';
import CommentsVideoTable from 'component/CommentsVideoTable/CommentsVideoTable';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import { getUserData } from 'helper';
import { COMMENTS_QUERY } from 'apollo/comment/queries';
import { DELETE_COMMENT_MUTATION } from 'apollo/comment/mutations';

const PAGE_LIMIT = 10;

const CommentsContainer = () => {
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false as any);
  const [commentsFilter, setCommentsFilter] = useState<any>({});
  const [commentsSorter, setCommentsSorter] = useState<any>({
    field: 'createdAt',
    order: 'descend',
  });

  const [comments, setComments] = useState<null | Array<Object>>(null);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION);

  const { fetchMore, data } = useQuery(COMMENTS_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      commentsFilterInput: commentsFilter,
      sorterInput: commentsSorter,
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.comments) {
        setComments(data.comments.comments);
        setPaginationData(data.comments.pagination);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.error('err are', err);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      // console.log('comments', data);
      setComments(data.comments.comments);
      // setLoading(false);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let commentsFilterInput = {};
    if (filters.name && filters.name.length) commentsFilterInput['name'] = filters.name[0];
    if (filters.content && filters.content.length) commentsFilterInput['content'] = filters.content[0];

    setCommentsFilter(commentsFilterInput);
    let sorterInput = {};
    sorterInput['field'] = sorter.columnKey;
    sorterInput['order'] = sorter.order ? sorter.order : 'descend';
    setCommentsSorter(sorterInput);
    fetch(paginationInput, commentsFilterInput, sorterInput);
  };

  const fetch = (paginationInput, commentsFilterInput: any = null, sorterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', commentsFilterInput);
    // console.log('sortterinput', sorterInput);
    setTableLoading(true);
    fetchMore({
      variables: {
        paginationInput,
        ...(commentsFilterInput && { commentsFilterInput: commentsFilterInput }),
        ...(sorterInput && { sorterInput: sorterInput }),
        organizationId: selectedOrg == 'all' ? null : selectedOrg,
      },
      updateQuery: (prev, { fetchMoreResult }: any) => {
        // console.log('fetchmore results', fetchMoreResult);
        if (!fetchMoreResult) return prev;
        setComments(fetchMoreResult.comments.comments);
        setPaginationData(fetchMoreResult.comments.pagination);
        setTableLoading(false);
      },
    });
  };
  const onDeleteComment = id => {
    message.loading({ content: 'Deleting video.', key: 'deleteMessage', duration: 0 });
    setTableLoading(true);
    deleteComment({ variables: { _id: id } })
      .then(data => {
        if (comments) {
          let temp = comments;
          let index = temp.findIndex((i: any) => i._id == id);
          temp.splice(index, 1);
          setComments(temp);
        }
        message.destroy();
        setTableLoading(false);
        notification.success({
          message: 'Comment Deleted',
          duration: 0,
          description: 'Comment was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        setTableLoading(false);
        notification.error({
          message: 'Comment Delete Failed',
          duration: 0,
          description: 'Comment was not deleted.',
        });
      });
  };
  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <CommentsVideoTable
            comments={comments}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            onDeleteComment={onDeleteComment}
            paginationData={paginationData}
            handleTableChange={handleTableChange}
            commentsSorter={commentsSorter}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommentsContainer;
