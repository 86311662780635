import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Modal, Form, Input, Switch, notification, Row, Col, Icon, Select, Upload, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ORGANIZATION_CREATE_MUTATION, ORGANIZATION_UPDATE_MUTATION } from 'apollo/organization/mutations';
import { GET_API_SYSTEMS_QUERY, ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import CountriesCities from './../../helper/countries.json';
import Api from 'axios/Api';

const { TextArea } = Input;
const { Option } = Select;

const Countries = Object.keys(CountriesCities);
interface OrganizationModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddEditOrganizationModalComponent: React.FC<OrganizationModalProps> = ({
  form,
  isCreate,
  currentItem,
  handleCancel,
  handleOk,
  modalVisible,
}) => {
  const [orgLogo, setOrgLogo] = useState<any>([]);
  const [apiSystems, setApiSystems] = useState<any>(null);
  const [selectCities, setSelectCities] = useState<any>(null);
  const [organizationCreate] = useMutation(ORGANIZATION_CREATE_MUTATION, {
    update: (cache, { data: { organizationCreate } }) => {
      const cachedData: any = cache.readQuery({ query: ORGANIZATIONS_QUERY });
      cache.writeQuery({
        query: ORGANIZATIONS_QUERY,
        data: { organizations: [...cachedData.organizations, organizationCreate] },
      });
    },
    refetchQueries: ['organizations'],
  });
  const [organizationUpdate] = useMutation(ORGANIZATION_UPDATE_MUTATION);
  useQuery(GET_API_SYSTEMS_QUERY, {
    onCompleted: data => {
      setApiSystems(data.getApiSystems);
    },
    onError: err => console.log('err in fetching api systems', err),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [organizationParam, setOrganizationParam] = useState<any>({
    name: undefined,
    provider: undefined,
    email: undefined,
    country: undefined,
    status: 'active',
    city: undefined,
    logo: undefined,
    phoneNumber: undefined,
    description: undefined,
    subdomain: [],
    disclaimer: undefined,
    loginPageTitle: undefined,
    isProduction: false,
    storageUnitPrice: 0,
    bandwidthUnitPrice: 0,
  });

  useEffect(() => {
    if (currentItem) {
      setOrganizationParam(prev => ({
        ...prev,
        name: currentItem.name,
        provider: currentItem.provider,
        email: currentItem.email,
        country: currentItem.country,
        status: currentItem.status,
        city: currentItem.city,
        logo: currentItem.logo,
        phoneNumber: currentItem.phoneNumber,
        description: currentItem.description,
        subdomain: currentItem.subdomain || [],
        disclaimer: (currentItem.customData && currentItem.customData.disclaimer) || undefined,
        loginPageTitle: (currentItem.customData && currentItem.customData.loginPageTitle) || undefined,
        isProduction: currentItem.isProduction || false,
        storageUnitPrice: (currentItem.customData && currentItem.customData.storageUnitPrice) || 0,
        bandwidthUnitPrice: (currentItem.customData && currentItem.customData.bandwidthUnitPrice) || 0,
      }));
      setSelectCities(CountriesCities[currentItem.country]);
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    setOrganizationParam({
      name: undefined,
      provider: undefined,
      email: undefined,
      country: undefined,
      status: 'active',
      city: undefined,
      logo: undefined,
      phoneNumber: undefined,
      description: undefined,
      subdomain: [],
      disclaimer: undefined,
      loginPageTitle: undefined,
      isProduction: false,
      storageUnitPrice: 0,
      bandwidthUnitPrice: 0,
    });
    setOrgLogo([]);
    form.resetFields();
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const updateOrganization = (_id, input) => {
    organizationUpdate({ variables: { _id: _id, input: input } })
      .then(mutationRes => {
        setIsLoading(false);
        notification.success({
          message: 'Organization Updated',
          duration: 0,
          description: 'Organization was updated successfully.',
        });
        resetForm();
        handleOk();
      })
      .catch(e => {
        setIsLoading(false);
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Organization Update Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };
  const createOrganization = input => {
    organizationCreate({ variables: { input: input } })
      .then(mutationRes => {
        setIsLoading(false);
        notification.success({
          message: 'Organization Created',
          duration: 0,
          description: 'Organization was created successfully.',
        });
        resetForm();
        handleOk();
      })
      .catch(e => {
        setIsLoading(false);
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Organization Create Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  const handleSubmit = async () => {
    form.validateFields(async (formErrors, values: any) => {
      // console.log('values', values);
      // console.log('org params', organizationParam);
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      let url = null;
      if (orgLogo.length > 0) {
        const { data } = await Api.uploadFile(orgLogo[0]);
        console.log('data', data);
        if (!data.success) {
          setIsLoading(false);
          const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Organization Create Failed',
            duration: 0,
            description: errorMessage,
          });
          return;
        }
        url = data.result.url;
      }
      const params = {
        ...organizationParam,
        name: values.name,
        provider: values.provider,
        email: values.email,
        country: values.country,
        status: organizationParam.status,
        city: values.city,
        logo: url || (currentItem && currentItem.logo) || null,
        phoneNumber: values.phoneNumber,
        description: values.description,
        subdomain: values.subdomain,
        disclaimer: values.disclaimer,
        loginPageTitle: values.loginPageTitle,
        isProduction: organizationParam.isProduction,
        storageUnitPrice: values.storageUnitPrice,
        bandwidthUnitPrice: values.bandwidthUnitPrice,
      };
      // console.log('input', params);
      if (isCreate) {
        createOrganization(params);
      } else {
        updateOrganization(currentItem._id, params);
      }
    });
  };

  const onStatusChange = val => {
    // console.log('status', val);
    setOrganizationParam(prev => ({ ...prev, status: val ? 'active' : 'inactive' }));
  };
  const onProductionChange = val => {
    // console.log('status', val);
    setOrganizationParam(prev => ({ ...prev, isProduction: val }));
  };

  const onCountrySelect = val => {
    if (val !== organizationParam.country) {
      setOrganizationParam(prev => ({
        ...prev,
        country: val,
        city: undefined,
      }));
      form.setFieldsValue({
        city: undefined,
      });
      setSelectCities(CountriesCities[val]);
    }
  };

  const beforeUpload = file => {
    // console.log('file', file);
    setOrgLogo(prev => [file]);
    getBase64(file, imageUrl => {
      setOrganizationParam(prev => ({ ...prev, logo: imageUrl }));
    });
    return false;
  };

  const onRemoveFile = file => {
    // console.log('here', file);
    setOrgLogo(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setOrganizationParam(prev => ({ ...prev, logo: (currentItem && currentItem.logo) || undefined }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <Modal
      title={isCreate ? 'Add New Organization' : 'Update Existing Organization'}
      footer={[
        <Button type="primary" onClick={handleSubmit} key="addUpdate" loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        {!isCreate && (
          <Form.Item label="Status">
            {getFieldDecorator('status', {
              valuePropName: 'checked',
              initialValue: organizationParam.status == 'active' ? true : false,
            })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={onStatusChange} />)}
          </Form.Item>
        )}
        <Form.Item label="Production">
          {getFieldDecorator('isProduction', {
            valuePropName: 'checked',
            initialValue: organizationParam.isProduction,
          })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={onProductionChange} />)}
        </Form.Item>
        <Form.Item
          label={
            <div>
              <label>Organization Logo</label>
              <div>{!!organizationParam.logo && <img src={organizationParam.logo} style={{ width: '100%' }} alt="logo org" />}</div>
            </div>
          }
        >
          {getFieldDecorator('logo', {
            initialValue: { fileList: orgLogo },
            // rules: [
            //   {
            //     required: true,
            //     message: 'Please input the name of the organization!',
            //   },
            // ],
          })(
            <Upload accept="image/*" fileList={orgLogo} beforeUpload={beforeUpload} onRemove={onRemoveFile}>
              <Button>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="Organization Name">
          {getFieldDecorator('name', {
            initialValue: organizationParam.name,
            rules: [
              {
                required: true,
                message: 'Please input the name of the organization!',
              },
            ],
          })(<Input placeholder="Enter Organization Name" />)}
        </Form.Item>
        <Form.Item label="Subdomain Name">
          {getFieldDecorator('subdomain', {
            initialValue: organizationParam.subdomain,
            rules: [
              {
                required: true,
                message: 'Please input the subdomain name of the organization!',
              },
            ],
          })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Subdomain"></Select>)}
        </Form.Item>
        <Form.Item label="Organiaztion API Provider">
          {getFieldDecorator('provider', {
            initialValue: organizationParam.provider,
            rules: [
              {
                required: true,
                message: 'Please input the system used by the organization!',
              },
            ],
          })(
            <Select loading={apiSystems ? false : true} placeholder="Select your API provider">
              {apiSystems &&
                apiSystems.map(item => {
                  const splittedName = item.split('_');
                  const name = splittedName.join(' ');
                  return (
                    <Option value={item} key={item}>
                      {name}
                    </Option>
                  );
                })}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: organizationParam.email,
            rules: [
              {
                required: true,
                message: 'Please input the email of the organization!',
              },
              { type: 'email', message: 'Email format invalid!' },
            ],
          })(<Input placeholder="Enter Organization Email" />)}
        </Form.Item>
        <Form.Item label="Phone Number">
          {getFieldDecorator('phoneNumber', {
            initialValue: organizationParam.phoneNumber,
          })(<Input placeholder="Enter Phone Number" />)}
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item label="Country">
              {getFieldDecorator('country', {
                initialValue: organizationParam.country,
                rules: [
                  {
                    required: true,
                    message: 'Please input the country!',
                  },
                ],
              })(
                <Select showSearch placeholder="Select Country" onChange={onCountrySelect}>
                  {Countries &&
                    Countries.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item label="City">
              {getFieldDecorator('city', {
                initialValue: organizationParam.city,
                rules: [
                  {
                    required: true,
                    message: 'Please input the city!',
                  },
                ],
              })(
                <Select showSearch placeholder="Select City">
                  {selectCities &&
                    selectCities.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: organizationParam.description,
          })(<TextArea placeholder="Enter something about your organization" />)}
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item label="Storage Unit Price">
              {getFieldDecorator('storageUnitPrice', {
                initialValue: organizationParam.storageUnitPrice,
                rules: [
                  { type: 'number', message: 'Should be number' },
                  { type: 'number', min: 0, message: 'Should not be negative' },
                ],
              })(
                <InputNumber
                  step={0.001}
                  style={{ width: '100%' }}
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item label="Bandwidth Unit Price">
              {getFieldDecorator('bandwidthUnitPrice', {
                initialValue: organizationParam.bandwidthUnitPrice,
                rules: [
                  { type: 'number', message: 'Should be number' },
                  { type: 'number', min: 0, message: 'Should not be negative' },
                ],
              })(
                <InputNumber
                  step={0.001}
                  style={{ width: '100%' }}
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Login Page Title">
          {getFieldDecorator('loginPageTitle', {
            initialValue: organizationParam.loginPageTitle,
          })(<Input placeholder="Enter Login Page Title" />)}
        </Form.Item>
        <Form.Item label="Disclaimer">
          {getFieldDecorator('disclaimer', {
            initialValue: organizationParam.disclaimer,
          })(<TextArea placeholder="Enter disclaimer to show on the login page" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditOrganizationModal = Form.create<OrganizationModalProps>()(AddEditOrganizationModalComponent);
export default AddEditOrganizationModal;
