import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Tooltip, notification } from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import LoadingSpinner from 'component/Loader';
import AddEditUserModal from 'component/AddEditUserModal/AddEditUserModal';
import { USERS_QUERY } from 'apollo/user/queries';
import EditUserAccessModal from 'component/EditUserAccessModal/EditUserAccessModal';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import { getUserData } from 'helper';
import UsersTable from 'component/UsersTable/UsersTable';
import { USER_DELETE_MUTATION } from 'apollo/user/mutations/userDelete';
import LikedVideosModal from 'component/LikedVideosModal/LikedVideosModal';
import WatchTimelineModal from 'component/WatchTimelineModal/WatchTimelineModal';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import UserMembershipModal from 'component/UserMembershipModal/UserMembershipModal';

const PAGE_LIMIT = 50;

interface UserContainerProps {}

const UsersContainer: React.FC<UserContainerProps> = ({}) => {
  const csvLinkRef: any = useRef();
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false as any);
  const [usersFilter, setUsersFilter] = useState<any>({});
  const [usersSorter, setUsersSorter] = useState<any>({
    field: 'createdAt',
    order: 'descend',
  });
  const [users, setUsers] = useState<null | Array<Object>>(null);
  const [showAddEditUserModal, setShowAddEditUserModal] = useState(false);
  const [showEditUserAccessModal, setShowEditUserAccessModal] = useState(false);
  const [showLikedVideosModal, setShowLikedVideosModal] = useState(false);
  const [showWatchTimelineModal, setShowWatchTimelineModal] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  const [isCreate, setIsCreate] = useState(true);
  const [csvData, setCsvData] = useState<any>([]);
  const [csvDl, setCsvDl] = useState(false);
  const [csvDownloading, setCsvDownloading] = useState(false);
  const [getUsersForCsv] = useLazyQuery(USERS_QUERY, {
    onCompleted: data => {
      if (data && data.users) {
        // console.log('users onCompletre', data);
        const { users: recieved, pagination } = data.users;
        let temp = recieved.map((item: any) => ({
          FirstName: item.firstName,
          LastName: item.lastName,
          Email: item.email,
          Role: item.role,
          Organization: item.organization?.name,
          Waiver: item.waiverFormSign ? 'Signed' : 'Not Signed',
          LastSessionAt: moment(item.lastSessionAt).format('DD MMM YYYY, h:mm a'),
          CreatedAt: moment(item.createdAt).format('DD MMM YYYY'),
        }));
        setCsvData(prev => [...prev, ...temp]);
        if (pagination.page < Math.ceil(pagination.total / pagination.limit)) {
          handleCsvData(pagination.page + 1);
        } else {
          setCsvDl(true);
        }
      }
    },
    onError: err => {
      setLoading(false);
      console.error('err are', err);
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (csvDl && csvData) {
      // console.log('useeff csv', csvData);
      setCsvDl(false);
      setTimeout(() => {
        csvLinkRef.current.link.click();
        setCsvDownloading(false);
      }, 1000);
      // setCsvData([]);
    }
  }, [csvData]);

  const [deleteUser] = useMutation(USER_DELETE_MUTATION);
  const { fetchMore, data } = useQuery(USERS_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      filterInput: usersFilter,
      sorterInput: usersSorter,
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.users) {
        // console.log('users onCompletre', data);
        setUsers(data.users.users);
        setPaginationData(data.users.pagination);
        setLoading(false);
        setTableLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.error('err are', err);
    },
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (data) {
      // console.log('users useeff', data);
      setUsers(data.users.users);
      // setPaginationData(data.publishedSharedVideos.pagination);
      // setLoading(false);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;

  const openAddEditUserModal = selectedUser => {
    if (selectedUser) setIsCreate(false);
    setCurrentItem(selectedUser);
    setShowAddEditUserModal(true);
  };
  const openEditUserAccessModal = selectedUser => {
    setCurrentItem(selectedUser);
    setShowEditUserAccessModal(true);
  };
  const openLikedVideosModal = selectedUser => {
    setCurrentItem(selectedUser);
    setShowLikedVideosModal(true);
  };
  const closeLikedVideosModal = () => {
    setIsCreate(true);
    setCurrentItem(null);
    setShowLikedVideosModal(false);
  };
  const openWatchTimelineModal = selectedUser => {
    setCurrentItem(selectedUser);
    setShowWatchTimelineModal(true);
  };
  const closeWatchTimelineModal = () => {
    setIsCreate(true);
    setShowWatchTimelineModal(false);
    setCurrentItem(null);
  };
  const openMembershipModal = selectedUser => {
    if (selectedUser) setIsCreate(false);
    setCurrentItem(selectedUser);
    setShowMembershipModal(true);
  };
  const closeMembershipModal = () => {
    setIsCreate(true);
    setShowMembershipModal(false);
    setCurrentItem(null);
  };
  const closeAddEditUserModal = () => {
    setIsCreate(true);
    setShowAddEditUserModal(false);
    setCurrentItem(null);
  };
  const closeEditUserAccessModal = () => {
    setIsCreate(true);
    setShowEditUserAccessModal(false);
    setCurrentItem(null);
  };
  const onDelete = record => {
    setTableLoading(true);
    deleteUser({
      variables: {
        _id: record._id,
      },
    })
      .then(result => {
        if (result.data.userDelete) {
          if (users) {
            let temp = users;
            let index = temp.findIndex((i: any) => i._id == record._id);
            temp.splice(index, 1);
            setUsers(temp);
          }
          notification.success({
            message: 'User Deleted',
            duration: 0,
            description: 'User was deleted successfully.',
          });
        }
        setTableLoading(false);
      })
      .catch(err => {
        setTableLoading(false);
        notification.error({
          message: 'User Delete Failed',
          duration: 0,
          description: 'User was not deleted.',
        });
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let userFilterInput = {};
    if (filters.firstName && filters.firstName.length) userFilterInput['firstName'] = filters.firstName[0];
    if (filters.lastName && filters.lastName.length) userFilterInput['lastName'] = filters.lastName[0];
    if (filters.email && filters.email.length) userFilterInput['email'] = filters.email[0];
    if (filters.role && filters.role.length) userFilterInput['role'] = filters.role;

    setUsersFilter(userFilterInput);
    let sorterInput = {};
    sorterInput['field'] = sorter.columnKey;
    sorterInput['order'] = sorter.order ? sorter.order : 'descend';
    setUsersSorter(sorterInput);
    fetch(paginationInput, userFilterInput, sorterInput);
  };

  const fetch = (paginationInput, userFilterInput: any = null, sorterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', userFilterInput);
    // console.log('sortterinput', sorterInput);
    setTableLoading(true);
    // fetchMore({
    //   variables: {
    //     paginationInput,
    //     ...(userFilterInput && { userFilterInput: userFilterInput }),
    //     ...(sorterInput && { sorterInput: sorterInput }),
    //     organizationId: selectedOrg == 'all' ? null : selectedOrg,
    //   },
    //   updateQuery: (prev, { fetchMoreResult }: any) => {
    //     // console.log('fetchmore results', fetchMoreResult);
    //     if (!fetchMoreResult) return prev;
    //     setUsers(fetchMoreResult.users.users);
    //     setPaginationData(fetchMoreResult.users.pagination);
    //     setTableLoading(false);
    //   },
    // });
  };

  const handleCsvData = page => {
    setCsvDownloading(true);
    getUsersForCsv({
      variables: {
        paginationInput: {
          limit: PAGE_LIMIT,
          page: page,
        },
        filterInput: usersFilter,
        sorterInput: usersSorter,
        organizationId: selectedOrg == 'all' ? null : selectedOrg,
      },
    });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tooltip placement="bottom" title="Add new user" key="Add new user">
              <Button
                type="primary"
                onClick={() => {
                  setIsCreate(true);
                  setShowAddEditUserModal(true);
                  setCurrentItem(null);
                }}
              >
                Add New User
              </Button>
            </Tooltip>
            <Button loading={csvDownloading} onClick={() => handleCsvData(1)}>
              Get CSV
            </Button>
            <CSVLink data={csvData} ref={csvLinkRef} filename={'users.csv'} target="_blank" style={{ display: 'none' }} />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <UsersTable
            users={users}
            userSorter={usersSorter}
            paginationData={paginationData}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            handleTableChange={handleTableChange}
            openAddEditUserModal={openAddEditUserModal}
            openMembershipModal={openMembershipModal}
            openEditUserAccessModal={openEditUserAccessModal}
            onDelete={onDelete}
            onLikedVideos={openLikedVideosModal}
            onWatchHistory={openWatchTimelineModal}
          />
        </Col>
      </Row>
      <AddEditUserModal
        isCreate={isCreate}
        modalVisible={showAddEditUserModal}
        currentItem={currentItem}
        handleCancel={closeAddEditUserModal}
        handleOk={closeAddEditUserModal}
      />
      <EditUserAccessModal
        modalVisible={showEditUserAccessModal}
        currentItem={currentItem}
        handleCancel={closeEditUserAccessModal}
        handleOk={closeEditUserAccessModal}
      />
      <LikedVideosModal visible={showLikedVideosModal} currentItem={currentItem} handleCancel={closeLikedVideosModal} />
      <UserMembershipModal visible={showMembershipModal} currentItem={currentItem} handleCancel={closeMembershipModal} />
      <WatchTimelineModal visible={showWatchTimelineModal} currentItem={currentItem} handleCancel={closeWatchTimelineModal} />
    </>
  );
};
export default UsersContainer;
