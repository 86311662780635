import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../userfragment';

export const USER_CREATE_MUTATION = gql`
  mutation userCreate($input: UserCreateInput!, $organizationId: String) {
    userCreate(input: $input, organizationId: $organizationId) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;
