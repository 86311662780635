import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button, Popconfirm, message, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { DELETE_ACTIVITY_MUTATION } from 'apollo/activity/mutations';
import LoadingSpinner from 'component/Loader';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { DELETE_TRAINER_MUTATION } from 'apollo/trainer/mutations';
import { getUserData } from 'helper';

const TrainerTable = ({ onEdit, organization }) => {
  const loggedInUser = JSON.parse(getUserData());
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteTrainer] = useMutation(DELETE_TRAINER_MUTATION, { refetchQueries: ['trainers'] });
  const onDeleteTrainer = id => {
    message.loading({ content: 'Deleting Trainer.', key: 'deleteMessage', duration: 0 });
    deleteTrainer({ variables: { _id: id } })
      .then(data => {
        message.destroy();
        notification.success({
          message: 'Trainer Deleted',
          duration: 0,
          description: 'Trainer was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        notification.error({
          message: 'Trainer Delete Failed',
          duration: 0,
          description: 'Trainer was not deleted.',
        });
      });
  };

  const { loading, error, data } = useQuery(TRAINERS_QUERY, {
    variables: { organizationId: organization },
  });
  if (loading) return <LoadingSpinner />;

  const columns = [
    {
      key: 'name',
      title: 'Trainer',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.name}
        </Button>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    ...(loggedInUser.role === 'superadmin'
      ? [
          {
            key: 'organizationName',
            title: 'Org Name',
            render: record => <span>{record.organization && record.organization.name}</span>,
          },
        ]
      : [{}]),
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <span>
            <Button type="primary" onClick={() => onEdit(record)}>
              Edit
            </Button>
          </span>
          <span style={{ marginLeft: '1em' }}>
            <Popconfirm title="Are you sure delete this trainer?" onConfirm={() => onDeleteTrainer(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];

  return <AntdTable loading={tableLoading} pagination={false} rowKey={record => record._id} columns={columns} dataSource={data.trainers} />;
};

export default TrainerTable;
