import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import AddTrainerModal from 'component/AddTrainerModal/AddTrainerModal';
import TrainerTable from 'component/TrainerTable/TrainerTable';
import { getUserData } from 'helper';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';

const TrainerContainer = () => {
  const [showAddTrainerModal, setShowAddTrainerModal] = useState(false);
  const [isCreateTrainer, setIsCreateTrainer] = useState(true);
  const [currentTrainer, setCurrentTrainer] = useState(false);
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [selectedOrg, setSelectedOrg] = useState('all');

  const openAddTrainerModal = () => {
    setShowAddTrainerModal(true);
  };

  const handleTrainerModalCancel = () => {
    setIsCreateTrainer(true);
    setCurrentTrainer(false);
    setShowAddTrainerModal(false);
  };

  const onEditTrainer = selectedTrainer => {
    setIsCreateTrainer(false);
    setCurrentTrainer(selectedTrainer);
    openAddTrainerModal();
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          <Button type="primary" onClick={openAddTrainerModal}>
            Add New Trainer
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={22} offset={1}>
          <TrainerTable organization={selectedOrg == 'all' ? null : selectedOrg} onEdit={onEditTrainer} />
        </Col>
      </Row>
      <AddTrainerModal
        modalVisible={showAddTrainerModal}
        isCreate={isCreateTrainer}
        currentItem={currentTrainer}
        handleOk={handleTrainerModalCancel}
        handleCancel={handleTrainerModalCancel}
      />
    </>
  );
};
export default TrainerContainer;
