import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import moment from 'moment';
import AddEditFreeAccessDateModal from 'component/AddEditFreeAccessDateModal/AddEditFreeAccessDateModal';

const FreeAccessDatesTable = ({ freeAccessDates, onAdd, onDelete, onEdit }) => {
  const [showAddEditFreeAccessDateModal, setShowAddEditFreeAccessDateModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedFreeAccessDate, setSelectedFreeAccessDate] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'start',
      title: 'Start Date',
      render: record => <p style={{ margin: 0 }}>{moment(record.start).format('DD MMM YYYY')}</p>,
    },
    {
      key: 'end',
      title: 'End Date',
      render: record => <p style={{ margin: 0 }}>{moment(record.end).format('DD MMM YYYY')}</p>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedFreeAccessDate(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditFreeAccessDateModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditFreeAccessDateModal = () => {
    setSelectedFreeAccessDate(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditFreeAccessDateModal(false);
  };

  const onAddFreeAccessDate = freeAccessDate => {
    onAdd(freeAccessDate);
    closeAddEditFreeAccessDateModal();
  };
  const onEditFreeAccessDate = freeAccessDate => {
    onEdit(freeAccessDate, selectedIndex);
    closeAddEditFreeAccessDateModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditFreeAccessDateModal(true)}>
        Add Free Access Date
      </Button>
      <AntdTable pagination={false} rowKey={record => `${record.start}${record.end}`} columns={columns} dataSource={freeAccessDates} size="small" />
      <AddEditFreeAccessDateModal
        visible={showAddEditFreeAccessDateModal}
        currentItem={selectedFreeAccessDate}
        isCreate={isCreate}
        onAdd={onAddFreeAccessDate}
        onEdit={onEditFreeAccessDate}
        onCancel={closeAddEditFreeAccessDateModal}
      />
    </div>
  );
};

export default FreeAccessDatesTable;
