import { gql } from 'apollo-boost';
import { MEMBERSHIP_FRAGMENT } from '../membershipFragment';

export const CREATE_MEMBERSHIP_MUTATION = gql`
  mutation createMembership($input: MembershipInput!, $organizationId: String) {
    createMembership(input: $input, organizationId: $organizationId) {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`;
