import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const CHANGE_VIDEO_STATUS_MUTATION = gql`
  mutation changeVideoStatus($_id: ID, $status: String!, $parentVideoId: String) {
    changeVideoStatus(_id: $_id, status: $status, parentVideoId: $parentVideoId) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
