import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const ALL_TRAINERS_QUERY = gql`
  query allTrainers {
    allTrainers {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
