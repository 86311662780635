import axios from 'axios';
import config from './../../config';
import { getToken } from 'helper';

const STREAM_BASE_URL = 'https://antvideo.tpasc.ca:5443/LiveApp/rest/v2/';

const URLS = {
  CREATE_STREAM: `${STREAM_BASE_URL}broadcasts/create`,
  UPLOAD_VIDEO: `${STREAM_BASE_URL}vods/create`,
  UPLOAD_FILE: `${config.BASE_URL}/s/api/v1/file/upload`,
  PRINT_BILLING: `${config.BASE_URL}/s/api/v1/billing/print`,
  // PRINT_BILLING: `http://localhost:5000/s/api/v1/billing/print`,
};

const createStream = () => {
  return axios.post(URLS.CREATE_STREAM, {
    name: 'rest test',
  });
};

const uploadVideo = async (url, file, onUploadProgress) => {
  let config = {
    method: 'put',
    // url: `${URLS.UPLOAD_VIDEO}?name=${title}.mp4`,
    url: url,
    // url: `https://4776f10116e6.ngrok.io/s/api/v1/videos/upload?name=${title}.mp4`,
    // url: `http://localhost:5000/s/api/v1/videos/upload?name=${title}.mp4`,
    onUploadProgress: onUploadProgress,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  // console.log('config', config);
  return axios(config);
};

const uploadFile = async payload => {
  const formData = new FormData();
  formData.append('file', payload);
  const token = await getToken();
  return axios.post(URLS.UPLOAD_FILE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

const printBilling = async html => {
  const token = await getToken();
  let config = {
    method: 'post',
    url: URLS.PRINT_BILLING,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
    data: {
      billingInfo: html,
    },
  };
  // console.log('config', config);
  return axios(config);
};

export default { createStream, uploadVideo, uploadFile, printBilling };
