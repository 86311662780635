import { createGlobalStyle } from 'styled-components';

const GlobalStyles: any = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #111;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }
  & .content-scrollbar .scrollbar-track{
    width: 3px;
  }
  & .content-scrollbar .scrollbar-thumb{
    width: 3px;
  }
`;

export default GlobalStyles;
