import React, { useState } from 'react';
import { Row, Col, Calendar, Badge } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_QUERY, VIDEOS_PAGE_FILTER_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import moment from 'moment';
import VideoTableModal from 'component/VideoTableModal/VideoTableModal';

interface CalendarCompProps {}

const CalendarContainer: React.FC<CalendarCompProps> = ({}) => {
  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [loading, setLoading] = useState(true);
  const [videosByDate, setVideosByDate] = useState<any>(null);
  const [showVideoTableModal, setShowVideoTableModal] = useState(false);
  const [defaultDate, setDefaultDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [videoList, setVideoList] = useState<any>(null);
  const { fetchMore, data } = useQuery(VIDEOS_PAGE_FILTER_QUERY, {
    variables: {
      videosPageFilterInput: {
        premiereAtRange: {
          start: moment().startOf('month').startOf('day').toISOString(),
          end: moment().endOf('month').endOf('day').toISOString(),
        },
        createdAtRange: {
          start: moment().startOf('month').startOf('day').toISOString(),
          end: moment().endOf('month').endOf('day').toISOString(),
        },
      },
    },
    onCompleted: data => {
      if (data && data.videosPageFilter) {
        let temp = {};
        data &&
          data.videosPageFilter &&
          data.videosPageFilter.videos &&
          data.videosPageFilter.videos.length &&
          data.videosPageFilter.videos.map(item => {
            if (item.premiereAt && item.premiere) {
              if (!temp.hasOwnProperty(`${moment(item.premiereAt).format('DD MM YYYY')}`)) {
                temp[`${moment(item.premiereAt).format('DD MM YYYY')}`] = [];
              }
              temp[`${moment(item.premiereAt).format('DD MM YYYY')}`].push(item);
            } else {
              if (!temp.hasOwnProperty(`${moment(item.createdAt).format('DD MM YYYY')}`)) {
                temp[`${moment(item.createdAt).format('DD MM YYYY')}`] = [];
              }
              temp[`${moment(item.createdAt).format('DD MM YYYY')}`].push(item);
            }
          });
        // console.log('tempo', temp);
        setVideosByDate(temp);
        setVideos(data.videosPageFilter.videos);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
  });
  // const { loading, error, data } = useQuery(VIDEOS_QUERY, {
  //   onCompleted: data => {
  //     let temp = {};
  //     data &&
  //       data.videos &&
  //       data.videos.length &&
  //       data.videos.map(item => {
  //         if (item.premiereAt && item.premiere) {
  //           if (!temp.hasOwnProperty(`${moment(item.premiereAt).format('DD MM YYYY')}`)) {
  //             temp[`${moment(item.premiereAt).format('DD MM YYYY')}`] = [];
  //           }
  //           temp[`${moment(item.premiereAt).format('DD MM YYYY')}`].push(item);
  //         } else {
  //           if (!temp.hasOwnProperty(`${moment(item.createdAt).format('DD MM YYYY')}`)) {
  //             temp[`${moment(item.createdAt).format('DD MM YYYY')}`] = [];
  //           }
  //           temp[`${moment(item.createdAt).format('DD MM YYYY')}`].push(item);
  //         }
  //       });
  //     // console.log('tempo', temp);
  //     setVideosByDate(temp);
  //     setVideos((data && data.videos) || []);
  //     // console.log(data);
  //   },
  // });
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  // const openEditVideoModal = (selectedVideo) => {
  //   setCurrentItem(selectedVideo);
  //   setShowEditVideoModal(true);
  // };

  const getListData = value => {
    const keys = Object.keys(videosByDate);
    let temp: any = [];
    for (const key of keys) {
      if (value.format('DD MM YYYY') == key) {
        temp = videosByDate[key];
      }
    }
    // console.log('listdata', temp);
    return temp;
  };

  const dateCellRender = value => {
    if (videosByDate) {
      let count = 0;
      const listData = getListData(value);
      return (
        <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
          {listData.map(item => {
            count++;
            if (count > 3) return null;
            if (count == 3) {
              return (
                <li key={'more'}>
                  <Badge color="#000" text="more..." />
                </li>
              );
            }
            if (count < 3) {
              return (
                <li key={item._id}>
                  <Badge color={item.parentVideoId ? '#f50' : '#108ee9'} text={item.title} />
                </li>
              );
            }
          })}
        </ul>
      );
    } else {
      return <span></span>;
    }
  };
  const onDateSelect = value => {
    // console.log('select date', value);
    if (videosByDate.hasOwnProperty(value.format('DD MM YYYY'))) {
      setVideoList(videosByDate[value.format('DD MM YYYY')]);
    } else {
      setVideoList([]);
    }
    setSelectedDate(value);
    setShowVideoTableModal(true);
  };

  const closeVideoTableModal = () => {
    setShowVideoTableModal(false);
    setVideoList(null);
  };

  const onChange = changedDate => {
    // console.log('changed', changedDate);
    setDefaultDate(changedDate);
    if (defaultDate.format('MM-YYYY') !== changedDate.format('MM-YYYY')) {
      setLoading(true);
      fetchMore({
        variables: {
          videosPageFilterInput: {
            premiereAtRange: {
              start: moment(changedDate).startOf('month').startOf('day').toISOString(),
              end: moment(changedDate).endOf('month').endOf('day').toISOString(),
            },
            createdAtRange: {
              start: moment(changedDate).startOf('month').startOf('day').toISOString(),
              end: moment(changedDate).endOf('month').endOf('day').toISOString(),
            },
          },
        },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          let temp = {};
          fetchMoreResult &&
            fetchMoreResult.videosPageFilter &&
            fetchMoreResult.videosPageFilter.videos &&
            fetchMoreResult.videosPageFilter.videos.length &&
            fetchMoreResult.videosPageFilter.videos.map(item => {
              if (item.premiereAt && item.premiere) {
                if (!temp.hasOwnProperty(`${moment(item.premiereAt).format('DD MM YYYY')}`)) {
                  temp[`${moment(item.premiereAt).format('DD MM YYYY')}`] = [];
                }
                temp[`${moment(item.premiereAt).format('DD MM YYYY')}`].push(item);
              } else {
                if (!temp.hasOwnProperty(`${moment(item.createdAt).format('DD MM YYYY')}`)) {
                  temp[`${moment(item.createdAt).format('DD MM YYYY')}`] = [];
                }
                temp[`${moment(item.createdAt).format('DD MM YYYY')}`].push(item);
              }
            });
          // console.log('tempo', temp);
          setVideosByDate(temp);
          setVideos(fetchMoreResult.videosPageFilter.videos);
          setLoading(false);
        },
      });
    }
  };

  return (
    <>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          {/* <AntdTable loading={tableLoading} pagination={false} rowKey={(record) => record._id} columns={columns} dataSource={videos} /> */}
          <div style={{ display: 'flex' }}>
            <Badge color="#108ee9" text="My Video" style={{ marginRight: '15px' }} />
            <Badge color="#f50" text="Shared Video" />
          </div>
          <div style={{ backgroundColor: '#fff' }}>
            <Calendar defaultValue={defaultDate} dateCellRender={dateCellRender} onSelect={onDateSelect} onChange={onChange} />
          </div>
        </Col>
      </Row>
      <VideoTableModal visible={showVideoTableModal} closeModal={closeVideoTableModal} videoList={videoList} selectedDate={selectedDate} />
      {/* <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} /> */}
    </>
  );
};
export default CalendarContainer;
