import { gql } from 'apollo-boost';
import { MEMBERSHIP_FRAGMENT } from '../membershipFragment';

export const MEMBERSHIPS_QUERY = gql`
  query memberships($organizationId: String) {
    memberships(organizationId: $organizationId) {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`;
