import React, { useState, useEffect, useRef } from 'react';
import { Button, DatePicker, notification } from 'antd';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { useLazyQuery } from '@apollo/react-hooks';
import { ANALYTICS_NEW_SUBS_QUERY } from 'apollo/analyticsReport/queries/analyticsNewSubs';
import AntdTable from 'component/elements';
import { CSVLink } from 'react-csv';

const SubscriberReportTable = ({ organization }) => {
  const csvLinkRef: any = useRef();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract('months', 1));
  const [endDate, setEndDate] = useState(moment());
  const [type, setType] = useState('daily');
  const [csvData, setCsvData] = useState<any>([]);
  const [csvDl, setCsvDl] = useState(false);

  useEffect(() => {
    if (csvDl && csvData) {
      // console.log('useeff csv', csvData);
      setCsvDl(false);
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 1000);
      // setCsvData([]);
    }
  }, [csvData]);

  const handleCsvData = () => {
    if (data) {
      let temp = (data as any).map((item: any) => ({
        Date: moment(item.date.split(',')[0]).format('DD MMM YYYY, ddd'),
        TotalSubscribers: item.initial,
        NewSubscribers: item.newUsers,
        PercentIncrease: item.percentInc,
        ActiveSubscribers: item.activeUsers,
      }));
      setCsvData(temp);
      setCsvDl(true);
    }
  };

  const [getReport] = useLazyQuery(ANALYTICS_NEW_SUBS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      setData(data.analyticsNewSub || []);
    },
    onError: err => {
      console.log('err', err);
    },
  });
  useEffect(() => {
    setLoading(true);
    getReport({
      variables: {
        startDate: startDate.startOf('day').toISOString(),
        endDate: endDate.endOf('day').toISOString(),
        organizationId: organization,
        type: type,
      },
    });
    return () => {};
  }, [organization]);
  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Loader type="TailSpin" color="#202541" />
      </div>
    );
  }
  if (!data) {
    return (
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Loader type="TailSpin" color="#202541" />
      </div>
    );
  }

  const onStartChange = value => {
    setStartDate(value);
  };
  const onEndChange = value => {
    setEndDate(value);
  };

  const handleApply = () => {
    setFetching(true);
    if (!startDate || !endDate) {
      setFetching(false);
      notification.error({
        message: 'Date Error',
        description: 'Start Date or End Date not selected',
      });
      return;
    }
    if (startDate > endDate) {
      setFetching(false);
      notification.error({
        message: 'Start Date Error',
        description: 'Start Date cannot be greater than end date.',
      });
      return;
    }
    getReport({
      variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), organizationId: organization, type: type },
    });
  };

  const columns = [
    {
      key: 'date',
      title: 'Date',
      render: record => moment(record.date.split(',')[0]).format('DD MMM YYYY, ddd'),
    },
    {
      key: 'initial',
      title: 'Total Subscribers',
      render: record => record.initial,
    },
    {
      key: 'newUsers',
      title: 'New Subscribers',
      render: record => record.newUsers,
    },
    {
      key: 'percentInc',
      title: 'Percent Increase',
      render: record => record.percentInc,
    },
    {
      key: 'activeUsers',
      title: 'Active Subscribers',
      render: record => record.activeUsers,
    },
  ];

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>New Subscribers Daily</h2>
      <div style={{ display: 'flex', margin: '15px 0', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Button disabled={fetching} onClick={handleCsvData}>
            Get CSV
          </Button>
          <CSVLink data={csvData} ref={csvLinkRef} filename={'subs-analytics.csv'} target="_blank" style={{ display: 'none' }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ margin: '0 20px' }}>
            <DatePicker value={startDate} onChange={onStartChange} />
            <DatePicker value={endDate} onChange={onEndChange} />
          </div>
          <Button loading={fetching} onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
      <AntdTable
        // size="small"
        loading={fetching}
        scroll={{ x: true }}
        rowKey={record => record.date}
        columns={columns}
        dataSource={data}
      />
    </>
  );
};

export default SubscriberReportTable;
