import React from 'react';
import { Modal } from 'antd';
import AntdTable from 'component/elements';

const UserMembershipModal = ({ visible, currentItem, handleCancel }) => {
  const close = () => {
    handleCancel();
  };

  const columns = [
    {
      key: 'membershipId',
      title: 'Membership Id',
      render: record => {
        return <span>{record.membershipId}</span>;
      },
      // sorter: (a, b) => a.activity.name.length - b.activity.name.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'name',
      title: 'Membership Name',
      render: record => {
        return <span>{record.name}</span>;
      },
      // sorter: (a, b) => a.trainer.name.length - b.trainer.name.length,
      // sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <Modal title="User Membership" visible={visible} onCancel={close} footer={null} width={1000}>
      {currentItem && (
        <div>
          {currentItem &&
            currentItem.externalUser &&
            currentItem.externalUser.customData &&
            currentItem.externalUser.customData.packages &&
            currentItem.externalUser.customData.packages.length > 0 && (
              <AntdTable
                pagination={true}
                rowKey={record => `${record.membershipId}-${record.name}`}
                columns={columns}
                dataSource={currentItem.externalUser.customData.packages || []}
              />
            )}
          {currentItem &&
            currentItem.externalUser &&
            currentItem.externalUser.customData &&
            (!currentItem.externalUser.customData.packages || currentItem.externalUser.customData.packages.length == 0) && <div>No membership</div>}
        </div>
      )}
    </Modal>
  );
};

export default UserMembershipModal;
