import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditRuleModal from 'component/AddEditRuleModal/AddEditRuleModal';

const RulesTable = ({ rules, organization, onAdd, onDelete, onEdit }) => {
  const [showAddEditRuleModal, setShowAddEditRuleModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedRule, setSelectedRule] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'by',
      title: 'By',
      render: record => <p style={{ margin: 0, padding: 0 }}>{record.by}</p>,
    },
    {
      key: 'shareById',
      title: 'Share By Id',
      render: record => (
        <p style={{ margin: 0, padding: 0 }}>{record.by == 'trainer' ? record.shareByTrainer?.name : record.shareByActivity?.name}</p>
      ),
    },
    {
      key: 'action',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedRule(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditRuleModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditRuleModal = () => {
    setSelectedRule(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditRuleModal(false);
  };

  const onAddRule = rule => {
    const { by, shareByTrainer, shareByActivity } = rule;
    const temp = {
      by: by,
      ...(shareByTrainer && { shareByTrainer: { _id: shareByTrainer.key, name: shareByTrainer.label } }),
      ...(shareByActivity && { shareByActivity: { _id: shareByActivity.key, name: shareByActivity.label } }),
    };
    onAdd(temp);
    closeAddEditRuleModal();
  };
  const onEditRule = rule => {
    const { from, by, shareByTrainer, shareByActivity } = rule;
    const temp = {
      by: by,
      ...(shareByTrainer && { shareByTrainer: { _id: shareByTrainer.key, name: shareByTrainer.label } }),
      ...(shareByActivity && { shareByActivity: { _id: shareByActivity.key, name: shareByActivity.label } }),
    };
    onEdit(temp, selectedIndex);
    closeAddEditRuleModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditRuleModal(true)}>
        Add Rule
      </Button>
      <AntdTable
        pagination={false}
        rowKey={record => `${record.by}${record.shareByTrainer}${record.shareByActivity}`}
        columns={columns}
        dataSource={rules}
        size="small"
      />
      <AddEditRuleModal
        visible={showAddEditRuleModal}
        currentItem={selectedRule}
        isCreate={isCreate}
        onAdd={onAddRule}
        onEdit={onEditRule}
        onCancel={closeAddEditRuleModal}
      />
    </div>
  );
};

export default RulesTable;
