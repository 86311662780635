import { gql } from 'apollo-boost';
import { MEMBERSHIP_FRAGMENT } from '../membershipFragment';

export const ALL_MEMBERSHIPS_QUERY = gql`
  query allMemberships {
    allMemberships {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`;
