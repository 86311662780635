import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from './../videoFragmet';

export const CREATE_VIDEO_MUTATION = gql`
  mutation createVideo($input: VideoCreateInput!, $organizationId: String) {
    createVideo(input: $input, organizationId: $organizationId) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
