import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const CREATE_TRAINER_MUTATION = gql`
  mutation createTrainer($input: TrainerInput!, $organizationId: String) {
    createTrainer(input: $input, organizationId: $organizationId) {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
