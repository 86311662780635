import React, { useState } from 'react';
import { Row, Col, Card, Button, Form, Input, Select, notification } from 'antd';
import LabelwTooltip from 'component/LabelwTooltip/LabelwTooltip';
import { FormComponentProps } from 'antd/lib/form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import Editor from 'component/Editor/Editor';
import { SEND_ANNOUNCEMENT_MUTATION } from 'apollo/organization/mutations';

interface Props extends FormComponentProps {}

const OrgAnnouncementsComponent = ({ form }: Props) => {
  const [sending, setSending] = useState(false);
  const [organizationsEmail, setOrganizationsEmail] = useState([]);
  const [announcementParams, setAnnouncementParams] = useState<any>({
    to: [],
    subject: '',
    message: '',
  });
  const { getFieldDecorator } = form;

  const [sendAnnouncementMutation] = useMutation(SEND_ANNOUNCEMENT_MUTATION);
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      let temp = data.organizations.filter(item => item.settings?.commentEmail).map(item => item.settings?.commentEmail);
      setOrganizationsEmail(temp);
    },
  });

  const sendEmail = () => {
    form.validateFields(async (formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setSending(true);
      sendAnnouncementMutation({
        variables: {
          input: {
            to: values.to,
            subject: values.subject,
            message: announcementParams.message,
          },
        },
      })
        .then(mutationRes => {
          if (mutationRes.data.sendAnnouncement) {
            notification.success({
              message: 'Mail Send Successfully',
            });
            form.resetFields();
            setAnnouncementParams({
              to: [],
              subject: '',
              message: '',
            });
          }
        })
        .catch(err => {
          console.log('err sending announcements', err);
          notification.error({
            message: 'Mail Send Failed',
          });
        })
        .finally(() => setSending(false));
    });
  };
  return (
    <Row style={{ margin: '15px 0' }}>
      <Col offset={1} span={15} style={{ background: '#fff' }}>
        <Card title={<LabelwTooltip label="Announcements" tooltipText="Send email announcements to organizations." />}>
          <Form layout="vertical">
            <Form.Item label="To">
              {getFieldDecorator('to', {
                initialValue: announcementParams.to,
                rules: [{ required: true, message: 'Please Select Email' }],
              })(
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Please select Email"
                  onChange={val => setAnnouncementParams(prev => ({ ...prev, to: val }))}
                >
                  {organizationsEmail
                    // .filter(item => !announcementParams.to.includes(item))
                    .map(item => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Subject">
              {getFieldDecorator('subject', {
                initialValue: announcementParams.subject,
                rules: [{ required: true, message: 'Enter subject' }],
              })(<Input placeholder="Enter Subject" onChange={val => setAnnouncementParams(prev => ({ ...prev, subject: val }))} />)}
            </Form.Item>
            <Form.Item label="Message">
              <Editor message={announcementParams.message} onChange={data => setAnnouncementParams(prev => ({ ...prev, message: data }))} />
            </Form.Item>
          </Form>
          <Button loading={sending} style={{ marginTop: 20 }} type="primary" onClick={sendEmail}>
            Send
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

const OrgAnnouncements = Form.create<Props>()(OrgAnnouncementsComponent);

export default OrgAnnouncements;
