import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

interface AddEditWantAccessModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditWantAccessModalComponent: React.FC<AddEditWantAccessModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [wantAccessParam, setWantAccessParam] = useState<any>({
    title: undefined,
    url: undefined,
    newTab: false,
  });

  useEffect(() => {
    if (currentItem) {
      setWantAccessParam({
        ...wantAccessParam,
        title: currentItem.title,
        url: currentItem.url,
        newTab: currentItem.newTab ? true : false,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setWantAccessParam({
      title: undefined,
      url: undefined,
      newTab: false,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const wantAccess = {
        title: values.title,
        url: values.url,
        newTab: wantAccessParam.newTab,
      };
      if (isCreate) {
        onAdd(wantAccess);
      } else {
        onEdit(wantAccess);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Want Access Button' : 'Edit Want Access Button'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: wantAccessParam.title,
            rules: [
              {
                required: true,
                message: 'Please input the title!',
              },
            ],
          })(<Input placeholder="Enter Title" />)}
        </Form.Item>
        <Form.Item label="Url">
          {getFieldDecorator('url', {
            initialValue: wantAccessParam.url,
            rules: [
              {
                required: true,
                message: 'Please input an URL!',
              },
            ],
          })(<Input placeholder="Enter URL" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('newTab', {
            valuePropName: 'checked',
            initialValue: wantAccessParam.newTab,
            rules: [],
          })(<Checkbox onChange={e => setWantAccessParam(prev => ({ ...prev, newTab: e.target.checked }))}>Open link in new tab</Checkbox>)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditWantAccessModal = Form.create<AddEditWantAccessModalProps>()(AddEditWantAccessModalComponent);
export default AddEditWantAccessModal;
