import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('dashboard_token');

export const setToken = token => Cookies.set('dashboard_token', token, { expires: 1 });

export const removeToken = () => Cookies.remove('dashboard_token');

export const setUserData = data => localStorage.setItem('vp_dashboard_user', data);

export const getUserData: any = () => localStorage.getItem('vp_dashboard_user');

export const removeUserData = () => localStorage.removeItem('vp_dashboard_user');
