import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button, Popconfirm, message, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import LoadingSpinner from 'component/Loader';
import { getUserData } from 'helper';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';
import { DELETE_MEMBERSHIP_MUTATION } from 'apollo/membership/mutations';

const MembershipTable = ({ onEdit, organization, memberships }) => {
  const loggedInUser = JSON.parse(getUserData());
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteMembership] = useMutation(DELETE_MEMBERSHIP_MUTATION, { refetchQueries: ['memberships'] });
  const onDeleteMembership = id => {
    message.loading({ content: 'Deleting Membership.', key: 'deleteMessage', duration: 0 });
    deleteMembership({ variables: { _id: id } })
      .then(data => {
        message.destroy();
        notification.success({
          message: 'Membership Deleted',
          duration: 0,
          description: 'Membership was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        notification.error({
          message: 'Membership Delete Failed',
          duration: 0,
          description: 'Membership was not deleted.',
        });
      });
  };

  if (!memberships) return <LoadingSpinner />;

  const columns = [
    {
      key: 'membershipName',
      title: 'Membership',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.membershipName}
        </Button>
      ),
    },
    {
      key: 'membershipExternalId',
      title: 'Membership ID',
      render: record => record.membershipExternalId,
    },
    ...(loggedInUser.role === 'superadmin'
      ? [
          {
            key: 'organizationName',
            title: 'Org Name',
            render: record => <span>{record.organization && record.organization.name}</span>,
          },
        ]
      : [{}]),
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <span>
            <Button type="primary" onClick={() => onEdit(record)}>
              Edit
            </Button>
          </span>
          <span style={{ marginLeft: '1em' }}>
            <Popconfirm title="Are you sure delete this membership?" onConfirm={() => onDeleteMembership(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];

  return <AntdTable loading={tableLoading} pagination={false} rowKey={record => record._id} columns={columns} dataSource={memberships} />;
};

export default MembershipTable;
