import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import Loader from "react-loader-spinner";
import moment from "moment";
import { VIDEO_WATCHED_USERS_QUERY } from "apollo/video/queries";
import AntdTable from "component/elements";
import { ColumnProps } from "antd/lib/table";

const VideoWatchedUsersModal = ({ visible, videoId, handleCancel }) => {
  const [videoWatched, setVideoWatched] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [getVideosWatchedQuery] = useLazyQuery(VIDEO_WATCHED_USERS_QUERY, {
    onCompleted: (data) => {
      if (data && data.videoWatchedUsers) {
        setVideoWatched(data.videoWatchedUsers);
      } else {
        setVideoWatched([]);
      }
    },
    onError: (err) => {
      // console.error('err in wactched', err);
      setVideoWatched([]);
      if (err.message.includes("Video Not Found"))
        setError("Such video does not exists.");
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (videoId) {
      getVideosWatchedQuery({
        variables: {
          videoId: videoId,
        },
      });
    } else {
      setVideoWatched(null);
      setError(null);
    }
  }, [videoId]);

  const close = () => {
    setVideoWatched(null);
    handleCancel();
  };

  const columns: ColumnProps<any>[] = [
    {
      key: "date",
      title: "Date",
      render: (record) => (
        <span style={{ fontWeight: "bold", color: "rgba(0,0,0,1)" }}>
          {moment(record.createdAt).format("DD MMM YYYY, h:mm a")}
        </span>
      ),
    },
    {
      key: "name",
      title: "Name",
      render: (record) => (
        <span>{`${record?.user?.firstName} ${record?.user?.lastName}`}</span>
      ),
    },
    {
      key: "email",
      title: "Email",
      render: (record) => <span>{`${record?.user?.email}`}</span>,
    },
  ];

  return (
    <Modal
      title="Watch History"
      visible={visible}
      onCancel={close}
      footer={null}
    >
      {videoWatched ? (
        <>
          {error ? (
            <div>{error}</div>
          ) : (
            <div>
              {videoWatched.length > 0 ? (
                <AntdTable
                  columns={columns}
                  dataSource={videoWatched}
                  rowKey={(item: any) => item._id}
                />
              ) : (
                <>No users have watched yet.</>
              )}
            </div>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </Modal>
  );
};

export default VideoWatchedUsersModal;
