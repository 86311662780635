import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { StyledSidebar, StyledSidebarHeader } from './sidebarStyles';
import { Menu, Icon, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { ME_QUERY } from 'apollo/user/queries';
import { removeToken, removeUserData } from '../../../helper';

const { SubMenu } = Menu;
interface Props {
  collapse: boolean;
  organization: any;
  // menu: Array<string | any>;
}

const Sidebar: React.FC<Props> = ({
  collapse,
  organization = null,
  // menu
}) => {
  const [menu, setMenu] = useState(false as any);
  const { loading, error, data } = useQuery(ME_QUERY);

  useEffect(() => {
    if (data) {
      // console.log('data', data);
      if (data.me.role == 'god') {
        setMenu([
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Videos',
            children: [
              {
                path: '/',
                icon: 'cloud-upload',
                component: 'VideosContainer',
                title: 'Videos',
              },
              {
                path: '/sharedvideos',
                icon: 'cloud-upload',
                component: 'SharedVideosContainer',
                title: 'Shared Videos',
              },
              {
                path: '/calendar',
                icon: 'calendar',
                component: 'CalendarContainer',
                title: 'Calendar',
              },
            ],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Users',
            children: [
              {
                path: '/users',
                icon: 'user',
                component: 'UsersContainer',
                title: 'Users And Roles',
              },
              {
                path: '/comments',
                icon: 'message',
                component: 'CommentsContainer',
                title: 'Comments',
              },
              {
                path: '/analytics',
                component: 'AnalyticsContainer',
                title: 'Analytics',
                icon: 'pie-chart',
              },
              {
                path: '/attemptedlogins',
                component: 'AttemptedLoginsContainer',
                title: 'Attempted Logins',
                icon: 'user',
              },
            ],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Settings',
            children: [
              {
                path: '/organizations',
                icon: 'border',
                component: 'OrganizationsContainer',
                title: 'Organizations',
              },
              {
                path: '/activity',
                icon: 'usergroup-add',
                component: 'ActivityContainer',
                title: 'Activity',
              },
              {
                path: '/trainer',
                icon: 'usergroup-add',
                component: 'TrainerContainer',
                title: 'Trainer',
              },

              {
                path: '/membership',
                icon: 'idcard',
                component: 'MembershipContainer',
                title: 'Membership',
              },
            ],
          },
          {
            path: '/god',
            icon: 'idcard',
            component: 'GodContainer',
            title: 'God Page',
          },
        ]);
      } else if (data.me.role == 'superadmin') {
        setMenu([
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Videos',
            children: [
              {
                path: '/',
                icon: 'cloud-upload',
                component: 'VideosContainer',
                title: 'Videos',
              },
              {
                path: '/sharedvideos',
                icon: 'cloud-upload',
                component: 'SharedVideosContainer',
                title: 'Shared Videos',
              },
              {
                path: '/calendar',
                icon: 'calendar',
                component: 'CalendarContainer',
                title: 'Calendar',
              },
            ],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Users',
            children: [
              {
                path: '/users',
                icon: 'user',
                component: 'UsersContainer',
                title: 'Users And Roles',
              },
              {
                path: '/comments',
                icon: 'message',
                component: 'CommentsContainer',
                title: 'Comments',
              },
              {
                path: '/analytics',
                component: 'AnalyticsContainer',
                title: 'Analytics',
                icon: 'pie-chart',
              },
              {
                path: '/attemptedlogins',
                component: 'AttemptedLoginsContainer',
                title: 'Attempted Logins',
                icon: 'user',
              },
            ],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Settings',
            children: [
              {
                path: '/organizations',
                icon: 'border',
                component: 'OrganizationsContainer',
                title: 'Organizations',
              },
              {
                path: '/activity',
                icon: 'usergroup-add',
                component: 'ActivityContainer',
                title: 'Activity',
              },
              {
                path: '/trainer',
                icon: 'usergroup-add',
                component: 'TrainerContainer',
                title: 'Trainer',
              },

              {
                path: '/membership',
                icon: 'idcard',
                component: 'MembershipContainer',
                title: 'Membership',
              },
              {
                path: '/organnouncements',
                icon: 'email',
                component: 'OrgAnnouncements',
                title: 'Announcmens',
              },
            ],
          },
        ]);
      } else if (data.me.role == 'admin') {
        setMenu([
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Videos',
            children: [
              {
                path: '/',
                icon: 'cloud-upload',
                component: 'MyVideosContainer',
                title: 'My Videos',
              },
              {
                path: '/myportal',
                icon: 'cloud-upload',
                component: 'MyPortalContainer',
                title: 'My Portal',
              },
              {
                path: '/allvideos',
                icon: 'cloud-upload',
                component: 'AllVideosContainer',
                title: 'All Videos',
              },
              {
                path: '/calendar',
                icon: 'calendar',
                component: 'CalendarContainer',
                title: 'Calendar',
              },
            ],
          },
          {
            submenu: true,
            icon: 'user',
            title: 'Users',
            children: [
              {
                path: '/users',
                icon: 'user',
                component: 'UsersContainer',
                title: 'Users And Roles',
              },
              {
                path: '/comments',
                icon: 'message',
                component: 'CommentsContainer',
                title: 'Comments',
              },
              {
                path: '/analytics',
                component: 'AnalyticsContainer',
                title: 'Analytics',
                icon: 'pie-chart',
              },
              {
                path: '/attemptedlogins',
                component: 'AttemptedLoginsContainer',
                title: 'Attempted Logins',
                icon: 'user',
              },
            ],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Settings',
            children: [
              {
                path: '/settings',
                component: 'EmailSettingsContainer',
                title: 'General Settings',
                icon: 'setting',
              },
              {
                path: '/activity',
                icon: 'usergroup-add',
                component: 'ActivityContainer',
                title: 'Activity',
              },
              {
                path: '/trainer',
                icon: 'usergroup-add',
                component: 'TrainerContainer',
                title: 'Trainer',
              },
              {
                path: '/membership',
                icon: 'idcard',
                component: 'MembershipContainer',
                title: 'Membership',
              },
            ],
          },
        ]);
      } else {
        const routes = [...data.me.enabledRoutes];
        const temp: any = [
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Videos',
            children: [],
          },
          {
            submenu: true,
            icon: 'user',
            title: 'Users',
            children: [],
          },
          {
            submenu: true,
            icon: 'cloud-upload',
            title: 'Settings',
            children: [],
          },
        ];
        routes.forEach(item => {
          if (item.title == null) {
            return;
          }
          if (item.title == 'My Videos' || item.title == 'My Portal' || item.title == 'All Videos' || item.title == 'Calendar') {
            temp[0].children.push(item);
          }
          if (item.title == 'Analytics' || item.title == 'Users and Roles' || item.title == 'Comments' || item.title == 'AttemptedLogins') {
            temp[1].children.push(item);
          }
          if (
            item.title == 'General Settings' ||
            item.title == 'Settings' ||
            item.title == 'Activity' ||
            item.title == 'Trainer' ||
            item.title == 'Membership'
          ) {
            temp[2].children.push(item);
          }
        });
        setMenu(temp);
      }
    }
  }, [data, loading]);

  let activeMenu = window.location.pathname.toString();
  const handleLogOut = () => {
    console.log('Logout Initiated......');
    removeToken();
    removeUserData();
    window.location.reload();
  };

  return (
    <StyledSidebar trigger={null} width={280} collapsible collapsed={collapse}>
      <StyledSidebarHeader style={{ padding: `${collapse ? '7px' : '5px 18px'}` }}>
        {/* <Link to="/newservice"> */}
        {collapse ? (
          organization ? (
            <img src={organization.logo} alt="Org logo" style={{ width: '100%' }} />
          ) : (
            <Avatar size={64} icon="user" />
          )
        ) : organization ? (
          <img
            src={organization.logo}
            alt="Org logo"
            style={{
              margin: '20px auto',
              objectFit: 'contain',
              objectPosition: 'left',
              display: 'block',
              maxWidth: 'auto',
              maxHeight: '100px',
              width: '100%',
              height: 'auto',
            }}
          />
        ) : (
          <h1>Super Admin</h1>
        )}
        {/* </Link> */}
      </StyledSidebarHeader>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[activeMenu]} defaultOpenKeys={['services_submenu']}>
        {/* {menu &&
          menu.map((singleMenu, index) => {
            if (singleMenu.title !== null && singleMenu.icon !== null) {
              return (
                <Menu.Item key={singleMenu.path}>
                  <Link to={singleMenu.path}>
                    <Icon type={singleMenu.icon} />
                    <span>{singleMenu.title}</span>
                  </Link>
                </Menu.Item>
              );
            }
          })} */}
        {menu &&
          menu.map((singleMenu, index) => {
            if (singleMenu.submenu) {
              if (singleMenu.children && singleMenu.children.length !== 0) {
                if (singleMenu.title !== null) {
                  return (
                    <SubMenu
                      key={singleMenu.title}
                      title={
                        <span>
                          <Icon type={singleMenu.icon} />
                          <span>{singleMenu.title}</span>
                        </span>
                      }
                    >
                      {singleMenu.children.map((item, index) => {
                        return (
                          <Menu.Item key={item.path}>
                            <Link to={item.path}>
                              <span>{item.title}</span>
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }
              }
            } else {
              if (singleMenu.title !== null) {
                return (
                  <Menu.Item key={singleMenu.path}>
                    <Link to={singleMenu.path}>
                      <Icon type={singleMenu.icon} />
                      <span>{singleMenu.title}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            }
          })}
        {/* <Menu.Item key="/">
          <Link to="/">
            <Icon type="snippets" />
            <span>Gates</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/flaggedbookings">
          <Link to="/flaggedbookings">
            <Icon type="book" />
            <span>Flagged Bookings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/settings">
          <Link to="/settings">
            <Icon type="setting" />
            <span>Settings</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="logout" style={{ marginTop: '25px', color: '#e74c3c', fontSize: '20px' }} onClick={handleLogOut}>
          <Icon type="logout" />
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </StyledSidebar>
  );
};

export default Sidebar;
