import React from 'react';
import { Button, Popconfirm } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
import DropdownActions from 'component/DropdownActions/DropdownActions';

const OrganizationTable = ({ organizations, openEditOrganizationModal, onDeleteOrganization, onBillingInfo, tableLoading }) => {
  const columns = [
    {
      key: 'name',
      title: 'Organization Name',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.name}
        </Button>
      ),
    },
    {
      key: 'email',
      title: 'Email',
      render: record => <span>{record.email}</span>,
    },
    {
      key: 'provider',
      title: 'Provider',
      render: record => <span>{record.provider}</span>,
    },
    {
      key: 'location',
      title: 'Location',
      width: 250,
      render: record => <span>{`${!!record.city ? record.city + ', ' : ''} ${record.country || ''}`}</span>,
    },
    {
      key: 'phoneNumber',
      title: 'Phone Number',
      render: record => <span>{record.phoneNumber}</span>,
    },
    {
      key: 'status',
      title: 'Status',
      render: record => <span>{record.status}</span>,
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => <span>{moment(record.createdAt).format('DD MMM, YYYY')}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <DropdownActions
          menuItem={[
            {
              key: 'billing',
              title: 'Billing Info',
              onClick: () => onBillingInfo(record),
            },
            {
              key: 'edit',
              title: 'Edit',
              onClick: () => openEditOrganizationModal(record),
            },
            {
              key: 'delete',
              title: 'Delete',
              onClick: () => onDeleteOrganization(record._id),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      <AntdTable
        // size="small"
        loading={tableLoading}
        scroll={{ x: true }}
        pagination={true}
        rowKey={record => record._id}
        columns={columns}
        dataSource={organizations}
      />
    </div>
  );
};

export default OrganizationTable;
