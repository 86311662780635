import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_LIKED_VIDEOS_QUERY } from 'apollo/user/queries';
import Loader from 'react-loader-spinner';
import AntdTable from 'component/elements';
import moment from 'moment';

const LikedVideosModal = ({ visible, currentItem, handleCancel }) => {
  const [likedVideos, setLikedVideos] = useState<any>(null);
  const [getLikedVideos] = useLazyQuery(USER_LIKED_VIDEOS_QUERY, {
    onCompleted: data => {
      if (data && data.userLikedVideos) {
        setLikedVideos(data.userLikedVideos);
      } else {
        setLikedVideos([]);
      }
    },
    onError: err => {
      console.error('err in liked videos', err);
      setLikedVideos([]);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (currentItem) {
      getLikedVideos({
        variables: {
          _id: currentItem._id,
        },
      });
    }
  }, [currentItem]);

  const close = () => {
    setLikedVideos(null);
    handleCancel();
  };

  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={
                record.thumbnail ||
                (record.organization && record.organization.settings && record.organization.settings.fallbackThumbnail) ||
                `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization.name}`
              }
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization.name}`;
              }}
              style={{
                background: '#fff',
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
      // sorter: (a, b) => a.title.length - b.title.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
      // sorter: (a, b) => a.activity.name.length - b.activity.name.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
      // sorter: (a, b) => a.trainer.name.length - b.trainer.name.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.title}
        </Button>
      ),
      // sorter: (a, b) => a.title.length - b.title.length,
      // sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <Modal title="Liked Videos" visible={visible} onCancel={close} footer={null} width={1000}>
      {likedVideos ? (
        <AntdTable pagination={true} rowKey={record => record._id} columns={columns} dataSource={likedVideos} />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </Modal>
  );
};

export default LikedVideosModal;
