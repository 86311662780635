import React, { useState } from 'react';
import { Row, Col, DatePicker, Button, InputNumber } from 'antd';
import moment from 'moment';
import { ALL_BILLING_INFO_QUERY } from 'apollo/organization/queries';
import { useQuery } from '@apollo/react-hooks';
import LoadingSpinner from 'component/Loader';
import { NetworkStatus } from 'apollo-boost';
import AllBillingTable from 'component/AllBillingInfo/AllBillingTable';
import VideoStorageModal from 'component/VideoStorageModal/VideoStorageModal';
import VideoBandwidthModal from 'component/VideoBandwidthModal/VideoBandwidthModal';
const { RangePicker } = DatePicker;

const GodContainer = () => {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showVideoStorageModal, setShowVideStorageModal] = useState(false);
  const [showVideoBandwidthModal, setShowVideBandwidthModal] = useState(false);
  const [sUnitPrice, setSUnitPrice] = useState(0.024);
  const [bUnitPrice, setBUnitPrice] = useState(0.09);
  const [newUnitPrice, setNewUnitPrice] = useState({
    storageUnitPrice: 0.024,
    bandwidthUnitPrice: 0.09,
  });
  const [startDate, setStartDate] = useState(moment().startOf('month').startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const { data, refetch, loading, networkStatus } = useQuery(ALL_BILLING_INFO_QUERY, {
    variables: {
      dateRange: {
        start: moment().startOf('month').startOf('day').toISOString(),
        end: moment().endOf('day').toISOString(),
      },
    },
    // onCompleted: data => {
    //   console.log('data', data);
    // },
    // onError: error => {
    //   console.error('error', error);
    // },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingSpinner />;

  if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner />;

  const refetchInfo = () => {
    refetch({
      dateRange: {
        start: moment(startDate).startOf('day').toISOString(),
        end: moment(endDate).endOf('day').toISOString(),
      },
    });
  };

  const onRangeChange = value => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  return (
    <Row style={{ marginTop: '30px' }}>
      <Col offset={1} span={22} style={{}}>
        <div style={{ background: 'white', padding: 20, marginBottom: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label>Date Range:</label>
              <RangePicker
                style={{ marginLeft: 10 }}
                value={[startDate, endDate]}
                disabledDate={(date: any) => {
                  return date && date > moment().endOf('day');
                }}
                onChange={onRangeChange}
              />
              <Button type="primary" style={{ marginLeft: 10 }} onClick={refetchInfo}>
                Apply
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 15, marginBottom: 15, alignItems: 'center' }}>
            <label style={{ marginRight: 5 }}>Storage Unit Price:</label>
            <InputNumber
              value={sUnitPrice}
              onChange={(val: any) => setSUnitPrice(val)}
              style={{ width: 100, marginRight: 15 }}
              step={0.001}
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            />
            <label style={{ marginRight: 5 }}>Bandwidth Unit Price: </label>
            <InputNumber
              value={bUnitPrice}
              onChange={(val: any) => setBUnitPrice(val)}
              style={{ width: 100, marginRight: 10 }}
              step={0.001}
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            />
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setNewUnitPrice({
                  storageUnitPrice: sUnitPrice,
                  bandwidthUnitPrice: bUnitPrice,
                })
              }
            >
              Calculate
            </Button>
          </div>
          {data && data.allBillingInfo && (
            <div id="billing">
              <AllBillingTable
                data={data.allBillingInfo}
                newUnitPrice={newUnitPrice}
                onStorage={org => {
                  setSelectedOrg(org);
                  setShowVideStorageModal(true);
                }}
                onBandwidth={org => {
                  setSelectedOrg(org);
                  setShowVideBandwidthModal(true);
                }}
              />
            </div>
          )}
        </div>
      </Col>
      <VideoStorageModal
        visible={showVideoStorageModal}
        closeModal={() => {
          setSelectedOrg(null);
          setShowVideStorageModal(false);
        }}
        organization={selectedOrg}
      />
      <VideoBandwidthModal
        visible={showVideoBandwidthModal}
        closeModal={() => {
          setSelectedOrg(null);
          setShowVideBandwidthModal(false);
        }}
        organization={selectedOrg}
      />
    </Row>
  );
};

export default GodContainer;
