import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button, Icon } from 'antd';
import AddEditWantAccessModal from 'component/AddEditWantAccessModal/AddEditWantAccessModal';

const WantAccessTable = ({ wantAccesses, onAdd, onDelete, onEdit }) => {
  const [showAddEditWantAccessModal, setShowAddEditWantAccessModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedWantAccess, setSelectedWantAccess] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'title',
      title: 'Title',
      render: record => <p>{record.title}</p>,
    },
    {
      key: 'url',
      title: 'URL',
      render: record => <p>{record.url}</p>,
    },
    {
      key: 'newTab',
      title: 'New Tab',
      render: record => <>{record.newTab ? <Icon type="check" style={{ color: 'green' }} /> : <Icon type="close" style={{ color: 'red' }} />}</>,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedWantAccess(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditWantAccessModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditWantAccessModal = () => {
    setSelectedWantAccess(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditWantAccessModal(false);
  };

  const onAddWantAccess = wantAccess => {
    onAdd(wantAccess);
    closeAddEditWantAccessModal();
  };
  const onEditWantAccess = wantAccess => {
    onEdit(wantAccess, selectedIndex);
    closeAddEditWantAccessModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditWantAccessModal(true)}>
        Add Want Access Button
      </Button>
      <AntdTable pagination={false} rowKey={record => `${record.title}${record.url}`} columns={columns} dataSource={wantAccesses} size="small" />
      <AddEditWantAccessModal
        visible={showAddEditWantAccessModal}
        currentItem={selectedWantAccess}
        isCreate={isCreate}
        onAdd={onAddWantAccess}
        onEdit={onEditWantAccess}
        onCancel={closeAddEditWantAccessModal}
      />
    </div>
  );
};

export default WantAccessTable;
