import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import { Select } from 'antd';

const { Option } = Select;

const OrganizationSelect = ({ value, onChange }) => {
  const [organizations, setOrganizations] = useState<any>(null);

  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      setOrganizations(data.organizations);
    },
    onError: err => {
      console.log('err in org', err);
    },
  });
  return (
    <div style={{ width: '100%' }}>
      <Select style={{ width: '100%' }} value={value} onChange={val => onChange(val)}>
        <Option key="allOrg" value="all">
          All
        </Option>
        {organizations &&
          organizations.map(item => (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default OrganizationSelect;
