import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col } from 'antd';
import { ORGANIZATION_QUERY } from 'apollo/organization/queries';
import LoadingSpinner from 'component/Loader';
import OrgDetailSettings from 'component/OrgDetailSettings/OrgDetailSettings';
import OrgOtherSettings from 'component/OrgOtherSettings/OrgOtherSettings';

const OrgSettingsContainer: React.FC<any> = () => {
  const [organization, setOrganization] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useQuery(ORGANIZATION_QUERY, {
    onCompleted: data => {
      if (data.organization) {
        const { organization } = data;
        setOrganization(organization);
      }
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Row style={{ marginTop: 15, marginBottom: 15 }}>
      <Col span={11} offset={1} style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        <OrgDetailSettings organization={organization} setLoading={setLoading} />
      </Col>
      <Col span={10} offset={1} style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        <OrgOtherSettings organization={organization} />
      </Col>
    </Row>
  );
};
export default OrgSettingsContainer;
