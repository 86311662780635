export default [
  {
    path: '/',
    icon: 'cloud-upload',
    component: 'MyVideosContainer',
    title: 'My Videos',
  },
  {
    path: '/myportal',
    icon: 'cloud-upload',
    component: 'MyPortalContainer',
    title: 'My Portal',
  },
  {
    path: '/allvideos',
    icon: 'cloud-upload',
    component: 'AllVideosContainer',
    title: 'All Videos',
  },
  {
    path: '/analytics',
    component: 'AnalyticsContainer',
    title: 'Analytics',
    icon: 'pie-chart',
  },
  {
    path: '/users',
    component: 'UsersContainer',
    title: 'Users and Roles',
    icon: 'user',
  },
  {
    path: '/comments',
    component: 'CommentsContainer',
    title: 'Comments',
    icon: 'message',
  },
  {
    path: '/activity',
    icon: 'usergroup-add',
    component: 'ActivityContainer',
    title: 'Activity',
  },
  {
    path: '/trainer',
    icon: 'usergroup-add',
    component: 'TrainerContainer',
    title: 'Trainer',
  },
  {
    path: '/calendar',
    icon: 'calendar',
    component: 'CalendarContainer',
    title: 'Calendar',
  },
  {
    path: '/membership',
    icon: 'idcard',
    component: 'MembershipContainer',
    title: 'Membership',
  },
  {
    path: null,
    icon: null,
    component: null,
    title: 'Meetings',
  },
  {
    path: '/attemptedlogins',
    component: 'AttemptedLoginsContainer',
    title: 'Attempted Logins',
    icon: 'user',
  },
  {
    path: '/settings',
    component: 'OrganizationSettingsContainer',
    title: 'General Settings',
    icon: 'setting',
  },
];
