import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const { Option } = Select;

interface AddEditModerationModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  ownOrganization: any;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditModerationModalComponent: React.FC<AddEditModerationModalProps> = ({
  form,
  ownOrganization,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [organizations, setOrganizations] = useState<any>(null);
  const [organizationId, setOrganizationId] = useState<null | string>(null);
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moderationParam, setModerationParam] = useState<any>({
    from: undefined,
    by: undefined,
    shareByActivity: undefined,
    shareByTrainer: undefined,
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      setOrganizations(data.organizations);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: data => {
      setActivities(data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: data => {
      setTrainers(data.trainers);
    },
  });
  useEffect(() => {
    if (currentItem) {
      setOrganizationId(currentItem.from._id);
      setModerationParam({
        ...moderationParam,
        from: { key: currentItem.from?._id, label: currentItem.from?.name },
        by: currentItem.by,
        shareByActivity:
          currentItem.by != 'all'
            ? {
                key: currentItem.shareByActivity?._id,
                label: currentItem.shareByActivity?.name,
              }
            : undefined,
        shareByTrainer: currentItem.by != 'all' ? { key: currentItem.shareByTrainer?._id, label: currentItem.shareByTrainer?.label } : undefined,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setOrganizationId(null);
    setModerationParam({
      from: undefined,
      by: undefined,
      shareByActivity: undefined,
      shareByTrainer: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const moderation = {
        from: values.from,
        by: values.by,
        shareByActivity: values.shareByActivity,
        shareByTrainer: values.shareByTrainer,
      };
      if (isCreate) {
        onAdd(moderation);
      } else {
        onEdit(moderation);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Moderation' : 'Edit Moderation'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit} loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="From Organization">
          {getFieldDecorator('from', {
            initialValue: moderationParam.from,
            rules: [
              {
                required: true,
                message: 'Please input the organization!',
              },
            ],
          })(
            <Select
              labelInValue={true}
              placeholder="Select organization"
              onChange={(val: any) => {
                console.log(val);
                setOrganizationId(`${val.key}`);
                form.setFields({ shareByTrainer: undefined, shareByActivity: undefined });
                setModerationParam(prev => ({ ...prev, shareByTrainer: undefined, shareByActivity: undefined }));
              }}
            >
              {organizations &&
                organizations
                  .filter(item => item._id !== ownOrganization._id)
                  .map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="By">
          {getFieldDecorator('by', {
            initialValue: moderationParam.by,
            rules: [
              {
                required: true,
                message: 'Please select an option!',
              },
            ],
          })(
            <Select
              placeholder="Select an option"
              onChange={val => {
                form.setFields({ shareByTrainer: undefined, shareByActivity: undefined });
                setModerationParam(prev => ({ ...prev, by: val, shareByTrainer: undefined, shareByActivity: undefined }));
              }}
            >
              <Option value="all">All</Option>
              <Option value="activity">Activity</Option>
              <Option value="trainer">Trainer</Option>
            </Select>
          )}
        </Form.Item>
        {moderationParam.by == 'activity' && (
          <Form.Item label="Activity">
            {getFieldDecorator('shareByActivity', {
              initialValue: moderationParam.shareByActivity,
              rules: [
                {
                  required: true,
                  message: 'Please select activity!',
                },
              ],
            })(
              <Select placeholder="Select an activity" labelInValue={true}>
                {activities &&
                  activities.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
        {moderationParam.by == 'trainer' && (
          <Form.Item label="Trainer">
            {getFieldDecorator('shareByTrainer', {
              initialValue: moderationParam.shareByTrainer,
              rules: [
                {
                  required: true,
                  message: 'Please select trainer!',
                },
              ],
            })(
              <Select placeholder="Select a trainer" labelInValue={true}>
                {trainers &&
                  trainers.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
const AddEditModerationModal = Form.create<AddEditModerationModalProps>()(AddEditModerationModalComponent);
export default AddEditModerationModal;
