import React, { useRef, useState } from 'react';
import { Button, Tooltip, Input, Icon, notification } from 'antd';
import moment from 'moment';
import AntdTable from 'component/elements';
import { USER_ROLES_QUERY } from 'apollo/user/queries';
import { useQuery } from '@apollo/react-hooks';
const PAGE_LIMIT = 50;

const AttemptedLoginsTable = ({ users, tableLoading, paginationData, handleTableChange, setTableLoading }) => {
  let searchInputRef: any = null;
  const [userRoles, setUserRoles] = useState<any>(null);
  useQuery(USER_ROLES_QUERY, {
    onCompleted: data => {
      setUserRoles(data.getUserRoles);
    },
  });

  const getSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ref => (searchInputRef = ref)}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = clearFilters => {
    clearFilters();
  };

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      render: record => <span>{record.firstName}</span>,
      ...getSearchProps('firstName'),
    },
    {
      key: 'lastName',
      title: 'Last Name',
      render: record => <span>{record.lastName}</span>,
      ...getSearchProps('lastName'),
    },

    {
      key: 'email',
      title: 'Email',
      render: record => {
        return <span>{record.email}</span>;
      },
      ...getSearchProps('email'),
    },
    {
      key: 'name',
      title: 'Organization Name',
      render: record => {
        return <span>{record.organization && record.organization.name}</span>;
      },
    },
    {
      key: 'role',
      title: 'Role',
      render: record => {
        return <span>{record.role}</span>;
      },
      filters:
        userRoles &&
        userRoles.map(item => ({
          text: item,
          value: item,
        })),
      filterMultiple: true,
    },
    {
      key: 'loginAttempt',
      title: 'Login Attempt',
      render: record => {
        return <span>{record.loginAttempt}</span>;
      },
    },
    {
      key: 'loginError',
      title: 'Login Error',
      render: record => {
        return <span>{record.loginError || 'none'}</span>;
      },
    },
  ];
  return (
    <div>
      <AntdTable
        scroll={{ x: true }}
        loading={tableLoading}
        dataSource={users}
        onChange={handleTableChange}
        pagination={{
          position: 'bottom',
          pageSize: PAGE_LIMIT,
          total: paginationData.total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          // onChange: handlePageChange,
        }}
        rowKey={record => record._id}
        columns={columns}
      />
    </div>
  );
};

export default AttemptedLoginsTable;
