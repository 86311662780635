import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const SHARED_VIDEOS_QUERY = gql`
  query sharedVideos($organizationId: String) {
    sharedVideos(organizationId: $organizationId) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
