import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Upload,
  Button,
  Input,
  Form,
  Icon,
  Switch,
  message,
  Select,
  Tooltip,
  DatePicker,
  Radio,
  notification,
  Progress,
  Popconfirm,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import AddActivityModal from 'component/AddActivityModal/AddActivityModal';
import AddTrainerModal from 'component/AddTrainerModal/AddTrainerModal';
import { CREATE_VIDEO_MUTATION, STOP_LIVE_STREAM_MUTATION } from 'apollo/video/mutations';
import { useLocation } from 'react-router-dom';
import Api from 'axios/Api';
// import { VIDEOS_PAGE_FILTER_QUERY, VIDEOS_QUERY } from 'apollo/video/queries';
import moment from 'moment';
import StreamComp from 'component/StreamComp/StreamComp';
import UploadProgressModal from 'component/UploadProgressModal/UploadProgressModal';
import { GENERATE_PRE_SIGNED_URL_MUTATION } from 'apollo/s3/mutations';
import { getUserData } from 'helper';
import { ORGANIZATIONS_QUERY, ORGANIZATION_QUERY } from 'apollo/organization/queries';
import LabelwTooltip from 'component/LabelwTooltip/LabelwTooltip';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

interface VideoUploadProps extends FormComponentProps {}

const equipments = [
  'No Equipment',
  'Balance Board',
  'Barbell',
  'Bench',
  'Bosu',
  'Box/Step',
  'Chair',
  'Dumbbells',
  'Elastic Bands',
  'Exercise Ball',
  'Foam Roller',
  'Kettle Bell',
  'Medicine Ball',
  'Resistence Band',
  'Rope',
  'Speed Ladder',
  'Stick',
  'Tennis Ball',
];

class SelectInput extends React.Component<any> {
  handleSelectChange = selectValue => {
    this.triggerChange({ selectValue });
  };
  triggerChange = changedValue => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  render() {
    const { arr, value, onClickAdd, field } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Select
          labelInValue
          showSearch
          style={{ width: '85%' }}
          placeholder={`Select a ${field}`}
          optionFilterProp="children"
          onChange={this.handleSelectChange}
          value={value.selectValue}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {arr.length > 0 &&
            arr.map((item: any) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Tooltip placement="bottom" title="Add New Trainer" key="Add New Trainer">
          <Button style={{ width: '10%' }} type="primary" onClick={onClickAdd}>
            <Icon type="plus" />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

const VideoUploadComponent: React.FC<VideoUploadProps> = ({ form }) => {
  const location: any = useLocation();
  const [organizations, setOrganizations] = useState<any>(null);
  const [orgId, setOrgId] = useState<any>(null);
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [memberships, setMemberships] = useState<null | Array<Object>>(null);
  const [videoId, setVideoId] = useState<null | String>(null);
  const [streamId, setStreamId] = useState<null | String>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [defaultVideoMemberships, setDefaultVideoMemberships] = useState([]);
  const [videoParam, setVideoParam] = useState<any>({
    title: undefined,
    description: undefined,
    trainer: undefined,
    thumbnail: undefined,
    activity: undefined,
    organizationId: undefined,
    videoFile: '',
    premiere: false,
    status: 'published',
    allowComments: false,
    premiereAt: null,
    intensity: 'low',
    equipments: [],
    tags: [],
    sharing: 'default',
    captionUrl: undefined,
    memberships: defaultVideoMemberships || [],
    membershipRestriction: true,
  });
  const [totalActivity, setTotalActivity] = useState(0);
  const [thumbnail, setThumbnail] = useState<any>([]);
  const [caption, setCaption] = useState<any>([]);
  const [filelists, setFilelists] = useState<any>([]);
  const [goLive, setGoLive] = useState<boolean>(false);
  const [showAddTrainerModal, setShowAddTrainerModal] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [stopLiveStream] = useMutation(STOP_LIVE_STREAM_MUTATION);
  const [createVideo] = useMutation(CREATE_VIDEO_MUTATION, {
    // update(cache, { data: { createVideo } }) {
    //   console.log('cache', cache);
    //   console.log('createViedo', createVideo);
    //   const cachedData: any = cache.readQuery({
    //     query: VIDEOS_PAGE_FILTER_QUERY,
    //     variables: {
    //       paginationInput: {
    //         limit: 10,
    //         page: 1,
    //       },
    //       videosPageFilterInput: {},
    //       sorterInput: {
    //         field: 'createdAt',
    //         order: 'descend',
    //       },
    //     },
    //   });
    //   console.log('cachedData', cachedData);
    //   cache.writeQuery({
    //     query: VIDEOS_PAGE_FILTER_QUERY,
    //     data: {
    //       videosPageFilter: {
    //         ...cachedData.videosPageFilter,
    //         videos: [...cachedData.videosPageFilter.videos, createVideo],
    //       },
    //     },
    //   });
    // },
    // refetchQueries: ['videosPageFilter'],
  });
  const [generatePresignedUrl] = useMutation(GENERATE_PRE_SIGNED_URL_MUTATION);
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => setOrganizations(data.organizations),
  });
  useQuery(ORGANIZATION_QUERY, {
    onCompleted: data => {
      setDefaultVideoMemberships(data?.organization?.settings?.defaultVideoMemberships || []);
      setVideoParam(prev => ({ ...prev, memberships: data?.organization?.settings?.defaultVideoMemberships || [] }));
    },
  });
  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      lg: { span: 12 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      sm: {
        span: 24,
        offset: 0,
      },
      md: {
        span: 12,
        offset: 8,
      },
      lg: {
        span: 12,
        offset: 6,
      },
    },
  };
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let goLiveQuery = queryParams.get('goLive');
      setGoLive(goLiveQuery == '1' ? true : false);
    }
  }, [location]);

  const renderEquipments = equipments.map(item => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));
  const resetFields = () => {
    form.resetFields();
    setVideoParam({
      title: undefined,
      description: undefined,
      organizationId: undefined,
      trainer: undefined,
      activity: undefined,
      videoFile: '',
      premiere: false,
      status: 'published',
      allowComments: false,
      premiereAt: null,
      intensity: 'low',
      equipments: [],
      tags: [],
      thumbnail: undefined,
      sharing: 'default',
      captionUrl: undefined,
      memberships: defaultVideoMemberships || [],
      membershipRestriction: true,
    });
    setProgressPercent(0);
    setFilelists([]);
    setThumbnail([]);
    setIsLoading(false);
  };

  const openAddTrainerModal = () => {
    setShowAddTrainerModal(true);
  };
  const openAddActivityModal = () => {
    setShowAddActivityModal(true);
  };

  const handleActivityModalCancel = () => {
    setShowAddActivityModal(false);
  };
  const handleTrainerModalCancel = () => {
    setShowAddTrainerModal(false);
  };
  useQuery(TRAINERS_QUERY, {
    variables: {
      organizationId: orgId,
    },
    onCompleted: data => {
      // console.log('trainers', data.trainers);
      setTrainers(data.trainers);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    variables: {
      organizationId: orgId,
    },
    onCompleted: data => {
      // console.log('activities', data);
      setActivities(data.activities);
      setTotalActivity(data.activities.length);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(MEMBERSHIPS_QUERY, {
    variables: {
      organizationId: orgId,
    },
    onCompleted: data => {
      // console.log('activities', data);
      setMemberships(data.memberships);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });

  const checkValidation = (rule, value, callback) => {
    if (value.selectValue) {
      return callback();
    }
    callback('Cannot be blank!');
  };

  const onMembershipRestrictionChange = val => {
    let memberships: any[] = [];
    if (val == true) {
      memberships = defaultVideoMemberships || [];
    }
    setVideoParam(prev => ({ ...prev, membershipRestriction: val, memberships: memberships || [] }));
  };
  useEffect(() => {
    form.setFieldsValue({ memberships: videoParam.membershipRestriction ? defaultVideoMemberships : [] });
  }, [videoParam.membershipRestriction]);
  const onAllowCommentsChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, allowComments: val }));
  };
  const onPremiereChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, premiere: val }));
  };
  const onPublishChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, isPublished: val }));
  };

  const onDateChange = val => {
    setVideoParam(prev => ({ ...prev, premiereAt: val }));
  };
  const onIntensityChange = val => {
    // console.log('intensity', val);
    setVideoParam(prev => ({ ...prev, intensity: val }));
  };
  const handleEquipmentChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, equipments: val }));
  };
  const handleStatusChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, status: val }));
  };
  const handleSharingChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, sharing: val }));
  };

  const beforeUploadCaption = file => {
    // console.log('file', file);
    setCaption(prev => [file]);
    return false;
  };

  const onRemoveCaption = file => {
    // console.log('here', file);
    setCaption(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, captionUrl: undefined }));
  };
  const beforeUploadThumbnail = file => {
    // console.log('file', file);
    setThumbnail(prev => [file]);
    getBase64(file, imageUrl => {
      setVideoParam(prev => ({ ...prev, thumbnail: imageUrl }));
    });
    return false;
  };

  const onRemoveThumbnail = file => {
    // console.log('here', file);
    setThumbnail(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, thumbnail: undefined }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = file => {
    setFilelists(prev => [...prev, file]);
    return false;
  };

  const onRemoveFile = file => {
    console.log('here', file);
    setFilelists(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
  };

  const uploadVideo = ({ input, organizationId }) => {
    console.log('input', input);
    createVideo({
      variables: {
        input: { ...input },
        organizationId: organizationId,
      },
    })
      .then(result => {
        // console.log('result', result);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.success({
          key: 'uploadingNotification',
          message: 'Video Uploaded',
          description: 'Video was uploaded successfully.',
          duration: 0,
        });
        resetFields();
      })
      .catch(err => {
        console.log('err', err);
        setProgressPercent(0);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Upload Failed',
          description: 'Video was not uploaded',
          duration: 0,
        });
      });
  };

  const onUploadProgress = progressEvent => {
    // console.log('prog event', progressEvent);
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // console.log(percentCompleted);
    setProgressPercent(percentCompleted);
  };

  const uploadToS3 = (url, payload) => {
    setShowProgressModal(true);
    Api.uploadVideo(url, filelists[0], onUploadProgress)
      .then(data => {
        // console.log('upload done', data);
        setProgressPercent(100);
        uploadVideo(payload);
        // if (data.data.success) {
        //   setProgressPercent(100);
        //   uploadVideo(input);
        // } else {
        //   setIsLoading(false);
        //   setShowProgressModal(false);
        //   setProgressPercent(0);
        //   notification.error({
        //     key: 'uploadingNotification',
        //     message: 'Video Upload Failed',
        //     description: err.toString().replace('Error: GraphQL error:', ''),
        //     duration: 0,
        //   });
        // }
      })
      .catch(err => {
        console.log('upload err', err);
        setIsLoading(false);
        setShowProgressModal(false);
        setProgressPercent(0);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Upload Failed',
          description: err.toString().replace('Error: GraphQL error:', ''),
          duration: 0,
        });
      });
  };

  const onSubmit = () => {
    if (!goLive) {
      if (filelists.length > 1) {
        message.error('Upload one video at a time');
        return;
      }
      form.validateFields(async (formErrors, values: any) => {
        if (formErrors) {
          return;
        }
        // return;
        try {
          let date = values.premiereAt ? moment(values.premiereAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY');
          let title = values.title == undefined || values.title == '' ? `${values.activity.selectValue.label} - ${date}` : values.title;
          setIsLoading(true);
          let thumbnailUrl = null;
          let captionUrl = null;
          if (thumbnail.length > 0) {
            // console.log(thumbnail);
            const res = await Api.uploadFile(thumbnail[0]);
            // console.log('data', res);
            const { data } = res;
            if (!data.success) {
              setIsLoading(false);
              const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
              notification.error({
                message: 'Video Create Failed',
                duration: 0,
                description: errorMessage,
              });
              return;
            }
            thumbnailUrl = data.result.url;
          }
          if (caption.length > 0) {
            console.log('caption', caption);
            const res = await Api.uploadFile(caption[0]);
            console.log('data caption', res);
            const { data } = res;
            if (!data.success) {
              setIsLoading(false);
              const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
              notification.error({
                message: 'Video Create Failed',
                duration: 0,
                description: errorMessage,
              });
              return;
            }
            captionUrl = data.result.url;
          }
          generatePresignedUrl({
            variables: {
              input: { type: 'put', mime: filelists[0].type },
            },
          })
            .then(async res => {
              const { url, type, vid } = res.data.generatePreSignedURL;
              const vidType = filelists[0].type.split('/')[1] == 'quicktime' ? 'mov' : filelists[0].type.split('/')[1];
              const input = {
                vid: vid,
                videoType: vidType,
                mimeType: filelists[0].type,
                rawMimeType: filelists[0].type,
                title: title,
                isLive: goLive,
                description: values.description,
                tags: values.tags,
                activity: values.activity.selectValue.key,
                trainer: values.trainer.selectValue.key,
                premium: false,
                intensity: values.intensity,
                equipments: values.equipments,
                premiere: videoParam.premiere,
                status: values.status,
                memberships: values.memberships || [],
                membershipRestriction: videoParam.membershipRestriction,
                allowComments: videoParam.allowComments,
                thumbnail: thumbnailUrl,
                captionUrl: captionUrl,
                sharing: values.sharing,
                ...(videoParam.premiere && {
                  premiereAt: values.premiereAt.toISOString(),
                }),
              };
              uploadToS3(url, { input, organizationId: values.organizationId });
              // uploadVideo({ input, organizationId: values.organizationId });
            })
            .catch(err => {
              setIsLoading(false);
              setShowProgressModal(false);
              setProgressPercent(0);
              notification.error({
                key: 'uploadingNotification',
                message: 'Video Upload Failed',
                description: 'Video was not uploaded',
                duration: 0,
              });
            });
        } catch (error) {
          setIsLoading(false);
          const errorMessage = error.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Video Create Failed',
            duration: 0,
            description: errorMessage,
          });
        }
      });
    } else {
      console.log('live stream');
      // streamApi.createStream();
    }
  };

  const liveStart = _streamId => {
    return new Promise((resolve, reject) => {
      form.validateFields(async (formErrors, values: any) => {
        if (formErrors) {
          reject('err');
          return null as any;
        }
        // return;
        // setIsLoading(true);
        // message.loading({
        //   content: 'uploading',
        //   key: 'uploading',
        //   duration: 0,
        // });
        let date = values.premiereAt ? moment(values.premiereAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY');
        let title = values.title == undefined || values.title == '' ? `${values.activity.selectValue.label} - ${date}` : values.title;

        const input = {
          vid: _streamId,
          title: title,
          isLive: goLive,
          description: values.description,
          tags: values.tags,
          activity: values.activity.selectValue.key,
          trainer: values.trainer.selectValue.key,
          premium: false,
          intensity: values.intensity,
          equipments: values.equipments,
          premiere: videoParam.premiere,
          allowComments: videoParam.allowComments,
          memberships: values.memberships || [],
          membershipRestriction: videoParam.membershipRestriction,
          sharing: values.sharing,
          ...(videoParam.premiere && {
            premiereAt: values.premiereAt.toISOString(),
          }),
        };
        console.log('input live', input);
        createVideo({
          variables: {
            input: { ...input },
          },
        })
          .then(result => {
            setStreamId(result.data.createVideo.vid);
            setVideoId(result.data.createVideo._id);
            resolve(result);
            // console.log('result', result);
            // setIsLoading(false);
            // message.success({
            //   content: 'Video Uploaded Successfully',
            //   key: 'uploading',
            //   duration: 2,
            // });
            // resetFields();
          })
          .catch(err => {
            console.log('err', err);
            // setIsLoading(false);
            notification.error({
              message: 'Stream start Failed',
              description: 'Stream was not started. Please try again.',
              duration: 0,
            });
          });
      });
    });
  };

  const liveEnd = () => {
    stopLiveStream({
      variables: {
        _id: videoId,
        vid: streamId,
      },
    }).then(done => {
      notification.success({
        message: 'Stream Ended',
        description: 'Streaming ended successfully.',
        duration: 0,
      });
    });
  };

  return (
    <>
      {goLive && (
        <Row>
          <Col span={16} offset={1}>
            <StreamComp onStart={liveStart} onEnd={liveEnd} setStreaming={setStreaming} />
          </Col>
        </Row>
      )}
      {trainers && activities && !streaming && (
        <Row style={{ marginTop: 10 }}>
          <Col span={16} offset={1}>
            <Card title={goLive ? 'Start Streaming (Fill up the form before start live)' : 'Upload Video to Library'}>
              <Form {...formItemLayout}>
                {user.role === 'superadmin' ? (
                  <Form.Item label="Organization">
                    {getFieldDecorator('organizationId', {
                      initialValue: videoParam.organizationId,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the organization of video !',
                        },
                      ],
                    })(
                      <Select placeholder="Select organization of the video" onChange={val => setOrgId(val)}>
                        {organizations &&
                          organizations.map(item => (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : null}
                <Form.Item
                  label={
                    <LabelwTooltip label="Title" tooltipText="Optional, only add title if you want a different title than trainer - activity." />
                  }
                >
                  {getFieldDecorator('title', {
                    initialValue: videoParam.title,
                  })(<Input placeholder="Enter Title" />)}
                </Form.Item>
                <Form.Item label="Description">
                  {getFieldDecorator('description', {
                    initialValue: videoParam.description,
                  })(<TextArea rows={4} placeholder="Enter Description" />)}
                </Form.Item>
                <Form.Item label="Tags">
                  {getFieldDecorator('tags', {
                    initialValue: videoParam.tags,
                  })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags"></Select>)}
                </Form.Item>
                {!!videoParam.thumbnail && (
                  <Row>
                    <Col {...formItemLayout.labelCol}></Col>
                    <Col {...formItemLayout.wrapperCol}>
                      <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
                        <img
                          alt="video preview"
                          src={videoParam.thumbnail}
                          style={{
                            background: '#fff',
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                          draggable={false}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <Form.Item label="Thumbnail">
                  {getFieldDecorator('thumbnail', {
                    initialValue: { fileList: thumbnail },
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Please input the name of the organization!',
                    //   },
                    // ],
                  })(
                    <Upload accept="image/*" fileList={thumbnail} beforeUpload={beforeUploadThumbnail} onRemove={onRemoveThumbnail}>
                      <Button>
                        <Icon type="upload" /> Upload
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item label="Closed Caption">
                  {getFieldDecorator('closedCaption', {
                    // initialValue: { fileList: thumbnail },
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Please input the name of the organization!',
                    //   },
                    // ],
                  })(
                    <Upload accept="text/*,.vtt" fileList={caption} beforeUpload={beforeUploadCaption} onRemove={onRemoveCaption}>
                      {/* <Popconfirm title="Coming Soon"> */}
                      <Button>
                        <Icon type="upload" /> Upload
                      </Button>
                      {/* </Popconfirm> */}
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item label="Trainer">
                  {getFieldDecorator('trainer', {
                    initialValue: { selectValue: videoParam.trainer },
                    rules: [
                      {
                        required: true,
                        validator: checkValidation,
                      },
                    ],
                  })(<SelectInput arr={trainers} onClickAdd={openAddTrainerModal} field="trainer" />)}
                </Form.Item>
                <Form.Item label="Activity">
                  {getFieldDecorator('activity', {
                    initialValue: { selectValue: videoParam.activity },
                    rules: [
                      {
                        required: true,
                        validator: checkValidation,
                      },
                    ],
                  })(
                    <SelectInput
                      arr={activities}
                      onClickAdd={openAddActivityModal}
                      field="activity"
                      onChange={s => {
                        if (activities) {
                          for (const a of activities) {
                            if ((a as any)._id == s.selectValue?.key) {
                              // console.log('act', a);
                              form.setFieldsValue({ equipments: (a as any).equipments || [] });
                            }
                          }
                        }
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Membership Restriction">
                  {getFieldDecorator('membershipRestriction', {
                    initialValue: { checked: videoParam.membershipRestriction },
                    rules: [
                      {
                        required: true,
                        message: 'Please input the title of Video!',
                      },
                    ],
                  })(<Switch checked={videoParam.membershipRestriction} onChange={onMembershipRestrictionChange} />)}
                </Form.Item>
                {videoParam.membershipRestriction && (
                  <Form.Item label="Memberships">
                    {getFieldDecorator('memberships', {
                      initialValue: videoParam.memberships,
                      rules: [
                        {
                          required: true,
                          message: 'Please add memberships!',
                        },
                      ],
                    })(
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select a Memberships"
                        optionFilterProp={'children'}
                        filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {memberships &&
                          memberships.length > 0 &&
                          memberships.map((item: any) => <Option key={item._id}>{item.membershipName}</Option>)}
                      </Select>
                    )}
                  </Form.Item>
                )}
                <Form.Item label="Intensity">
                  {getFieldDecorator('intensity', {
                    initialValue: videoParam.intensity,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the actvity!',
                      },
                    ],
                  })(
                    <Radio.Group onChange={onIntensityChange}>
                      <Radio.Button value="low">Low</Radio.Button>
                      <Radio.Button value="medium">Medium</Radio.Button>
                      <Radio.Button value="high">High</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item label="Equiment Needed">
                  {getFieldDecorator('equipments', {
                    initialValue: videoParam.equipments,
                  })(
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                      {renderEquipments}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Allow Comments">
                  {getFieldDecorator('allowComments', {
                    initialValue: { checked: videoParam.allowComments },
                    rules: [
                      {
                        required: true,
                        message: 'Please input the title of Video!',
                      },
                    ],
                  })(<Switch checked={videoParam.allowComments} onChange={onAllowCommentsChange} />)}
                </Form.Item>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: videoParam.status,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the title of Video!',
                      },
                    ],
                  })(
                    <Select style={{ width: '100%' }} onChange={handleStatusChange}>
                      <Option value="published">Publish</Option>
                      <Option value="notPublished">Not Publish</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Sharing">
                  {getFieldDecorator('sharing', {
                    initialValue: videoParam.sharing,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the title of Video!',
                      },
                    ],
                  })(
                    <Select style={{ width: '100%' }} onChange={handleSharingChange}>
                      <Option value="default">Default</Option>
                      <Option value="share">Share</Option>
                      <Option value="doNotShare">Do Not Share</Option>
                    </Select>
                  )}
                </Form.Item>
                {/* {!goLive && ( */}
                <>
                  <Form.Item label="Premiere">
                    {getFieldDecorator('premiere', {
                      initialValue: { checked: videoParam.premiere },
                      rules: [
                        {
                          required: true,
                          message: 'Please input the title of Video!',
                        },
                      ],
                    })(<Switch checked={videoParam.premiere} onChange={onPremiereChange} />)}
                  </Form.Item>
                  {videoParam.premiere && (
                    <Form.Item label="Premiere At">
                      {getFieldDecorator('premiereAt', {
                        initialValue: videoParam.premiereAt,
                        rules: [
                          {
                            required: true,
                            message: 'Please input premiere date!',
                          },
                        ],
                      })(<DatePicker showTime onChange={onDateChange} />)}
                    </Form.Item>
                  )}
                </>
                {/* )} */}
                {!goLive && (
                  <Form.Item wrapperCol={{ span: 24 }} required={true}>
                    {getFieldDecorator('filelists', {
                      initialValue: { fileList: filelists },
                      rules: [
                        {
                          required: true,
                          message: 'Please upload a video!',
                        },
                      ],
                    })(
                      <Dragger accept=".mov,video/*,.mkv" beforeUpload={beforeUpload} onRemove={onRemoveFile} fileList={filelists}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Click or drag video to this area to upload</p>
                      </Dragger>
                    )}
                  </Form.Item>
                )}
                {!goLive && (
                  <>
                    {!isLoading && (
                      <Form.Item {...tailFormItemLayout}>
                        <div style={{ textAlign: 'right' }}>
                          <Button type="danger" style={{ marginRight: 10 }} onClick={resetFields}>
                            Cancel
                          </Button>
                          {goLive ? (
                            <Button type="primary" htmlType="submit" onClick={onSubmit}>
                              Start Live
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={onSubmit}
                              disabled={filelists.length > 0 ? false : true}
                              loading={isLoading}
                            >
                              {isLoading ? 'Uploading' : 'Upload'}
                            </Button>
                          )}
                        </div>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      <UploadProgressModal visible={showProgressModal} progressPercent={progressPercent} />
      <AddActivityModal
        isCreate={true}
        currentItem={false}
        modalVisible={showAddActivityModal}
        handleOk={handleActivityModalCancel}
        handleCancel={handleActivityModalCancel}
        totalActivity={totalActivity}
      />
      <AddTrainerModal
        isCreate={true}
        currentItem={false}
        modalVisible={showAddTrainerModal}
        handleOk={handleTrainerModalCancel}
        handleCancel={handleTrainerModalCancel}
      />
    </>
  );
};
const VideoUploadContainer = Form.create<VideoUploadProps>()(VideoUploadComponent);
export default VideoUploadContainer;
