import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

const { Option } = Select;

interface AddEditCustomButtonsModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditCustomButtonsModalComponent: React.FC<AddEditCustomButtonsModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [customButtonsParam, setCustomButtonsParam] = useState<any>({
    title: undefined,
    url: undefined,
    action: undefined,
    icon: undefined,
  });

  useEffect(() => {
    if (currentItem) {
      setCustomButtonsParam({
        ...customButtonsParam,
        title: currentItem.title,
        url: currentItem.url,
        action: currentItem.action,
        icon: currentItem.icon,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setCustomButtonsParam({
      title: undefined,
      url: undefined,
      action: undefined,
      icon: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const customButton = {
        title: values.title,
        url: values.url,
        action: values.action,
        icon: values.icon,
      };
      if (isCreate) {
        onAdd(customButton);
      } else {
        onEdit(customButton);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Custom Button' : 'Edit Custom Button'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: customButtonsParam.title,
            rules: [
              {
                required: true,
                message: 'Please input the title!',
              },
            ],
          })(<Input placeholder="Enter Title" />)}
        </Form.Item>
        <Form.Item
          label="Icon"
          extra={
            <>
              <p style={{ margin: 0, padding: 0 }}>Select suitable icon from:</p>
              <a target="_blank" href="https://3x.ant.design/components/icon/">
                https://3x.ant.design/components/icon
              </a>
            </>
          }
        >
          {getFieldDecorator('icon', {
            initialValue: customButtonsParam.icon,
            rules: [
              {
                required: true,
                message: 'Please input the icon!',
              },
            ],
          })(<Input placeholder="Enter Icon Name" />)}
        </Form.Item>
        <Form.Item label="Url">
          {getFieldDecorator('url', {
            initialValue: customButtonsParam.url,
            rules: [
              {
                required: true,
                message: 'Please input an URL!',
              },
            ],
          })(<Input placeholder="Enter URL" />)}
        </Form.Item>
        <Form.Item label="Action">
          {getFieldDecorator('action', {
            initialValue: customButtonsParam.action,
            rules: [
              {
                required: true,
                message: 'Please select action!',
              },
            ],
          })(
            <Select placeholder="Select an option">
              <Option value="iframe">Iframe</Option>
              <Option value="redirect">Redirect</Option>
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditCustomButtonsModal = Form.create<AddEditCustomButtonsModalProps>()(AddEditCustomButtonsModalComponent);
export default AddEditCustomButtonsModal;
