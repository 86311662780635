import React from 'react';
import { Button, Dropdown, Icon, Menu } from 'antd';

const DropdownActions = ({ menuItem }) => {
  const menu = (
    <Menu>
      {menuItem &&
        menuItem.map(item => (
          <Menu.Item key={item.key} onClick={() => item.onClick()}>
            <span>{item.title}</span>
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <Button>
        Actions <Icon type="caret-down" />
      </Button>
    </Dropdown>
  );
};

export default DropdownActions;
