import { gql } from 'apollo-boost';
import { MEMBERSHIP_FRAGMENT } from '../membershipFragment';

export const UPDATE_MEMBERSHIP_MUTATION = gql`
  mutation updateMembership($_id: ID!, $input: MembershipInput!) {
    updateMembership(_id: $_id, input: $input) {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`;
