import { gql } from 'apollo-boost';

export const CHECK_EMAIL_FORGOT_PW_DASHBOARD_MUTATION = gql`
  mutation checkEmailForgotPasswordDashboard($email: String!) {
    checkEmailForgotPasswordDashboard(email: $email) {
      type
      payload
    }
  }
`;
