import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { PUBLISHED_SHARED_VIDEOS_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import { useHistory } from 'react-router-dom';
import EditVideoModal from 'component/EditVideoModal/EditVideoModal';
import VideoPlayerModal from 'component/VideoPlayerModal/VideoPlayerModal';
import VideoLogsModal from 'component/VideoLogsModal/VideoLogsModal';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import { getUserData } from 'helper';
import AltVideoTable from 'component/AltVideoTable/AltVideoTable';
import { NetworkStatus } from 'apollo-client';

const PAGE_LIMIT = 10;

interface VideoUploadProps {}

const VideosContainer: React.FC<VideoUploadProps> = ({}) => {
  const history = useHistory();
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false as any);
  const [videosFilter, setVideosFilter] = useState<any>({});
  const [videosSorter, setVideosSorter] = useState<any>({
    field: 'createdAt',
    order: 'descend',
  });

  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [showVideoLogsModal, setShowVideoLogsModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  // const { loading, error, data } = useQuery(VIDEOS_QUERY);
  const { fetchMore, data, networkStatus } = useQuery(PUBLISHED_SHARED_VIDEOS_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      videosPageFilterInput: videosFilter,
      sorterInput: videosSorter,
      organizationId: selectedOrg == 'all' ? null : selectedOrg,
    },
    onCompleted: data => {
      if (data && data.publishedSharedVideos) {
        setVideos(data.publishedSharedVideos.videos);
        setPaginationData(data.publishedSharedVideos.pagination);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.error('err are', err);
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      // console.log('videos', data);
      setVideos(data.publishedSharedVideos.videos);
      // setPaginationData(data.publishedSharedVideos.pagination);
      // setLoading(false);
    }
  }, [data]);
  useEffect(() => {
    if (networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading) setTableLoading(true);
    if (networkStatus === NetworkStatus.ready || networkStatus === NetworkStatus.error) setTableLoading(false);
  }, [networkStatus]);
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  const onEditVideo = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowEditVideoModal(true);
  };
  const closeEditVideoModal = () => {
    setCurrentItem(null);
    setShowEditVideoModal(false);
  };

  const openVideoPlayerModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoPlayerModal(true);
  };
  const closeVideoPlayerModal = () => {
    setCurrentItem(null);
    setShowVideoPlayerModal(false);
  };

  const closeVideoLogsModal = () => {
    setCurrentItem(null);
    setShowVideoLogsModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let videosPageFilterInput = {};
    if (filters.title && filters.title.length) videosPageFilterInput['search'] = filters.title[0];
    if (filters.trainer && filters.trainer.length) videosPageFilterInput['trainer'] = filters.trainer;
    if (filters.activity && filters.activity.length) videosPageFilterInput['activity'] = filters.activity;
    if (filters.memberships && filters.memberships.length) videosPageFilterInput['memberships'] = filters.memberships;
    if (filters.sharedBy && filters.sharedBy.length) videosPageFilterInput['organization'] = filters.sharedBy;
    if (filters.premiereAt && filters.premiereAt.length)
      videosPageFilterInput['premiereAtRange'] = {
        start: filters.premiereAt[0].startOf('day').toISOString(),
        end: filters.premiereAt[0].endOf('day').toISOString(),
      };
    if (filters.createdAt && filters.createdAt.length)
      videosPageFilterInput['createdAtRange'] = {
        start: filters.createdAt[0].startOf('day').toISOString(),
        end: filters.createdAt[0].endOf('day').toISOString(),
      };
    setVideosFilter(videosPageFilterInput);
    let sorterInput = {};
    sorterInput['field'] = sorter.columnKey;
    sorterInput['order'] = sorter.order ? sorter.order : 'descend';
    setVideosSorter(sorterInput);
    fetch(paginationInput, videosPageFilterInput, sorterInput);
  };

  const fetch = (paginationInput, videosPageFilterInput: any = null, sorterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', videosPageFilterInput);
    // console.log('sortterinput', sorterInput);
    setTableLoading(true);
    fetchMore({
      variables: {
        paginationInput,
        ...(videosPageFilterInput && { videosPageFilterInput: videosPageFilterInput }),
        ...(sorterInput && { sorterInput: sorterInput }),
        organizationId: selectedOrg == 'all' ? null : selectedOrg,
      },
      updateQuery: (prev, { fetchMoreResult }: any) => {
        // console.log('fetchmore results', fetchMoreResult);
        if (!fetchMoreResult) return prev;
        setVideos(fetchMoreResult.publishedSharedVideos.videos);
        setPaginationData(fetchMoreResult.publishedSharedVideos.pagination);
        setTableLoading(false);
      },
    });
  };

  return (
    <>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <AltVideoTable
            onEditVideo={onEditVideo}
            // onDeleteVideo={onDeleteVideo}
            videos={videos}
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            openVideoPlayerModal={openVideoPlayerModal}
            // openEditVideoModal={openEditVideoModal}
            paginationData={paginationData}
            handleTableChange={handleTableChange}
            // openVideoLogsModal={openVideoLogsModal}
            videosSorter={videosSorter}
          />
        </Col>
      </Row>
      <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} />
      <VideoPlayerModal visible={showVideoPlayerModal} video={currentItem} closeModal={closeVideoPlayerModal} />
      <VideoLogsModal visible={showVideoLogsModal} video={currentItem} closeModal={closeVideoLogsModal} />
    </>
  );
};
export default VideosContainer;
