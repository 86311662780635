import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from '../organizationFragment';

export const SAVE_WAIVER_FORM_MUTATION = gql`
  mutation saveWaiverForm($_id: ID!, $input: WaiverFormInput!) {
    saveWaiverForm(_id: $_id, input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
