import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, Select, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';
import { getUserData } from 'helper';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import { CREATE_MEMBERSHIP_MUTATION, UPDATE_MEMBERSHIP_MUTATION } from 'apollo/membership/mutations';

const { Option } = Select;

interface MembershipModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddMembershipModalComponent: React.FC<MembershipModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [createMembership] = useMutation(CREATE_MEMBERSHIP_MUTATION, {
    update(cache, { data: { createMembership } }) {
      const cachedData: any = cache.readQuery({ query: MEMBERSHIPS_QUERY });
      cache.writeQuery({
        query: MEMBERSHIPS_QUERY,
        data: { memberships: [...cachedData.memberships, createMembership] },
      });
    },
    refetchQueries: ['memberships'],
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => setOrganizations(data.organizations),
  });
  const [organizations, setOrganizations] = useState<any>(null);
  const [self, setSelf] = useState(JSON.parse(getUserData()) || null);
  const [updateMembership] = useMutation(UPDATE_MEMBERSHIP_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipParam, setMembershipParam] = useState({
    membershipName: '',
    membershipExternalId: '',
    organizationId: '',
  });

  useEffect(() => {
    if (currentItem) {
      setMembershipParam(prev => ({
        ...prev,
        membershipName: currentItem.membershipName || '',
        membershipExternalId: currentItem.membershipExternalId || '',
        organizationId: currentItem.organizationId,
      }));
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setMembershipParam({
      membershipName: '',
      membershipExternalId: '',
      organizationId: '',
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        input: {
          membershipName: values.membershipName,
          membershipExternalId: values.membershipExternalId,
        },
        ...(self && self.role === 'superadmin' ? (isCreate ? { organizationId: values.organizationId } : {}) : {}),
      };
      if (isCreate) {
        createMembership({
          variables: {
            ...params,
          },
        })
          .then(mutationResult => {
            setIsLoading(false);
            notification.success({
              message: 'Membership Created',
              duration: 0,
              description: 'Membership was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Membership Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateMembership({
          variables: {
            ...params,
            _id: currentItem._id,
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            notification.success({
              message: 'Membership Updated',
              duration: 0,
              description: 'Membership was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Membership Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Membership' : 'Update Membership Detail'}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Membership Name">
          {getFieldDecorator('membershipName', {
            initialValue: membershipParam.membershipName,
            rules: [
              {
                required: true,
                message: 'Please input the name of membership!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Membership Id/Package Id">
          {getFieldDecorator('membershipExternalId', {
            initialValue: membershipParam.membershipExternalId,
            rules: [
              {
                required: true,
                message: 'Please input membership id!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        {self.role === 'superadmin' && isCreate ? (
          <Form.Item label="Organization">
            {getFieldDecorator('organizationId', {
              initialValue: membershipParam.organizationId,
              rules: [
                {
                  required: true,
                  message: 'Please input the organization of membership!',
                },
              ],
            })(
              <Select>
                {organizations &&
                  organizations.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        <Row style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? 'Add' : 'Update'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddMembershipModal = Form.create<MembershipModalProps>()(AddMembershipModalComponent);
export default AddMembershipModal;
