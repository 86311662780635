import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from 'apollo/organization/organizationFragment';

export const USER_FRAGMENT = gql`
  fragment User on User {
    _id
    email
    firstName
    lastName
    role
    avatarUrl
    enabledRoutes
    organizationId
    organization {
      ...Organization
    }
    adHoc
    allowMultipleLogin
    externalId
    externalUser
    waiverFormSign
    waiverFormUrl
    loginAttempt
    loginError
    createdAt
    lastSessionAt
    trainerId
  }
  ${ORGANIZATION_FRAGMENT}
`;
