import React, { useRef, useState } from 'react';
import { Button, Icon, Input, DatePicker, notification, Tag } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { getUserData } from 'helper';
import VideoStatusSelect from 'component/VideoStatusSelect/VideoStatusSelect';
import { START_VIDEO_COMPRESSION_MUTATION, STOP_LIVE_STREAM_MUTATION } from 'apollo/video/mutations';
import DropdownActions from 'component/DropdownActions/DropdownActions';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';

const PAGE_LIMIT = 10;

const VideoTable = ({
  tableLoading,
  setTableLoading,
  openVideoPlayerModal,
  openEditVideoModal,
  openCommentsModal,
  onEditVideo,
  openVideoLogsModal,
  onDeleteVideo,
  videos,
  paginationData,
  handleTableChange,
  videosSorter,
}) => {
  const loggedInUser = JSON.parse(getUserData());
  const titleSearhInputRef = useRef<any>();
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [memberships, setMemberships] = useState<any>(null);
  const [startVideoCompression] = useMutation(START_VIDEO_COMPRESSION_MUTATION);
  const [stopLiveStreamMutation] = useMutation(STOP_LIVE_STREAM_MUTATION);
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
      // console.log('data.activities', data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => setTrainers(data.trainers),
  });
  useQuery(MEMBERSHIPS_QUERY, {
    onCompleted: data => setMemberships(data.memberships),
  });

  /** video title search  */
  const getVideoTitleSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={titleSearhInputRef}
          placeholder={`Search Title`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleTitleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => titleSearhInputRef.current.select());
      }
    },
  });
  const handleTitleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleTitleReset = clearFilters => {
    clearFilters();
  };

  /** Date filter */
  const getDateFilterProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          placeholder={`Filter Date`}
          onChange={val => setSelectedKeys(val ? [val] : [])}
          format="DD-MMM"
        />
        <Button
          type="primary"
          onClick={() => handleDateFilter(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleDateReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? '#1890ff' : undefined }} />,
  });
  const handleDateFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleDateReset = clearFilters => {
    clearFilters();
  };

  const startCompression = record => {
    startVideoCompression({
      variables: {
        _id: record._id,
      },
    })
      .then(res => {
        if (res.data.startVideoCompression) {
          notification.success({
            message: 'Compression Started',
            duration: 0,
            description: 'Video compression started in background.',
          });
        }
      })
      .catch(e => {
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Compression Start Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  const fixLiveVideo = record => {
    setTableLoading(true);
    stopLiveStreamMutation({
      variables: {
        _id: record._id,
      },
    })
      .then(res => {
        if (res.data.stopLiveStream) {
          setTableLoading(false);
          notification.success({
            message: 'Live Video Fixed',
            duration: 0,
            description: 'Live Video Fixed.',
          });
        }
      })
      .catch(e => {
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        setTableLoading(false);
        notification.error({
          message: 'Fix Video Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  const downloadVideo = record => {
    fetch(record.url).then(function (t) {
      return t.blob().then(b => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', `${record.title}.mp4`);
        a.click();
      });
    });
  };

  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={
                record.thumbnail ||
                (record.organization && record.organization.settings && record.organization.settings.fallbackThumbnail) ||
                `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization && record.organization.name}`
              }
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization && record.organization.name}`;
              }}
              style={{
                background: '#fff',
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
      filters:
        activities &&
        activities.map(item => ({
          text: item.name,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
      filters:
        trainers &&
        trainers.map(item => ({
          text: item.name,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }} onClick={() => openVideoPlayerModal(record)}>
          {record.title}
        </Button>
      ),
      ...getVideoTitleSearchProps(),
    },
    {
      key: 'memberships',
      title: 'Memberships',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.memberships.map(item => (
            <Tag key={item._id}>{`${item.membershipName} (${item.membershipExternalId})`}</Tag>
          ))}
        </div>
      ),
      filters:
        memberships &&
        memberships.map(item => ({
          text: item.membershipName,
          value: item._id,
        })),
      filterMultiple: true,
    },
    ...(loggedInUser.role === 'superadmin'
      ? [
          {
            key: 'organizationName',
            title: 'Org Name',
            render: record => <span>{record.organization && record.organization.name}</span>,
          },
        ]
      : [{}]),
    {
      key: 'compressionStatus',
      title: 'Compression',
      render: record => (record.compressionStatus ? record.compressionStatus : 'none'),
    },
    {
      key: 'premiereAt',
      title: 'Premiere At',
      render: record => (record.premiereAt ? moment(record.premiereAt).format('Do MMMM, hh:mm a') : 'No Premiere'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: videosSorter.field == 'premiereAt' ? videosSorter.order : false,
      sorter: true,
      ...getDateFilterProps('premiereAt'),
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: videosSorter.field == 'createdAt' ? videosSorter.order : false,
      sorter: true,
      ...getDateFilterProps('createdAt'),
    },
    {
      key: 'status',
      title: 'Status',
      render: record => <VideoStatusSelect video={record} setTableLoading={setTableLoading} />,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <DropdownActions
          menuItem={[
            ...(record.isLive
              ? [
                  {
                    key: 'fixLiveVideo',
                    title: 'Fix Live Video',
                    onClick: () => fixLiveVideo(record),
                  },
                ]
              : []),
            {
              key: 'edit',
              title: 'Edit',
              onClick: () => onEditVideo(record),
            },
            {
              key: 'logs',
              title: 'View Logs',
              onClick: () => openVideoLogsModal(record),
            },
            {
              key: 'comments',
              title: 'Show Comments',
              onClick: () => openCommentsModal(record),
            },
            {
              key: 'download',
              title: 'Download',
              onClick: () => downloadVideo(record),
            },
            ...(loggedInUser.role === 'superadmin' &&
            record.compressionStatus == 'none' &&
            record.url.match(/techdurbar-videolibrary.s3.ca-central-1.amazonaws.com\/raw/) &&
            record.size !== 0
              ? [
                  {
                    key: 'compression',
                    title: 'Start Compression',
                    onClick: () => startCompression(record),
                  },
                ]
              : []),
            {
              key: 'delete',
              title: 'Delete',
              onClick: () => onDeleteVideo(record._id),
            },
          ]}
        />
        // <>
        //   <span style={{ marginRight: 10 }}>
        //     <Button type="primary" onClick={() => onEditVideo(record)}>
        //       Edit
        //     </Button>
        //   </span>
        //   <span style={{ marginRight: 10 }}>
        //     <Button onClick={() => openVideoLogsModal(record)} type="primary">
        //       View Logs
        //     </Button>
        //   </span>
        //   <span style={{ marginRight: 10 }}>
        //     <Button onClick={() => openCommentsModal(record)}>Show Comments</Button>
        //   </span>
        //   {loggedInUser.role === 'superadmin' && record.compressionStatus != 'final' && (
        //     <span style={{ marginRight: 10 }}>
        //       <Button onClick={() => startCompression(record)}>Start Compression</Button>
        //     </span>
        //   )}
        //   <span>
        //     <Popconfirm title="Are you sure delete this video?" onConfirm={() => onDeleteVideo(record._id)} okText="Yes" cancelText="No">
        //       <Button type="danger">Delete</Button>
        //     </Popconfirm>
        //   </span>
        // </>
      ),
    },
  ];

  return (
    <AntdTable
      loading={tableLoading}
      rowKey={record => record._id}
      columns={columns}
      onChange={handleTableChange}
      dataSource={videos}
      scroll={{ x: true }}
      pagination={{
        position: 'bottom',
        pageSize: PAGE_LIMIT,
        total: paginationData.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        // onChange: handlePageChange,
      }}
    />
  );
};

export default VideoTable;
