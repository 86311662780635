import { Button, Popconfirm } from 'antd';
import DropdownActions from 'component/DropdownActions/DropdownActions';
import AntdTable from 'component/elements';
import VideoStatusSelect from 'component/VideoStatusSelect/VideoStatusSelect';
import { getUserData } from 'helper';
import moment from 'moment';
import React from 'react';

const SharedVideosTable = ({ onEditVideo, onDeleteVideo, videos, tableLoading, setTableLoading, openVideoPlayerModal, openVideoLogsModal }) => {
  const loggedInUser = JSON.parse(getUserData());

  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={
                record.thumbnail ||
                (record.organization && record.organization.settings && record.organization.settings.fallbackThumbnail) ||
                `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization.name}`
              }
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization.name}`;
              }}
              style={{
                background: '#fff',
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }} onClick={() => openVideoPlayerModal(record)}>
          {record.title}
        </Button>
      ),
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
    },
    {
      key: 'sharedBy',
      title: 'Shared By',
      render: record => <span>{record.parentOrganization && record.parentOrganization.name}</span>,
    },
    ...(loggedInUser.role === 'superadmin'
      ? [
          {
            key: 'sharedTo',
            title: 'Shared To',
            render: record => <span>{record.organization && record.organization.name}</span>,
          },
        ]
      : [{}]),
    {
      key: 'premiereAt',
      title: 'Premiere At',
      render: record => (record.premiereAt ? moment(record.premiereAt).format('Do MMMM, hh:mm a') : 'No Premiere'),
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
    },
    {
      key: 'status',
      title: 'Status',
      render: record => <VideoStatusSelect video={record} setTableLoading={setTableLoading} />,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <DropdownActions
          menuItem={[
            {
              key: 'edit',
              title: 'Edit',
              onClick: () => onEditVideo(record),
            },
            {
              key: 'logs',
              title: 'View Logs',
              onClick: () => openVideoLogsModal(record),
            },
            // {
            //   key: 'comments',
            //   title: 'Show Comments',
            //   onClick: () => openCommentsModal(record),
            // },
            // ...(loggedInUser.role === 'superadmin' && record.compressionStatus != 'final'
            //   ? [
            //       {
            //         key: 'compression',
            //         title: 'Start Compression',
            //         onClick: () => startCompression(record),
            //       },
            //     ]
            //   : []),
            {
              key: 'delete',
              title: 'Delete',
              onClick: () => onDeleteVideo(record._id),
            },
          ]}
        />
        // <>
        //   <span style={{ marginRight: 10 }}>
        //     <Button type="primary" onClick={() => onEditVideo(record)}>
        //       Edit
        //     </Button>
        //   </span>
        //   <span style={{ marginRight: 10 }}>
        //     <Button onClick={() => openVideoLogsModal(record)} type="primary">
        //       View Logs
        //     </Button>
        //   </span>
        //   <span>
        //     <Popconfirm title="Are you sure delete this video?" onConfirm={() => onDeleteVideo(record._id)} okText="Yes" cancelText="No">
        //       <Button type="danger">Delete</Button>
        //     </Popconfirm>
        //   </span>
        // </>
      ),
    },
  ];

  return <AntdTable loading={tableLoading} rowKey={record => record._id} columns={columns} dataSource={videos} scroll={{ x: true }} />;
};

export default SharedVideosTable;
