import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, DatePicker, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

interface AddEditFreeAccessDateModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditFreeAccessDateModalComponent: React.FC<AddEditFreeAccessDateModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [freeAccessDateParam, setFreeAccessDateParam] = useState<any>({
    start: undefined,
    end: undefined,
  });

  useEffect(() => {
    if (currentItem) {
      setFreeAccessDateParam({
        ...freeAccessDateParam,
        start: moment(currentItem.start),
        end: moment(currentItem.end),
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setFreeAccessDateParam({
      start: undefined,
      end: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      if (values.start > values.end) {
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greater than end date.',
        });
        return;
      }
      const freeAccessDate = {
        start: values.start.startOf('day').toISOString(),
        end: values.end.endOf('day').toISOString(),
      };
      if (isCreate) {
        onAdd(freeAccessDate);
      } else {
        onEdit(freeAccessDate);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Free Access Date' : 'Edit Free Access Date'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form>
        <Form.Item label="Start">
          {getFieldDecorator('start', {
            initialValue: freeAccessDateParam.start,
            rules: [
              {
                required: true,
                message: 'Please input start date!',
              },
            ],
          })(<DatePicker placeholder="Enter Start Date" />)}
        </Form.Item>
        <Form.Item label="End">
          {getFieldDecorator('end', {
            initialValue: freeAccessDateParam.end,
            rules: [
              {
                required: true,
                message: 'Please input end date!',
              },
            ],
          })(<DatePicker placeholder="Enter End Date" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditFreeAccessDateModal = Form.create<AddEditFreeAccessDateModalProps>()(AddEditFreeAccessDateModalComponent);
export default AddEditFreeAccessDateModal;
