import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Icon, Input, message, notification, Switch, Upload, Select, Card } from 'antd';
import CustomButtonsTable from 'component/CustomButtonsTable/CustomButtonsTable';
import { ORGANIZATION_SETTINGS_UPDATE_MUTATION } from 'apollo/organization/mutations';
import LabelwTooltip from 'component/LabelwTooltip/LabelwTooltip';
import Api from 'axios/Api';
import ModerationTable from 'component/ModerationTable/ModerationTable';
import WantAccessTable from 'component/WantAccessTable/WantAccessTable';
import RulesTable from 'component/RulesTable/RulesTable';
import { useHistory } from 'react-router-dom';
import CustomBubblesTable from 'component/CustomBubblesTable copy/CustomBubblesTable';
import AnnouncementsTable from 'component/AnnouncementsTable/AnnouncementsTable';
import FreeAccessDatesTable from 'component/FreeAccessDatesTable/FreeAccessDatesTable';

const { Option } = Select;

const OrgOtherSettings = ({ organization }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fallbackThumbnail, setFallbackThumbnail] = useState<any>([]);
  const [updateOrgSettings] = useMutation(ORGANIZATION_SETTINGS_UPDATE_MUTATION, { refetchQueries: ['organization'] });
  const [settingsParam, setSettingsParam] = useState<any>({
    sharedVideoModerations: [],
    announcements: [],
    customButtons: [],
    shareAllVideos: false,
    fallbackThumbnail: undefined,
    wantAccesses: [],
    sharingRules: [],
    customBubbles: [],
    showAllSharedVideos: false,
    forgotPasswordUrl: undefined,
    forgotPasswordAction: undefined,
    commentEmail: undefined,
    whitelistResourceId: [],
    freeAccessDates: [],
    allowGuestLogin: false,
  });

  useEffect(() => {
    if (organization) {
      setSettingsParam(prev => ({
        ...prev,
        fallbackThumbnail: organization.settings && organization.settings.fallbackThumbnail,
        sharedVideoModerations: (organization.settings && organization.settings.sharedVideoModerations) || [],
        announcements: (organization.settings && organization.settings.announcements) || [],
        customButtons: (organization.settings && organization.settings.customButtons) || [],
        shareAllVideos: (organization.settings && organization.settings.shareAllVideos) || false,
        wantAccesses: (organization.settings && organization.settings.wantAccesses) || [],
        sharingRules: (organization.settings && organization.settings.sharingRules) || [],
        showAllSharedVideos: (organization.settings && organization.settings.showAllSharedVideos) || false,
        forgotPasswordUrl: (organization.settings && organization.settings.forgotPasswordUrl?.url) || undefined,
        forgotPasswordAction: (organization.settings && organization.settings.forgotPasswordUrl?.action) || undefined,
        customBubbles: (organization.settings && organization.settings.customBubbles) || [],
        commentEmail: (organization.settings && organization.settings.commentEmail) || undefined,
        whitelistResourceId: (organization.settings && organization.settings.whitelistResourceId) || [],
        freeAccessDates: (organization.settings && organization.settings.freeAccessDates) || [],
        allowGuestLogin: (organization.settings && organization.settings.allowGuestLogin) || false,
      }));
    }
  }, [organization]);

  const saveSettings = async () => {
    try {
      setIsLoading(true);
      let url = null;
      if (fallbackThumbnail.length > 0) {
        // console.log(fallbackThumbnail);
        const res = await Api.uploadFile(fallbackThumbnail[0]);
        // console.log('data', res);
        const { data } = res;
        if (!data.success) {
          setIsLoading(false);
          const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Organization Update Failed',
            duration: 0,
            description: errorMessage,
          });
          return;
        }
        url = data.result.url;
      }
      const tempCustomBubbles = settingsParam.customBubbles.map(item => {
        return {
          bubbleType: item.bubbleType,
          ...(item.bubbleType === 'tag'
            ? { tag: item.tag }
            : {
                modelType: item.modelType,
                bubbleContent: item.bubbleContent?._id,
              }),
        };
      });
      const tempShareVideoModerations = settingsParam.sharedVideoModerations.map(item => {
        return {
          from: item.from._id,
          by: item.by,
          shareByActivity: item.shareByActivity?._id || null,
          shareByTrainer: item.shareByTrainer?._id || null,
        };
      });
      const tempSharingRules = settingsParam.sharingRules.map(item => {
        return {
          by: item.by,
          shareByActivity: item.shareByActivity?._id || null,
          shareByTrainer: item.shareByTrainer?._id || null,
        };
      });
      const input = {
        fallbackThumbnail: url || (organization && organization.settings && organization.settings.fallbackThumbnail) || null,
        sharedVideoModerations: tempShareVideoModerations || [],
        announcements: settingsParam.announcements || [],
        customButtons: settingsParam.customButtons || [],
        shareAllVideos: settingsParam.shareAllVideos,
        wantAccesses: settingsParam.wantAccesses,
        sharingRules: tempSharingRules || [],
        customBubbles: tempCustomBubbles || [],
        showAllSharedVideos: settingsParam.showAllSharedVideos || false,
        forgotPasswordUrl: { action: settingsParam.forgotPasswordAction, url: settingsParam.forgotPasswordUrl } || null,
        commentEmail: settingsParam.commentEmail || null,
        // whitelistResourceId: settingsParam.whitelistResourceId || [],
        freeAccessDates: settingsParam.freeAccessDates || [],
        allowGuestLogin: settingsParam.allowGuestLogin || false,
      };
      // console.log('inpu', input);
      updateOrgSettings({
        variables: {
          _id: organization._id,
          input: input,
        },
      })
        .then(mutationResult => {
          // console.log('updated', mutationResult.data.organizationUpdate);
          setIsLoading(false);
          message.success('Updated Succesfully.');
        })
        .catch(e => {
          setIsLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
        });
    } catch (e) {
      setIsLoading(false);
      const errorMessage = e.toString().replace('Error: GraphQL error:', '');
      notification.error({
        message: 'Organization Update Failed',
        duration: 0,
        description: errorMessage,
      });
      console.error('error in uploading thumnail fallback', e);
    }
  };

  const onShareAllChange = val => {
    setSettingsParam(prev => ({ ...prev, shareAllVideos: val }));
  };
  const onAllowGuestLoginChange = val => {
    setSettingsParam(prev => ({ ...prev, allowGuestLogin: val }));
  };
  const onShowAllChange = val => {
    setSettingsParam(prev => ({ ...prev, showAllSharedVideos: val }));
  };
  const onCommentEmailChange = val => {
    setSettingsParam(prev => ({ ...prev, commentEmail: val }));
  };
  const onWhitelistResourceIdChange = val => {
    setSettingsParam(prev => ({ ...prev, whitelistResourceId: val }));
  };
  const onForgotPwUrlChange = val => {
    setSettingsParam(prev => ({ ...prev, forgotPasswordUrl: val }));
  };

  const onAddModeration = moderation => {
    setSettingsParam(prev => ({ ...prev, sharedVideoModerations: [...prev.sharedVideoModerations, moderation] }));
  };
  const onEditModeration = (moderation, index) => {
    const temp = [...settingsParam.sharedVideoModerations];
    temp[index] = moderation;
    setSettingsParam(prev => ({ ...prev, sharedVideoModerations: [...temp] }));
  };
  const onDeleteModeration = (moderation, index) => {
    const temp = [...settingsParam.sharedVideoModerations];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, sharedVideoModerations: [...temp] }));
  };

  const onAddCustomButton = customButton => {
    setSettingsParam(prev => ({ ...prev, customButtons: [...prev.customButtons, customButton] }));
  };
  const onEditCustomButton = (customButton, index) => {
    const temp = [...settingsParam.customButtons];
    temp[index] = customButton;
    setSettingsParam(prev => ({ ...prev, customButtons: [...temp] }));
  };
  const onDeleteCustomButton = (customButton, index) => {
    const temp = [...settingsParam.customButtons];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, customButtons: [...temp] }));
  };

  const onAddWantAccess = wantAccess => {
    setSettingsParam(prev => ({ ...prev, wantAccesses: [...prev.wantAccesses, wantAccess] }));
  };
  const onEditWantAccess = (wantAccess, index) => {
    const temp = [...settingsParam.wantAccesses];
    temp[index] = wantAccess;
    setSettingsParam(prev => ({ ...prev, wantAccesses: [...temp] }));
  };
  const onDeleteWantAccess = (wantAccess, index) => {
    const temp = [...settingsParam.wantAccesses];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, wantAccesses: [...temp] }));
  };

  const onAddCustomBubble = customBubble => {
    setSettingsParam(prev => ({ ...prev, customBubbles: [...prev.customBubbles, customBubble] }));
  };
  const onEditCustomBubble = (customBubble, index) => {
    const temp = [...settingsParam.customBubbles];
    temp[index] = customBubble;
    setSettingsParam(prev => ({ ...prev, customBubbles: [...temp] }));
  };
  const onDeleteCustomBubble = (customBubbles, index) => {
    const temp = [...settingsParam.customBubbles];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, customBubbles: [...temp] }));
  };

  const onAddAnnouncements = announcement => {
    setSettingsParam(prev => ({ ...prev, announcements: [...prev.announcements, announcement] }));
  };
  const onEditAnnouncements = (announcement, index) => {
    const temp = [...settingsParam.announcements];
    temp[index] = announcement;
    setSettingsParam(prev => ({ ...prev, announcements: [...temp] }));
  };
  const onDeleteAnnouncements = (announcement, index) => {
    const temp = [...settingsParam.announcements];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, announcements: [...temp] }));
  };

  const onAddFreeAccessDate = freeAccessDate => {
    setSettingsParam(prev => ({ ...prev, freeAccessDates: [...prev.freeAccessDates, freeAccessDate] }));
  };
  const onEditFreeAccessDate = (freeAccessDate, index) => {
    const temp = [...settingsParam.freeAccessDates];
    temp[index] = freeAccessDate;
    setSettingsParam(prev => ({ ...prev, freeAccessDates: [...temp] }));
  };
  const onDeleteFreeAccessDate = (freeAccessDate, index) => {
    const temp = [...settingsParam.freeAccessDates];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, freeAccessDates: [...temp] }));
  };

  const onAddRule = rule => {
    setSettingsParam(prev => ({ ...prev, sharingRules: [...prev.sharingRules, rule] }));
  };
  const onEditRule = (rule, index) => {
    const temp = [...settingsParam.sharingRules];
    temp[index] = rule;
    setSettingsParam(prev => ({ ...prev, sharingRules: [...temp] }));
  };
  const onDeleteRule = (rule, index) => {
    const temp = [...settingsParam.sharingRules];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, sharingRules: [...temp] }));
  };

  const beforeUpload = file => {
    // console.log('file', file);
    setFallbackThumbnail(prev => [file]);
    getBase64(file, imageUrl => {
      setSettingsParam(prev => ({ ...prev, fallbackThumbnail: imageUrl }));
    });
    return false;
  };

  const onRemoveFile = file => {
    // console.log('here', file);
    setFallbackThumbnail(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setSettingsParam(prev => ({
      ...prev,
      fallbackThumbnail: (organization && organization.settings && organization.settings.fallbackThumbnail) || undefined,
    }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div>
      <h4>Settings</h4>
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Allow Guest Login" tooltipText="Enable this to let anyone login as a guest user." />}
      >
        <label style={{ fontSize: '14px', marginRight: 5 }}>Allow Guest Login:</label>
        <Switch style={{ marginLeft: 10 }} checked={settingsParam.allowGuestLogin || false} onChange={onAllowGuestLoginChange} />
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Notification Email" tooltipText="Add an email for which to receive notification." />}
      >
        <Input value={settingsParam.commentEmail} onChange={e => onCommentEmailChange(e.target.value)} placeholder="Enter an email" />
      </Card>
      {/* <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip label="White List Resource IDs" tooltipText="Add Ids for the packages/services that will get access to video platform." />
        }
      >
        <Select
          mode="tags"
          style={{ width: '100%' }}
          value={settingsParam.whitelistResourceId}
          onChange={val => onWhitelistResourceIdChange(val)}
          placeholder="Enter an whitelist resource id"
        />
      </Card> */}
      <Card style={{ marginBottom: '24px' }} title={<LabelwTooltip label="Waiver Form" tooltipText="From here you can edit your wave form." />}>
        <Button onClick={() => history.push('/waiver')}>Edit Waiver Form</Button>
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Fallback Thumbnail"
            tooltipText="Fallback thumbnail will be used when any of your video does not have a thumbnail or when there is a problem loading thumbnail."
          />
        }
      >
        {!!settingsParam.fallbackThumbnail && (
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={settingsParam.fallbackThumbnail}
              style={{
                background: '#fff',
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </div>
        )}
        <div>
          <Upload accept="image/*" fileList={fallbackThumbnail} beforeUpload={beforeUpload} onRemove={onRemoveFile}>
            <Button>
              <Icon type="upload" /> Upload
            </Button>
          </Upload>
        </div>
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Forgot Password Url" tooltipText="Enter url to redirect your users to your change password page." />}
      >
        <div style={{ marginBottom: 10 }}>
          <label style={{ fontSize: '14px', marginRight: 5 }}>Action</label>
          <div>
            <Select
              placeholder="Select an option"
              value={settingsParam.forgotPasswordAction}
              onChange={val => setSettingsParam(prev => ({ ...prev, forgotPasswordAction: val }))}
              style={{ width: '100%' }}
            >
              <Option value="iframe">Iframe</Option>
              <Option value="redirect">Redirect</Option>
            </Select>
          </div>
        </div>
        <div>
          <label style={{ fontSize: '14px', marginRight: 5 }}>Url</label>
          <Input
            value={settingsParam.forgotPasswordUrl}
            placeholder="Enter forgot password url"
            onChange={e => onForgotPwUrlChange(e.target.value)}
            style={{ marginTop: 5 }}
          />
        </div>
      </Card>
      {/* <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Announcement"
            tooltipText="Enter any information/announcement you want to give to the subscriber on the video platform. This will be displayed on top of the application."
          />
        }
      >
        <Input.TextArea
          value={settingsParam.announcement}
          placeholder="Enter announcement"
          onChange={e => onAnnouncementChange(e.target.value)}
          style={{ marginTop: 5 }}
        />
      </Card> */}
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Sharing Settings" tooltipText="Settings for sharing your videos to other locations." />}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LabelwTooltip
            label="Share All Videos:"
            tooltipText="Enable this to share your new videos immediately after creation. When checked there is no need to set up sharing rules as all videos you upload will be shared with other locations."
          />
          <Switch style={{ marginLeft: 10 }} checked={settingsParam.shareAllVideos || false} onChange={onShareAllChange} />
        </div>
        {!settingsParam.shareAllVideos && (
          <div style={{ marginTop: '15px' }}>
            <div style={{ marginBottom: 5 }}>
              <LabelwTooltip
                label="Sharing Rules"
                tooltipText="Set your sharing rules in order to share only specific videos with respect to trainer or activity."
              />
            </div>
            <RulesTable
              organization={organization}
              rules={settingsParam.sharingRules}
              onAdd={onAddRule}
              onDelete={onDeleteRule}
              onEdit={onEditRule}
            />
          </div>
        )}
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Receiving Settings" tooltipText="Settings to publish or not to publish videos shared by other location." />}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LabelwTooltip
            label="Show All Shared Videos:"
            tooltipText="Enable this to show all the received videos from other location on your portal. When checked there is no need to set up Receiving rules as all videos from all locations will automatically be shown in your portal."
          />
          <Switch style={{ marginLeft: 10 }} checked={settingsParam.showAllSharedVideos || false} onChange={onShowAllChange} />
        </div>
        {!settingsParam.showAllSharedVideos && (
          <div style={{ marginTop: '15px' }}>
            <div style={{ marginBottom: 5 }}>
              <LabelwTooltip
                label="Receiving Rules"
                tooltipText="Set your moderation rules in order to filter only the shared videos you want on your platform."
              />
            </div>
            <ModerationTable
              organization={organization}
              moderations={settingsParam.sharedVideoModerations}
              onAdd={onAddModeration}
              onDelete={onDeleteModeration}
              onEdit={onEditModeration}
            />
          </div>
        )}
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={<LabelwTooltip label="Announcements" tooltipText="You can add announcements to show in video platdorm." />}
      >
        <AnnouncementsTable
          announcements={settingsParam.announcements}
          onAdd={onAddAnnouncements}
          onDelete={onDeleteAnnouncements}
          onEdit={onEditAnnouncements}
        />
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Custom Buttons"
            tooltipText="You can add custom buttons that you want to be displayed on video platform header. Using this you can display other URL from your own website as an modal iframe or directly redirect to that url. You must add a title, url, icon and action(iframe/redirect).
            Select suitable icon from:
            https://3x.ant.design/components/icon"
          />
        }
      >
        <CustomButtonsTable
          customButtons={settingsParam.customButtons}
          onAdd={onAddCustomButton}
          onDelete={onDeleteCustomButton}
          onEdit={onEditCustomButton}
        />
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Custom Bubbles"
            tooltipText="You can add custom bubbles that you want to be displayed on video platform below header. Using this you can add filters shortcuts for trainer, location and activity."
          />
        }
      >
        <CustomBubblesTable
          customBubbles={settingsParam.customBubbles}
          onAdd={onAddCustomBubble}
          onDelete={onDeleteCustomBubble}
          onEdit={onEditCustomBubble}
        />
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Want Access Buttons"
            tooltipText="You can add custom buttons that you want to be displayed on video platform want access page. Using this you can redirect your users to your package pages."
          />
        }
      >
        <WantAccessTable wantAccesses={settingsParam.wantAccesses} onAdd={onAddWantAccess} onDelete={onDeleteWantAccess} onEdit={onEditWantAccess} />
      </Card>
      <Card
        style={{ marginBottom: '24px' }}
        title={
          <LabelwTooltip
            label="Free Access Dates"
            tooltipText="You can add start date and end date to let your subscribers have free access to your video platform."
          />
        }
      >
        <FreeAccessDatesTable
          freeAccessDates={settingsParam.freeAccessDates}
          onAdd={onAddFreeAccessDate}
          onDelete={onDeleteFreeAccessDate}
          onEdit={onEditFreeAccessDate}
        />
      </Card>
      <div style={{ marginBottom: '24px', textAlign: 'right' }}>
        <Button type="primary" onClick={saveSettings} loading={isLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default OrgOtherSettings;
