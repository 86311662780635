import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Upload, Button, Icon, Modal, Form, Input, message, Switch, Select, Radio, DatePicker, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { UPDATE_VIDEO_MUTATION } from 'apollo/video/mutations/updateVideo';
import TextArea from 'antd/lib/input/TextArea';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import LoadingSpinner from 'component/Loader';
import moment from 'moment';
import Api from 'axios/Api';
import styled from 'styled-components';
import UploadProgressModal from 'component/UploadProgressModal/UploadProgressModal';
import { GENERATE_PRE_SIGNED_URL_MUTATION } from 'apollo/s3/mutations';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';

interface VideoModalProps extends FormComponentProps {
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}
const { Option } = Select;
const { Dragger } = Upload;

const equipments = [
  'No Equipment',
  'Balance Board',
  'Barbell',
  'Bench',
  'Bosu',
  'Box/Step',
  'Chair',
  'Dumbbells',
  'Elastic Bands',
  'Exercise Ball',
  'Foam Roller',
  'Kettle Bell',
  'Medicine Ball',
  'Resistence Band',
  'Rope',
  'Speed Ladder',
  'Stick',
  'Tennis Ball',
];
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  &:hover {
    background: #e6f7ff;
  }
`;

const EditVideoModalComponent: React.FC<VideoModalProps> = ({ form, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [updateVideo] = useMutation(UPDATE_VIDEO_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [memberships, setMemberships] = useState<null | Array<Object>>(null);
  const [thumbnail, setThumbnail] = useState<any>([]);
  const [caption, setCaption] = useState<any>([]);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [filelists, setFilelists] = useState<any>([]);
  const [changeVideo, setChangeVideo] = useState(false);
  const [videoParam, setVideoParam] = useState({
    title: undefined,
    description: undefined,
    isLive: false,
    activity: { key: undefined, label: undefined },
    trainer: { key: undefined, label: undefined },
    intensity: '',
    equipments: [],
    tags: [],
    premium: false,
    premiere: false,
    status: 'published',
    allowComments: false,
    premiereAt: null as any,
    thumbnail: undefined,
    captionUrl: undefined,
    shared: false,
    memberships: [],
    membershipRestriction: false,
  });
  const [generatePresignedUrl] = useMutation(GENERATE_PRE_SIGNED_URL_MUTATION);

  useEffect(() => {
    if (currentItem) {
      console.log(currentItem);
      setVideoParam(prev => ({
        ...prev,
        title: currentItem.title,
        description: currentItem.description,
        activity: { key: currentItem.activity._id, label: currentItem.activity.name },
        intensity: currentItem.intensity,
        equipments: currentItem.equipments,
        tags: currentItem.tags,
        trainer: { key: currentItem.trainer._id, label: currentItem.trainer.name },
        premiere: currentItem.premiere,
        premiereAt: currentItem.premiereAt ? moment(currentItem.premiereAt) : null,
        allowComments: currentItem.allowComments,
        thumbnail: currentItem.thumbnail,
        status: currentItem.status || 'published',
        captionUrl: currentItem.captionUrl,
        shared: currentItem.shared || false,
        memberships: currentItem.memberships.map(item => item._id) || [],
        membershipRestriction: currentItem.membershipRestriction || false,
      }));
    }
  }, [currentItem]);

  useQuery(TRAINERS_QUERY, {
    variables: { organizationId: (currentItem && currentItem.organizationId) || null },
    onCompleted: data => {
      // console.log('trainers', data.trainers);
      setTrainers(data.trainers);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    variables: { organizationId: (currentItem && currentItem.organizationId) || null },
    onCompleted: data => {
      // console.log('activities', data);
      setActivities(data.activities);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(MEMBERSHIPS_QUERY, {
    variables: { organizationId: (currentItem && currentItem.organizationId) || null },
    onCompleted: data => {
      setMemberships(data.memberships);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setCaption([]);
    setChangeVideo(false);
    setFilelists([]);
    setThumbnail([]);
    setVideoParam({
      title: undefined,
      description: undefined,
      isLive: false,
      activity: { key: undefined, label: undefined },
      trainer: { key: undefined, label: undefined },
      intensity: '',
      equipments: [],
      tags: [],
      premium: false,
      premiere: false,
      status: 'published',
      allowComments: false,
      premiereAt: null as any,
      thumbnail: undefined,
      captionUrl: undefined,
      shared: false,
      memberships: [],
      membershipRestriction: false,
    });
  };
  const renderEquipments = equipments.map(item => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));
  const onMembershipRestrictionChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, membershipRestriction: val }));
  };
  const onAllowCommentsChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, allowComments: val }));
  };
  const onIntensityChange = val => {
    // console.log('intensity', val);
    setVideoParam(prev => ({ ...prev, intensity: val }));
  };
  const handleEquipmentChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, equipments: val }));
  };
  const handleStatusChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, status: val }));
  };
  const onPremiereChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, premiere: val }));
  };
  const onSharedChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, shared: val }));
  };
  const onDateChange = val => {
    setVideoParam(prev => ({ ...prev, premiereAt: val }));
  };

  const beforeUploadThumbnail = file => {
    // console.log('file', file);
    setThumbnail(prev => [file]);
    getBase64(file, imageUrl => {
      setVideoParam(prev => ({ ...prev, thumbnail: imageUrl }));
    });
    return false;
  };

  const onRemoveThumbnail = file => {
    // console.log('here', file);
    setThumbnail(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, thumbnail: (currentItem && currentItem.thumbnail) || undefined }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUploadCaption = file => {
    // console.log('file', file);
    setCaption(prev => [file]);
    setVideoParam(prev => ({ ...prev, captionUrl: undefined }));
    return false;
  };

  const onRemoveCaption = file => {
    // console.log('here', file);
    setCaption(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setVideoParam(prev => ({ ...prev, captionUrl: undefined }));
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };
  const beforeUpload = file => {
    setFilelists(prev => [...prev, file]);
    return false;
  };

  const onRemoveFile = file => {
    // console.log('here', file);
    setFilelists(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
  };
  const onUploadProgress = progressEvent => {
    // console.log('prog event', progressEvent);
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // console.log(percentCompleted);
    setProgressPercent(percentCompleted);
  };
  const uploadVideo = ({ input }) => {
    console.log('input', input);
    updateVideo({
      variables: {
        _id: currentItem._id,
        input: { ...input },
      },
    })
      .then(result => {
        // console.log('result', result);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.success({
          key: 'uploadingNotification',
          message: 'Video Update Success',
          description: 'Video was updated successfully.',
          duration: 0,
        });
        resetForm();
        handleOk();
      })
      .catch(err => {
        console.log('err', err);
        setProgressPercent(0);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Update Failed',
          description: 'Video was not updated',
          duration: 0,
        });
      });
  };
  const uploadToS3 = (url, payload) => {
    setShowProgressModal(true);
    console.log('upload to s3');
    Api.uploadVideo(url, filelists[0], onUploadProgress)
      .then(data => {
        // console.log('upload done', data);
        setProgressPercent(100);
        uploadVideo(payload);
        // if (data.data.success) {
        //   setProgressPercent(100);
        //   uploadVideo(input);
        // } else {
        //   setIsLoading(false);
        //   setShowProgressModal(false);
        //   setProgressPercent(0);
        //   notification.error({
        //     key: 'uploadingNotification',
        //     message: 'Video Upload Failed',
        //     description: err.toString().replace('Error: GraphQL error:', ''),
        //     duration: 0,
        //   });
        // }
      })
      .catch(err => {
        console.error('upload err', err);
        setIsLoading(false);
        setShowProgressModal(false);
        setProgressPercent(0);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Update Failed',
          description: err.toString().replace('Error: GraphQL error:', ''),
          duration: 0,
        });
      });
  };

  const handleSubmit = () => {
    if (changeVideo && filelists.length > 1) {
      message.error('Upload one video at a time');
      return;
    }
    form.validateFields(async (formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      // return;
      try {
        let date = currentItem.premiereAt
          ? moment(currentItem.premiereAt).format('MMM DD, YYYY')
          : moment(currentItem.createdAt).format('MMM DD, YYYY');
        let title = values.title == undefined || values.title == '' ? `${values.activity.label} - ${date}` : values.title;
        setIsLoading(true);
        let thumbnailUrl = null;
        let captionUrl = null;
        if (thumbnail.length > 0) {
          console.log(thumbnail);
          const res = await Api.uploadFile(thumbnail[0]);
          console.log('data', res);
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Video Update Failed',
              duration: 0,
              description: errorMessage,
            });
            return;
          }
          thumbnailUrl = data.result.url;
        }
        if (caption.length > 0) {
          // console.log('caption', caption);
          const res = await Api.uploadFile(caption[0]);
          // console.log('data caption', res);
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Video Update Failed',
              duration: 0,
              description: errorMessage,
            });
            return;
          }
          captionUrl = data.result.url;
        }
        if (changeVideo && filelists.length == 1) {
          generatePresignedUrl({
            variables: {
              input: { type: 'put', mime: filelists[0].type, vid: currentItem.vid },
            },
          })
            .then(async res => {
              const { url, type, vid } = res.data.generatePreSignedURL;
              const vidType = filelists[0].type.split('/')[1] == 'quicktime' ? 'mov' : filelists[0].type.split('/')[1];
              const input = {
                vid: vid,
                videoType: vidType,
                mimeType: filelists[0].type,
                rawMimeType: filelists[0].type,
                title: title,
                isLive: currentItem.isLive,
                description: values.description,
                tags: values.tags,
                premiere: videoParam.premiere,
                status: values.status,
                allowComments: videoParam.allowComments,
                thumbnail: thumbnailUrl,
                memberships: values.memberships || [],
                membershipRestriction: videoParam.membershipRestriction,
                ...(videoParam.premiere && {
                  premiereAt: values.premiereAt.toISOString(),
                }),
                ...(!currentItem.parentVideoId
                  ? {
                      captionUrl: captionUrl || videoParam.captionUrl || null,
                      activity: values.activity.key,
                      trainer: values.trainer.key,
                      intensity: values.intensity,
                      equipments: values.equipments,
                      shared: videoParam.shared,
                    }
                  : {}),
              };
              uploadToS3(url, { input, organizationId: currentItem.organizationId });
            })
            .catch(error => {
              setIsLoading(false);
              const errorMessage = error.toString().replace('Error: GraphQL error:', '');
              notification.error({
                message: 'Video Update Failed',
                duration: 0,
                description: errorMessage,
              });
            });
        } else {
          const params = {
            title: title,
            description: values.description,
            tags: values.tags,
            isLive: currentItem.isLive,
            premiere: videoParam.premiere,
            status: values.status,
            allowComments: videoParam.allowComments,
            thumbnail: thumbnailUrl || videoParam.thumbnail || null,
            memberships: values.memberships,
            premiereAt: videoParam.premiere ? values.premiereAt.toISOString() : null,
            ...(!currentItem.parentVideoId
              ? {
                  shared: videoParam.shared,
                  captionUrl: captionUrl || videoParam.captionUrl || null,
                  activity: values.activity.key,
                  trainer: values.trainer.key,
                  intensity: values.intensity,
                  equipments: values.equipments,
                }
              : {}),
          };
          uploadVideo({ input: params });
        }
      } catch (error) {
        setIsLoading(false);
        const errorMessage = error.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Video Update Failed',
          duration: 0,
          description: errorMessage,
        });
      }
    });
  };
  return (
    <Modal
      title="Edit Video"
      footer={[
        <Button key="update" type="primary" loading={isLoading} onClick={handleSubmit}>
          Update
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      {trainers && activities && currentItem ? (
        <>
          <Form layout="vertical">
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                initialValue: videoParam.title,
              })(<Input placeholder="Enter Title" />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                initialValue: videoParam.description,
              })(<TextArea rows={3} placeholder="Enter Description" />)}
            </Form.Item>
            <Form.Item label="Tags">
              {getFieldDecorator('tags', {
                initialValue: videoParam.tags,
              })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags"></Select>)}
            </Form.Item>
            <Form.Item label="Membership Restriction">
              {getFieldDecorator('membershipRestriction', {
                initialValue: { checked: videoParam.membershipRestriction },
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of Video!',
                  },
                ],
              })(<Switch checked={videoParam.membershipRestriction} onChange={onMembershipRestrictionChange} />)}
            </Form.Item>
            {videoParam.membershipRestriction && (
              <Form.Item label="Memberships">
                {getFieldDecorator('memberships', {
                  initialValue: videoParam.memberships,
                  rules: [
                    {
                      required: true,
                      message: 'Please add memberships!',
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select a Memberships"
                    optionFilterProp={'children'}
                    filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {memberships && memberships.length > 0 && memberships.map((item: any) => <Option key={item._id}>{item.membershipName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            )}
            {!currentItem.parentVideoId && (
              <>
                <Form.Item
                  label={
                    <div>
                      <label>Thumbnail</label>
                      {!!videoParam.thumbnail && (
                        <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
                          <img
                            alt="video preview"
                            src={videoParam.thumbnail}
                            style={{
                              background: '#fff',
                              position: 'absolute',
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                            }}
                            draggable={false}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  {getFieldDecorator('thumbnail', {
                    initialValue: { fileList: thumbnail },
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Please input the name of the organization!',
                    //   },
                    // ],
                  })(
                    <Upload accept="image/*" fileList={thumbnail} beforeUpload={beforeUploadThumbnail} onRemove={onRemoveThumbnail}>
                      <Button>
                        <Icon type="upload" /> Upload
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item
                  label="Closed Caption"
                  extra={
                    videoParam.captionUrl && (
                      <StyledDiv>
                        <div>caption</div>
                        <div>
                          <Button onClick={() => setVideoParam(prev => ({ ...prev, captionUrl: undefined }))} type="link" icon="delete"></Button>
                        </div>
                      </StyledDiv>
                    )
                  }
                >
                  {getFieldDecorator('closedCaption', {
                    // initialValue: { fileList: thumbnail },
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Please input the name of the organization!',
                    //   },
                    // ],
                  })(
                    <Upload accept="text/*,.vtt" fileList={caption} beforeUpload={beforeUploadCaption} onRemove={onRemoveCaption}>
                      {/* <Popconfirm title="Coming Soon"> */}
                      <Button>
                        <Icon type="upload" /> Upload
                      </Button>
                      {/* </Popconfirm> */}
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item label="Trainer">
                  {getFieldDecorator('trainer', {
                    initialValue: videoParam.trainer,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the trainer!',
                      },
                    ],
                  })(
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Select a trainer"
                      optionFilterProp="children"
                      // onChange={this.handleSelectChange}
                      filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {trainers.length > 0 &&
                        trainers.map((item: any) => (
                          <Option key={item._id} value={item._id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Activity">
                  {getFieldDecorator('activity', {
                    initialValue: videoParam.activity,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the actvity!',
                      },
                    ],
                  })(
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Select a activity"
                      onChange={(s: any) => {
                        if (activities) {
                          for (const a of activities) {
                            if ((a as any)._id == s.key) {
                              form.setFieldsValue({ equipments: (a as any).equipments || [] });
                            }
                          }
                        }
                      }}
                      optionFilterProp="children"
                      // onChange={this.handleSelectChange}
                      filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {activities.length > 0 &&
                        activities.map((item: any) => (
                          <Option key={item._id} value={item._id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Share">
                  {getFieldDecorator('shared', {
                    initialValue: { checked: videoParam.shared },
                    rules: [
                      // {
                      //   required: true,
                      //   message: 'Please input the title of Video!',
                      // },
                    ],
                  })(<Switch checked={videoParam.shared} onChange={onSharedChange} />)}
                </Form.Item>
                <Form.Item label="Intensity">
                  {getFieldDecorator('intensity', {
                    initialValue: videoParam.intensity,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the actvity!',
                      },
                    ],
                  })(
                    <Radio.Group onChange={onIntensityChange}>
                      <Radio.Button value="low">Low</Radio.Button>
                      <Radio.Button value="medium">Medium</Radio.Button>
                      <Radio.Button value="high">High</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item label="Equiment Needed">
                  {getFieldDecorator('equipments', {
                    initialValue: videoParam.equipments,
                  })(
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                      {renderEquipments}
                    </Select>
                  )}
                </Form.Item>
              </>
            )}
            <Form.Item label="Allow Comments">
              {getFieldDecorator('allowComments', {
                initialValue: { checked: videoParam.allowComments },
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of Video!',
                  },
                ],
              })(<Switch checked={videoParam.allowComments} onChange={onAllowCommentsChange} />)}
            </Form.Item>
            <Form.Item label="Status">
              {getFieldDecorator('status', {
                initialValue: videoParam.status,
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of Video!',
                  },
                ],
              })(
                <Select style={{ width: '100%' }} onChange={handleStatusChange}>
                  <Option value="published">Publish</Option>
                  <Option value="notPublished">Not Publish</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Premiere">
              {getFieldDecorator('premiere', {
                initialValue: { checked: videoParam.premiere },
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of Video!',
                  },
                ],
              })(<Switch checked={videoParam.premiere} onChange={onPremiereChange} />)}
            </Form.Item>
            {videoParam.premiere && (
              <Form.Item label="Premiere At">
                {getFieldDecorator('premiereAt', {
                  initialValue: videoParam.premiereAt,
                  rules: [
                    {
                      required: true,
                      message: 'Please input premiere date!',
                    },
                  ],
                })(<DatePicker showTime onChange={onDateChange} />)}
              </Form.Item>
            )}
            {!currentItem.parentVideoId && (
              <>
                <Form.Item>
                  <Button
                    onClick={() => {
                      if (changeVideo) {
                        setFilelists([]);
                      }
                      setChangeVideo(prev => !prev);
                    }}
                  >
                    {changeVideo ? `Don't Change Video` : 'Change Video'}
                  </Button>
                </Form.Item>
                {changeVideo && (
                  <Form.Item wrapperCol={{ span: 24 }} required={true}>
                    {getFieldDecorator('filelists', {
                      initialValue: { fileList: filelists },
                      rules: [
                        {
                          required: true,
                          message: 'Please upload a video!',
                        },
                      ],
                    })(
                      <Dragger accept=".mov,video/*" beforeUpload={beforeUpload} onRemove={onRemoveFile} fileList={filelists}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Click or drag video to this area to upload</p>
                      </Dragger>
                    )}
                  </Form.Item>
                )}
              </>
            )}
          </Form>
          <UploadProgressModal visible={showProgressModal} progressPercent={progressPercent} />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Modal>
  );
};
const EditVideoModal = Form.create<VideoModalProps>()(EditVideoModalComponent);
export default EditVideoModal;
