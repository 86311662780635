import React, { useRef, useState } from 'react';
import { Button, Icon, Input, DatePicker, notification, Tag } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ALL_ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { ALL_TRAINERS_QUERY } from 'apollo/trainer/queries';
import { getUserData } from 'helper';
import './styles.css';
import { CHANGE_VIDEO_STATUS_MUTATION } from 'apollo/video/mutations';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import DropdownActions from 'component/DropdownActions/DropdownActions';
import { MEMBERSHIPS_QUERY } from 'apollo/membership/queries';
const PAGE_LIMIT = 10;

const AltVideoTable = ({
  tableLoading,
  setTableLoading,
  openVideoPlayerModal,
  onEditVideo,
  videos,
  paginationData,
  handleTableChange,
  videosSorter,
}) => {
  const [changeVideoStatus] = useMutation(CHANGE_VIDEO_STATUS_MUTATION, {
    refetchQueries: ['allSharedVideos', 'publishedSharedVideos'],
    awaitRefetchQueries: true,
  });
  const loggedInUser = JSON.parse(getUserData());
  const titleSearhInputRef = useRef<any>();
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [memberships, setMemberships] = useState<any>(null);
  const [organizations, setOrganizations] = useState<any>(null);
  useQuery(ALL_ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.allActivities);
      // console.log('data.activities', data.activities);
    },
  });
  useQuery(ALL_TRAINERS_QUERY, {
    onCompleted: data => setTrainers(data.allTrainers),
  });
  useQuery(MEMBERSHIPS_QUERY, {
    onCompleted: data => setMemberships(data.memberships),
  });
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => {
      setOrganizations(data.organizations);
    },
  });

  /** video title search  */
  const getVideoTitleSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={titleSearhInputRef}
          placeholder={`Search Title`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleTitleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => titleSearhInputRef.current.select());
      }
    },
  });
  const handleTitleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleTitleReset = clearFilters => {
    clearFilters();
  };

  /** Date filter */
  const getDateFilterProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          placeholder={`Filter Date`}
          onChange={val => setSelectedKeys(val ? [val] : [])}
          format="DD-MMM"
        />
        <Button
          type="primary"
          onClick={() => handleDateFilter(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleDateReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? '#1890ff' : undefined }} />,
  });
  const handleDateFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleDateReset = clearFilters => {
    clearFilters();
  };

  const changeStatus = (video, status) => {
    setTableLoading(true);
    changeVideoStatus({
      variables: {
        _id: video._id,
        parentVideoId: video.parentVideoId,
        status: status,
      },
      awaitRefetchQueries: true,
    })
      .then(result => {
        setTableLoading(false);
        notification.success({
          message: 'Status Changed',
          duration: 0,
          description: 'Status was changed successfully.',
        });
      })
      .catch(err => {
        setTableLoading(false);
        notification.error({
          message: 'Status Change Failed',
          duration: 0,
          description: 'Status was not changed.',
        });
      });
  };

  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={
                record.thumbnail ||
                (record.organization && record.organization.settings && record.organization.settings.fallbackThumbnail) ||
                `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization && record.organization.name}`
              }
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${record.organization && record.organization.name}`;
              }}
              style={{
                background: '#fff',
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
      filters:
        activities &&
        activities.map(item => ({
          text: item.name,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
      filters:
        trainers &&
        trainers.map(item => ({
          text: item.name,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <span style={{ cursor: 'pointer' }} onClick={() => openVideoPlayerModal(record)}>
          {record.title}
        </span>
      ),
      ...getVideoTitleSearchProps(),
    },

    {
      key: 'sharedBy',
      title: 'Shared By',
      render: record => {
        return <span>{(record.parentOrganization && record.parentOrganization.name) || (record.organization && record.organization.name)}</span>;
      },
      filters:
        organizations &&
        organizations.map(item => ({
          text: item.name,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'memberships',
      title: 'Memberships',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.memberships.map(item => (
            <Tag key={item._id}>{`${item.membershipName} (${item.membershipExternalId})`}</Tag>
          ))}
        </div>
      ),
      filters:
        memberships &&
        memberships.map(item => ({
          text: item.membershipName,
          value: item._id,
        })),
      filterMultiple: true,
    },
    {
      key: 'premiereAt',
      title: 'Premiere At',
      render: record => (record.premiereAt ? moment(record.premiereAt).format('Do MMMM, hh:mm a') : 'No Premiere'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: videosSorter.field == 'premiereAt' ? videosSorter.order : false,
      sorter: true,
      ...getDateFilterProps('premiereAt'),
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sortDirections: ['descend', 'ascend'],
      sortOrder: videosSorter.field == 'createdAt' ? videosSorter.order : false,
      sorter: true,
      ...getDateFilterProps('createdAt'),
    },
    {
      key: 'status',
      title: 'Status',
      render: record => <span>{record.status}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <DropdownActions
          menuItem={[
            ...(record.status == 'published'
              ? [
                  {
                    key: 'publish',
                    title: 'Remove',
                    onClick: () => changeStatus(record, 'notPublished'),
                  },
                ]
              : [
                  {
                    key: 'nptpublish',
                    title: 'Add',
                    onClick: () => changeStatus(record, 'published'),
                  },
                ]),
            ...(record.parentVideoId
              ? [
                  {
                    key: 'edit',
                    title: 'Edit',
                    onClick: () => onEditVideo(record),
                  },
                ]
              : []),
          ]}
        />
        // <>
        //   {record.status == 'published' && (
        //     <span style={{ marginRight: 10 }}>
        //       <Button type="danger" onClick={() => changeStatus(record, 'notPublished')}>
        //         Remove
        //       </Button>
        //     </span>
        //   )}
        //   {record.status == 'notPublished' && (
        //     <span style={{ marginRight: 10 }}>
        //       <Button type="primary" onClick={() => changeStatus(record, 'published')}>
        //         Add
        //       </Button>
        //     </span>
        //   )}
        //   {record.parentVideoId && (
        //     <span style={{ marginRight: 10 }}>
        //       <Button type="primary" onClick={() => onEditVideo(record)}>
        //         Edit
        //       </Button>
        //     </span>
        //   )}
        // </>
      ),
    },
  ];

  return (
    <AntdTable
      loading={tableLoading}
      rowKey={record => record._id}
      columns={columns}
      rowClassName={record => (record.status === 'published' ? 'green' : 'red')}
      onChange={handleTableChange}
      dataSource={videos}
      scroll={{ x: true }}
      pagination={{
        position: 'bottom',
        pageSize: PAGE_LIMIT,
        total: paginationData.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        // onChange: handlePageChange,
      }}
    />
  );
};

export default AltVideoTable;
