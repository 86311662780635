import { gql } from 'apollo-boost';

export const ANALYTICS_VIDEO_REPORT_QUERY = gql`
  query analyticsVideoReport(
    $startDate: String!
    $endDate: String!
    $organizationId: String
    $analyticsFilterInput: AnalyticsFilterInput
    $nextPageToken: String
  ) {
    analyticsVideoReport(
      startDate: $startDate
      endDate: $endDate
      organizationId: $organizationId
      analyticsFilterInput: $analyticsFilterInput
      nextPageToken: $nextPageToken
    )
  }
`;
