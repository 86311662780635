import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Row, Button, Icon, Modal, Form, Input, Select, notification, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { CREATE_ACTIVITY_MUTATION, UPDATE_ACTIVITY_MUTATION } from 'apollo/activity/mutations';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { getUserData } from 'helper';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';

const { Option } = Select;
const equipmentList = [
  'No Equipment',
  'Balance Board',
  'Barbell',
  'Bench',
  'Bosu',
  'Box/Step',
  'Chair',
  'Dumbbells',
  'Elastic Bands',
  'Exercise Ball',
  'Foam Roller',
  'Kettle Bell',
  'Medicine Ball',
  'Resistence Band',
  'Rope',
  'Speed Ladder',
  'Stick',
  'Tennis Ball',
];
interface ActivityModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
  totalActivity?: number;
}

const AddActivityModalComponent: React.FC<ActivityModalProps> = ({
  form,
  isCreate,
  currentItem,
  handleCancel,
  handleOk,
  modalVisible,
  totalActivity = 0,
}) => {
  const [createActivity] = useMutation(CREATE_ACTIVITY_MUTATION, {
    update(cache, { data: { createActivity } }) {
      const cachedData: any = cache.readQuery({ query: ACTIVITIES_QUERY });
      cache.writeQuery({
        query: ACTIVITIES_QUERY,
        data: { activities: [...cachedData.activities, createActivity] },
      });
    },
    refetchQueries: ['activities'],
  });
  const [updateActivity] = useMutation(UPDATE_ACTIVITY_MUTATION);
  useQuery(ORGANIZATIONS_QUERY, {
    onCompleted: data => setOrganizations(data.organizations),
  });
  const [organizations, setOrganizations] = useState<any>(null);
  const [self, setSelf] = useState(JSON.parse(getUserData()) || null);
  const [isLoading, setIsLoading] = useState(false);
  const [activityParam, setActivityParam] = useState({
    name: '',
    organizationId: '',
    displayOrder: totalActivity + 1,
    equipments: [],
    showOnHome: true,
  });

  useEffect(() => {
    if (currentItem) {
      setActivityParam({
        ...activityParam,
        name: currentItem.name,
        organizationId: currentItem.organizationId,
        displayOrder: currentItem.displayOrder,
        equipments: currentItem.equipments || [],
        showOnHome: currentItem.showOnHome ? true : false,
      });
    }
  }, [currentItem]);
  useEffect(() => {
    setActivityParam({
      ...activityParam,
      displayOrder: totalActivity + 1,
    });
  }, [totalActivity]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setActivityParam({
      name: '',
      organizationId: '',
      displayOrder: totalActivity + 1,
      equipments: [],
      showOnHome: true,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };
  const orderOptions = Array.from({ length: isCreate ? totalActivity + 1 : (totalActivity as any) }, (_, i) => i + 1).map((item, index) => (
    <Option key={index} value={index + 1}>
      {index + 1}
    </Option>
  ));

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        input: {
          name: values.name,
          displayOrder: values.displayOrder,
          equipments: values.equipments,
          showOnHome: activityParam.showOnHome,
        },
        ...(self && self.role === 'superadmin' ? (isCreate ? { organizationId: values.organizationId } : {}) : {}),
      };
      if (isCreate) {
        createActivity({
          variables: { ...params },
        })
          .then(mutationResult => {
            setIsLoading(false);
            notification.success({
              message: 'Activity Created',
              duration: 0,
              description: 'Activity was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Activity Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateActivity({
          variables: {
            ...params,
            _id: currentItem._id,
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            notification.success({
              message: 'Activity Update',
              duration: 0,
              description: 'Activity was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Activity Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Activity' : 'Update Activity Detail'}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: activityParam.name,
            rules: [
              {
                required: true,
                message: 'Please input the name of site!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Equipments">
          {getFieldDecorator('equipments', {
            initialValue: activityParam.equipments,
            rules: [],
          })(
            <Select mode="multiple" style={{ width: '100%' }} placeholder="Select Equipment">
              {equipmentList &&
                equipmentList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Display Order">
          {getFieldDecorator('displayOrder', {
            initialValue: activityParam.displayOrder,
            rules: [],
          })(<Select>{orderOptions}</Select>)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showOnHome', {
            valuePropName: 'checked',
            initialValue: activityParam.showOnHome,
            rules: [],
          })(
            <Checkbox onChange={e => setActivityParam(prev => ({ ...prev, showOnHome: e.target.checked }))}>Show On Home in Video Platfom</Checkbox>
          )}
        </Form.Item>
        {self.role === 'superadmin' && isCreate ? (
          <Form.Item label="Organization">
            {getFieldDecorator('organizationId', {
              initialValue: activityParam.organizationId,
              rules: [
                {
                  required: true,
                  message: 'Please input the organization of user!',
                },
              ],
            })(
              <Select>
                {organizations &&
                  organizations.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        ) : null}

        <Row style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? 'Add' : 'Update'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddActivityModal = Form.create<ActivityModalProps>()(AddActivityModalComponent);
export default AddActivityModal;
