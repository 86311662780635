import gql from 'graphql-tag';
import { VIDEO_FRAGMENT } from 'apollo/video/videoFragmet';

export const USER_ACTIVITY_QUERY = gql`
  query userActivity($_id: ID) {
    userActivity(_id: $_id) {
      _id
      activityType
      userId
      videoId
      createdAt
      video {
        ...Video
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
