import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal, Timeline } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_ACTIVITY_QUERY } from 'apollo/user/queries';
import Loader from 'react-loader-spinner';
import moment from 'moment';

const WatchTimelineModal = ({ visible, currentItem, handleCancel }) => {
  const [watchedVideos, setWatchedVideos] = useState<any>(null);
  const [getWatchedVideos] = useLazyQuery(USER_ACTIVITY_QUERY, {
    onCompleted: data => {
      console.log('timeline', data);
      if (data && data.userActivity) {
        setWatchedVideos(data.userActivity);
      } else {
        setWatchedVideos([]);
      }
    },
    onError: err => {
      console.error('err in liked videos', err);
      setWatchedVideos([]);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (currentItem) {
      getWatchedVideos({
        variables: {
          _id: currentItem._id,
        },
      });
    }
  }, [currentItem]);

  const close = () => {
    setWatchedVideos(null);
    handleCancel();
  };

  return (
    <Modal title="Watch History" visible={visible} onCancel={close} footer={null} width={1000}>
      {watchedVideos ? (
        <Timeline>
          {watchedVideos.map(item => (
            <Timeline.Item dot={<Icon type="clock-circle" />} key={item._id}>
              <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,1)' }}>
                {moment(item.createdAt).format('DD MMM YYYY')}
                <br />
              </span>
              <span>{`${item.activityType}ed video '${item.video?.title}'`}</span>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </Modal>
  );
};

export default WatchTimelineModal;
