import React, { useState } from 'react';
import { Row, Col } from 'antd';
import LoadingSpinner from 'component/Loader';
import VideoDetailEdit from 'component/VideoDetailEdit/VideoDetailEdit';
import { useQuery } from '@apollo/react-hooks';
import { VIDEO_QUERY } from 'apollo/video/queries/video';
import { useHistory, useLocation } from 'react-router-dom';
import VideoShareInfoEdit from 'component/VideoShareInfoEdit/VideoShareInfoEdit';

const VideoEditContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const [video, setVideo] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const getVideoId = () => {
    let videoIdParam: null | String = null;
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      videoIdParam = queryParams.get('vid');
      if (videoIdParam == null) {
        return null;
      }
    }
    return videoIdParam;
  };
  useQuery(VIDEO_QUERY, {
    variables: {
      _id: getVideoId(),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setLoading(false);
      if (data.video == null) {
        history.push('/');
      } else {
        setVideo(data.video);
      }
    },
    onError: error => {
      setLoading(false);
      console.log('err', error);
      history.push('/');
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Row style={{ marginTop: 15, marginBottom: 15 }}>
      <Col span={11} offset={1} style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        <VideoDetailEdit video={video} setLoading={setLoading} />
      </Col>
      <Col span={10} offset={1}>
        <div style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
          {!video.parentVideoId && <VideoShareInfoEdit video={video} />}
          {video.parentVideoId && video.parentOrganization && (
            <div style={{ paddingBottom: 20 }}>
              <span style={{ fontWeight: 200 }}>This video is shared by:</span>
              <div>
                <h3>{video.parentOrganization.name}</h3>
                <img src={video.parentOrganization.logo} style={{ width: '100%' }} alt="organization logo" />
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default VideoEditContainer;
