import { gql } from 'apollo-boost';

export const SEND_RESET_PASSWORD_MUTATION = gql`
  mutation sendResetPassword($_id: ID!) {
    sendResetPassword(_id: $_id) {
      type
      payload
    }
  }
`;
