import React, { useState } from 'react';
import { Row, Col, Button, Select, Input, Checkbox, DatePicker, notification } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_WAIVER_FORM_QUERY } from 'apollo/organization/queries';
import { SAVE_WAIVER_FORM_MUTATION } from 'apollo/organization/mutations';
import { getUserData } from 'helper';
import LoadingSpinner from 'component/Loader';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const SelectEntity = ({ item, index, onSave, onRemove }) => {
  const [ent, setEnt] = useState({
    type: item.type,
    title: item.title,
    text: item.text,
    label: item.label,
  });

  const onchange = (target, value) => {
    let temp = { ...ent };
    temp[target] = value;
    setEnt(temp);
  };
  return (
    <div style={{ borderBottom: '1px solid #000', padding: '20px 10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <label>Type:</label>
        <Select
          value={ent.type}
          onChange={val => setEnt(prev => ({ type: val, title: null, label: null, text: null }))}
          style={{ marginLeft: 10, width: '100%' }}
        >
          <Option value="title">Title</Option>
          <Option value="text">Text</Option>
          <Option value="textInput">Text Input</Option>
          <Option value="checkbox">Checkbox</Option>
          <Option value="date">Date</Option>
        </Select>
      </div>
      {ent.type == 'title' && (
        <div>
          <label>Title</label>
          <Input value={ent.title} onChange={e => onchange('title', e.target.value)} />
        </div>
      )}
      {ent.type == 'text' && (
        <div>
          <label>Text</label>
          <Input.TextArea value={ent.text} onChange={e => onchange('text', e.target.value)} />
        </div>
      )}
      {(ent.type == 'textInput' || ent.type == 'checkbox' || ent.type == 'date') && (
        <div>
          <label>Label</label>
          <Input value={ent.label} onChange={e => onchange('label', e.target.value)} />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div>
          <Button type="link" onClick={() => onSave(ent, index)}>
            Save
          </Button>
        </div>
        <div>
          <Button type="link" style={{ color: 'red' }} onClick={() => onRemove(ent, index)}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

const WaiverContainer = () => {
  const history = useHistory();
  const [user] = useState(JSON.parse(getUserData()));
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [entity, setEntity] = useState<any>([]);
  const [saveWaiverForm] = useMutation(SAVE_WAIVER_FORM_MUTATION);
  useQuery(GET_WAIVER_FORM_QUERY, {
    onCompleted: data => {
      setLoading(false);
      setEntity(data.getWaiverForm || []);
    },
    onError: err => {
      setLoading(false);
      setEntity([]);
    },
    fetchPolicy: 'network-only',
  });
  if (loading) return <LoadingSpinner />;
  const onSave = () => {
    setSaving(true);
    saveWaiverForm({
      variables: {
        _id: user.organizationId,
        input: {
          waiverForm: entity,
        },
      },
    })
      .then(result => {
        setSaving(false);
        notification.success({
          message: 'Waiver Form Saved',
          duration: 0,
          description: 'Waiver from was saved successfully.',
        });
        history.push('/settings');
      })
      .catch(result => {
        setSaving(false);
        notification.error({
          message: 'Waiver Save Failed',
          duration: 0,
          description: 'Waiver from save was unsuccessful.',
        });
      });
  };

  return (
    <Row style={{ marginTop: 20 }}>
      <Col span={12} offset={1} style={{ background: 'white', padding: 15 }}>
        You can preview your form below:
        <div style={{ marginTop: 10 }}>
          {entity.map((item: any) => {
            if (item.type == 'title') {
              return (
                <div style={{ marginBottom: 10 }}>
                  <h2>{item.title}</h2>
                </div>
              );
            }
            if (item.type == 'text') {
              return (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ opacity: 0.65 }}>{item.text}</p>
                </div>
              );
            }
            if (item.type == 'textInput') {
              return (
                <Row style={{ alignItems: 'center', marginBottom: 10 }}>
                  <Col span={4}>
                    <label style={{ opacity: 0.65, fontSize: '14px' }}>{item.label}:</label>
                  </Col>
                  <Col span={10}>
                    <Input style={{ width: '100%' }} />
                  </Col>
                </Row>
              );
            }
            if (item.type == 'checkbox') {
              return (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <Checkbox>
                    <label style={{ opacity: 0.65, fontSize: '14px' }}>{item.label}</label>
                  </Checkbox>
                </div>
              );
            }
            if (item.type == 'date') {
              return (
                <Row style={{ alignItems: 'center', marginBottom: 10 }}>
                  <Col span={4}>
                    <label style={{ opacity: 0.65, fontSize: '14px' }}>{item.label}:</label>
                  </Col>
                  <Col span={10}>
                    <DatePicker style={{ width: '100%' }} />
                  </Col>
                </Row>
              );
            }
          })}
        </div>
      </Col>
      <Col span={9} offset={1} style={{ background: 'white', padding: 15 }}>
        {entity.length > 0 &&
          entity.map((item, index) => {
            return (
              <SelectEntity
                item={item}
                index={index}
                onSave={(s, i) => {
                  let temp = [...entity];
                  temp[i] = s;
                  setEntity(temp);
                }}
                onRemove={(s, i) => {
                  let temp = [...entity];
                  temp.splice(i, 1);
                  setEntity(temp);
                }}
              />
            );
          })}
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>
          <Button type="default" onClick={() => setEntity(prev => [...prev, { type: 'title' }])} disabled={saving}>
            Add
          </Button>
          <Button style={{ marginLeft: 15 }} type="primary" onClick={onSave} loading={saving}>
            Save
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default WaiverContainer;
