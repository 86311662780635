import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-full';
import React from 'react';
import Uploader from './Uploader';

const Editor = ({ message, onChange }) => {
  const config = {
    language: 'en',
    toolbar: { shouldNotGroupWhenFull: true },
  };
  return (
    <CKEditor
      editor={ClassicEditor}
      data={message}
      onReady={editor => {
        editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
          return new Uploader(loader);
        };
      }}
      config={config}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};
export default Editor;
