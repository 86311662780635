import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, DatePicker, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

interface AddEditAnnouncementsModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditAnnouncementsModalComponent: React.FC<AddEditAnnouncementsModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [announcementsParam, setAnnouncementsParam] = useState<any>({
    description: undefined,
    start: undefined,
    end: undefined,
  });

  useEffect(() => {
    if (currentItem) {
      setAnnouncementsParam({
        ...announcementsParam,
        description: currentItem.description,
        start: moment(currentItem.start),
        end: moment(currentItem.end),
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setAnnouncementsParam({
      description: undefined,
      start: undefined,
      end: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      if (values.start > values.end) {
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greater than end date.',
        });
        return;
      }
      const announcement = {
        description: values.description,
        start: values.start.startOf('day').toISOString(),
        end: values.end.endOf('day').toISOString(),
      };
      if (isCreate) {
        onAdd(announcement);
      } else {
        onEdit(announcement);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Announcement' : 'Edit Announcement'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: announcementsParam.description,
            rules: [
              {
                required: true,
                message: 'Please input the description!',
              },
            ],
          })(<Input.TextArea placeholder="Enter Description" />)}
        </Form.Item>
        <Form.Item label="Start">
          {getFieldDecorator('start', {
            initialValue: announcementsParam.start,
            rules: [
              {
                required: true,
                message: 'Please input start date!',
              },
            ],
          })(<DatePicker placeholder="Enter Start Date" />)}
        </Form.Item>
        <Form.Item label="End">
          {getFieldDecorator('end', {
            initialValue: announcementsParam.end,
            rules: [
              {
                required: true,
                message: 'Please input end date!',
              },
            ],
          })(<DatePicker placeholder="Enter End Date" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditAnnouncementsModal = Form.create<AddEditAnnouncementsModalProps>()(AddEditAnnouncementsModalComponent);
export default AddEditAnnouncementsModal;
