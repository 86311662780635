import React, { useEffect, useState } from 'react';
import { Icon, Input, Modal, Button, DatePicker } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { VIDEOS_BANDWIDTH_QUERY } from 'apollo/video/queries';
import Loader from 'react-loader-spinner';
import AntdTable from 'component/elements';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';

const { RangePicker } = DatePicker;

const VideoBandwidthModal = ({ visible, closeModal, organization }) => {
  const [videos, setVideos] = useState(null);
  const [videosBandwidthQuery, { loading }] = useLazyQuery(VIDEOS_BANDWIDTH_QUERY, {
    onCompleted: data => {
      setVideos(data.videosBandwidth.report);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (organization) {
      videosBandwidthQuery({
        variables: {
          organizationId: organization._id,
        },
      });
    } else {
      setVideos(null);
    }
  }, [organization]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button type="primary" onClick={() => confirm()} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });
  const getDateSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          value={[(selectedKeys[0] && selectedKeys[0][0]) || undefined, (selectedKeys[0] && selectedKeys[0][1]) || undefined]}
          onChange={val => setSelectedKeys([val])}
          style={{ width: 300, marginBottom: 8, display: 'block' }}
        />
        <Button type="primary" onClick={() => confirm()} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return moment(record[dataIndex]) <= value[1] && moment(record[dataIndex]) >= value[0];
      } else {
        return false;
      }
    },
  });

  const columns: ColumnProps<any>[] = [
    {
      key: '_id',
      title: 'Video ID',
      render: record => record.videoId,
      ...getColumnSearchProps('videoId'),
    },

    {
      key: 'title',
      title: 'Title',
      render: record => record.videoTitle,
      ...getColumnSearchProps('videoTitle'),
    },
    {
      key: 'size',
      title: 'Size',
      render: record => `${(record.videoSizeWatched / (1024 * 1024)).toFixed(2)} GB`,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.videoSizeWatched - b.videoSizeWatched,
      // ...getVideoTitleSearchProps(),
    },

    {
      key: 'uploadDate',
      title: 'Upload Date',
      render: record => moment(record.uploadDate).format('DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => new Date(a.uploadDate).getTime() - new Date(b.uploadDate).getTime(),
      ...getDateSearchProps('uploadDate'),
    },
  ];
  return (
    <Modal title={organization ? `${organization.name} Videos Bandwidth` : '-'} footer={null} visible={visible} onCancel={closeModal} width={'70%'}>
      <div>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Loader type="TailSpin" color="#202541" />
          </div>
        ) : (
          <>
            {videos && (
              <AntdTable
                style={{ width: '100%' }}
                rowKey={(item: any) => item.videoId + item.organizationId}
                dataSource={videos || []}
                columns={columns}
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default VideoBandwidthModal;
