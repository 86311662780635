import React, { useEffect, useState } from 'react';
import { Form, Upload, Button, Icon, Input, Select, Row, Col, notification, message, Tooltip } from 'antd';
import ColorPickerPopup from 'component/ColorPickerPopup/ColorPickerPopup';
import CountriesCities from '../../helper/countries.json';
import Api from 'axios/Api';
import { ORGANIZATION_UPDATE_MUTATION } from 'apollo/organization/mutations';
import { useMutation } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import LabelwTooltip from 'component/LabelwTooltip/LabelwTooltip';

const { TextArea } = Input;
const { Option } = Select;

interface OrgDetailSettingsProps extends FormComponentProps {
  organization: any;
  setLoading: any;
}

const OrgDetailSettingsForm = ({ form, organization, setLoading }) => {
  const { getFieldDecorator } = form;
  const [orgLogo, setOrgLogo] = useState<any>([]);
  const [selectCities, setSelectCities] = useState<any>(null);
  const Countries = Object.keys(CountriesCities);
  const [updateOrganization] = useMutation(ORGANIZATION_UPDATE_MUTATION, { refetchQueries: ['organization'] });
  const [organizationParam, setOrganizationParam] = useState({
    logo: undefined,
    description: undefined,
    loginPageTitle: undefined,
    disclaimer: undefined,
    forgotPasswordHelpText: undefined,
    country: undefined,
    city: undefined,
    phoneNumber: undefined,
    primary: '#fff',
    secondary: '#fff',
  });
  useEffect(() => {
    if (organization) {
      setOrganizationParam(prev => ({
        ...prev,
        country: organization.country,
        city: organization.city,
        logo: organization.logo,
        phoneNumber: organization.phoneNumber,
        description: organization.description,
        disclaimer: (organization.customData && organization.customData.disclaimer) || undefined,
        forgotPasswordHelpText: (organization.customData && organization.customData.forgotPasswordHelpText) || undefined,
        loginPageTitle: (organization.customData && organization.customData.loginPageTitle) || undefined,
        primary: (organization.theme && organization.theme.colors && organization.theme.colors.primary) || '#fff',
        secondary: (organization.theme && organization.theme.colors && organization.theme.colors.secondary) || '#fff',
      }));
    }
  }, [organization]);

  const handleSubmit = async () => {
    form.validateFields(async (formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setLoading(true);
      try {
        let url = null;
        if (orgLogo.length > 0) {
          console.log(orgLogo);
          const res = await Api.uploadFile(orgLogo[0]);
          console.log('data', res);
          const { data } = res;
          if (!data.success) {
            setLoading(false);
            const errorMessage = data.error.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Organization Update Failed',
              duration: 0,
              description: errorMessage,
            });
            return;
          }
          url = data.result.url;
        }
        const params = {
          country: values.country,
          city: values.city,
          logo: url || (organization && organization.logo) || null,
          phoneNumber: values.phoneNumber,
          description: values.description,
          disclaimer: values.disclaimer,
          forgotPasswordHelpText: values.forgotPasswordHelpText,
          loginPageTitle: values.loginPageTitle,
          colors: { primary: values.primary, secondary: values.secondary },
        };
        // console.log('form vals', values);
        // setLoading(false);
        // return;
        updateOrganization({
          variables: {
            _id: organization && organization._id,
            input: {
              ...params,
            },
          },
        })
          .then(mutationResult => {
            console.log('updated', mutationResult.data.organizationUpdate);
            setLoading(false);
            message.success('Updated Succesfully.');
          })
          .catch(e => {
            setLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            message.error(errorMessage);
          });
      } catch (error) {
        const errorMessage = error.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Organization Update Failed',
          duration: 0,
          description: errorMessage,
        });
        setLoading(false);
        console.error('here', error);
      }
    });
  };

  const onCountrySelect = val => {
    if (val !== organizationParam.country) {
      setOrganizationParam(prev => ({
        ...prev,
        country: val,
        city: undefined,
      }));
      form.setFieldsValue({
        city: undefined,
      });
      setSelectCities(CountriesCities[val]);
    }
  };

  const beforeUpload = file => {
    // console.log('file', file);
    setOrgLogo(prev => [file]);
    getBase64(file, imageUrl => {
      setOrganizationParam(prev => ({ ...prev, logo: imageUrl }));
    });
    return false;
  };

  const onRemoveFile = file => {
    // console.log('here', file);
    setOrgLogo(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
    setOrganizationParam(prev => ({ ...prev, logo: (organization && organization.logo) || undefined }));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div>
      <Form layout="vertical">
        <Form.Item
          label={
            <>
              <LabelwTooltip label="Organization Logo" tooltipText="This logo will appear on the front end video platform." />
              <div>{!!organizationParam.logo && <img src={organizationParam.logo} style={{ width: '50%' }} alt="logo org" />}</div>
            </>
          }
        >
          {getFieldDecorator('logo', {
            initialValue: { fileList: orgLogo },
            // rules: [
            //   {
            //     required: true,
            //     message: 'Please input the name of the organization!',
            //   },
            // ],
          })(
            <Upload accept="image/*" fileList={orgLogo} beforeUpload={beforeUpload} onRemove={onRemoveFile}>
              <Button>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item label={<LabelwTooltip label="Phone Number" tooltipText="Phone number of your organization." />}>
          {getFieldDecorator('phoneNumber', {
            initialValue: organizationParam.phoneNumber,
          })(<Input placeholder="Enter Phone Number" />)}
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item label={<LabelwTooltip label="Country" tooltipText="Country of your organization." />}>
              {getFieldDecorator('country', {
                initialValue: organizationParam.country,
                rules: [
                  {
                    required: true,
                    message: 'Please input the country!',
                  },
                ],
              })(
                <Select showSearch placeholder="Select Country" onChange={onCountrySelect}>
                  {Countries &&
                    Countries.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item label={<LabelwTooltip label="City" tooltipText="City of your organization." />}>
              {getFieldDecorator('city', {
                initialValue: organizationParam.city,
                rules: [
                  {
                    required: true,
                    message: 'Please input the city!',
                  },
                ],
              })(
                <Select showSearch placeholder="Select City">
                  {selectCities &&
                    selectCities.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={<LabelwTooltip label="Description" tooltipText="Description of your organization." />}>
          {getFieldDecorator('description', {
            initialValue: organizationParam.description,
          })(<TextArea placeholder="Enter something about your organization" />)}
        </Form.Item>
        <Form.Item
          label={
            <LabelwTooltip
              label="Login Page Title"
              tooltipText="This is the title for the login page. It will be displayed on the login page of the video platform."
            />
          }
        >
          {getFieldDecorator('loginPageTitle', {
            initialValue: organizationParam.loginPageTitle,
          })(<Input placeholder="Enter Login Page Title" />)}
        </Form.Item>
        <Form.Item label={<LabelwTooltip label="Forgot Password Help Text" tooltipText="Add text for users to see below forgot password." />}>
          {getFieldDecorator('forgotPasswordHelpText', {
            initialValue: organizationParam.forgotPasswordHelpText,
          })(<Input placeholder="Enter an Forgot Password Help Text" />)}
        </Form.Item>
        <Form.Item label={<LabelwTooltip label="Disclaimer" tooltipText="Disclaimer will be displayed on the login page below login form." />}>
          {getFieldDecorator('disclaimer', {
            initialValue: organizationParam.disclaimer,
          })(<TextArea placeholder="Enter disclaimer to show on the login page" />)}
        </Form.Item>
        <LabelwTooltip
          label="Colors"
          tooltipText="Select primary and secondary colors of your organization. These colors will be used in videoplatform."
        />
        <div style={{ display: 'flex' }}>
          <Form.Item label="Primary" style={{ marginRight: 20 }}>
            {getFieldDecorator('primary', {
              initialValue: organizationParam.primary,
            })(
              <ColorPickerPopup
                onChange={val => {
                  console.log(val);
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Secondary">
            {getFieldDecorator('secondary', {
              initialValue: organizationParam.secondary,
            })(
              <ColorPickerPopup
                onChange={val => {
                  console.log(val);
                }}
              />
            )}
          </Form.Item>
        </div>
        <Form.Item>
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
const OrgDetailSettings = Form.create<OrgDetailSettingsProps>()(OrgDetailSettingsForm);

export default OrgDetailSettings;
