import React, { useState, useEffect } from 'react';
import { Button, Col, notification, Row } from 'antd';
import OrganizationTable from 'component/OrganizationTable/OrganizationTable';
import AddEditOrganizationModal from 'component/AddEditOrganizationModal/AddEditOrganizationModal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ORGANIZATIONS_QUERY } from 'apollo/organization/queries';
import LoadingSpinner from 'component/Loader';
import { ORGANIZATION_DELETE_MUTATION } from 'apollo/organization/mutations';
import { useHistory } from 'react-router-dom';

const OrganizationsContainer = () => {
  const history = useHistory();
  const [organizations, setOrganizations] = useState<any>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [isCreate, setIsCreate] = useState(true);
  const [showEditOrganizationModal, setShowEditOrganizationModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [organizationDelete] = useMutation(ORGANIZATION_DELETE_MUTATION);
  const { data, loading } = useQuery(ORGANIZATIONS_QUERY);

  useEffect(() => {
    if (data && data.organizations) {
      // console.log('org', data);
      setOrganizations(data.organizations);
    }
    return () => {};
  }, [data]);

  const openEditOrganizationModal = org => {
    setSelectedOrganization(org);
    setIsCreate(false);
    openAddOrganizationModal();
  };

  const openAddOrganizationModal = () => setShowEditOrganizationModal(true);

  const onDeleteOrganization = organizationId => {
    // console.log('org', organizationId);
    setTableLoading(true);
    organizationDelete({
      variables: { _id: organizationId },
      update: cache => {
        const cachedData: any = cache.readQuery({ query: ORGANIZATIONS_QUERY });
        console.log('cached data', cachedData);
        cache.writeQuery({
          query: ORGANIZATIONS_QUERY,
          data: { organizations: cachedData.organizations.filter(item => item._id !== organizationId) },
        });
      },
    })
      .then(res => {
        setTableLoading(false);
        let temp = organizations.filter(item => item._id !== organizationId);
        setOrganizations(temp);
        notification.success({
          message: 'Organization Deleted',
          duration: 0,
          description: 'Organization was deleted successfully.',
        });
      })
      .catch(err => {
        setTableLoading(false);
        console.log('org delete err', err);
        notification.error({
          message: 'Organization Delte Failed',
          duration: 0,
          description: err.toString(),
        });
      });
  };

  const closeEditOrganizationModal = () => {
    setSelectedOrganization(null);
    setIsCreate(true);
    setShowEditOrganizationModal(false);
  };

  const onBillingInfo = organization => {
    history.push(`/organizationBilling/${organization?._id}`);
  };

  if (loading) return <LoadingSpinner />;
  return (
    <>
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={23}>
          <Button type="primary" onClick={openAddOrganizationModal}>
            Add Organization
          </Button>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={23}>
          <OrganizationTable
            tableLoading={tableLoading}
            organizations={organizations}
            onDeleteOrganization={onDeleteOrganization}
            openEditOrganizationModal={openEditOrganizationModal}
            onBillingInfo={onBillingInfo}
          />
        </Col>
      </Row>
      <AddEditOrganizationModal
        currentItem={selectedOrganization}
        isCreate={isCreate}
        handleCancel={closeEditOrganizationModal}
        handleOk={closeEditOrganizationModal}
        modalVisible={showEditOrganizationModal}
      />
    </>
  );
};

export default OrganizationsContainer;
