import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const { Option } = Select;

interface AddEditRuleModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditRuleModalComponent: React.FC<AddEditRuleModalProps> = ({ form, isCreate, currentItem, onCancel, onAdd, onEdit, visible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [ruleParam, setRuleParam] = useState<any>({
    by: undefined,
    shareByActivity: undefined,
    shareByTrainer: undefined,
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      setTrainers(data.trainers);
    },
  });
  useEffect(() => {
    if (currentItem) {
      setRuleParam({
        ...ruleParam,
        by: currentItem.by,
        shareByActivity: currentItem.by != 'all' ? { key: currentItem.shareByActivity?._id, label: currentItem.shareByActivity?.name } : undefined,
        shareByTrainer: currentItem.by != 'all' ? { key: currentItem.shareByTrainer?._id, label: currentItem.shareByTrainer?.name } : undefined,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setRuleParam({
      by: undefined,
      shareByActivity: undefined,
      shareByTrainer: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const rule = {
        by: values.by,
        shareByActivity: values.shareByActivity,
        shareByTrainer: values.shareByTrainer,
      };
      if (isCreate) {
        onAdd(rule);
      } else {
        onEdit(rule);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Rule' : 'Edit Rule'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit} loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="By">
          {getFieldDecorator('by', {
            initialValue: ruleParam.by,
            rules: [
              {
                required: true,
                message: 'Please select an option!',
              },
            ],
          })(
            <Select
              placeholder="Select an option"
              onChange={val => {
                form.setFields({ shareByTrainer: undefined, shareByActivity: undefined });
                setRuleParam(prev => ({ ...prev, by: val, shareByTrainer: undefined, shareByActivity: undefined }));
              }}
            >
              <Option value="activity">Activity</Option>
              <Option value="trainer">Trainer</Option>
            </Select>
          )}
        </Form.Item>
        {ruleParam.by == 'activity' && (
          <Form.Item label="Activity">
            {getFieldDecorator('shareByActivity', {
              initialValue: ruleParam.shareByActivity,
              rules: [
                {
                  required: true,
                  message: 'Please select activity!',
                },
              ],
            })(
              <Select placeholder="Select an activity" labelInValue={true}>
                {activities.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {ruleParam.by == 'trainer' && (
          <Form.Item label="Trainer">
            {getFieldDecorator('shareByTrainer', {
              initialValue: ruleParam.shareByTrainer,
              rules: [
                {
                  required: true,
                  message: 'Please select trainer!',
                },
              ],
            })(
              <Select placeholder="Select a trainer" labelInValue={true}>
                {trainers.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
const AddEditRuleModal = Form.create<AddEditRuleModalProps>()(AddEditRuleModalComponent);
export default AddEditRuleModal;
