import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { Select, DatePicker, Button } from 'antd';

const { Option } = Select;

const { MonthPicker } = DatePicker;

const AnalyticsFilterSelect = ({
  organization,
  getType,
  startDate,
  endDate,
  trainer,
  activity,
  handleChange,
  onStartChange,
  onEndChange,
  onTrainerChange,
  onActivityChange,
  fetching,
  handleApply,
}) => {
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  useQuery(ACTIVITIES_QUERY, {
    variables: {
      organizationId: organization,
    },
    onCompleted: data => {
      setActivities(data.activities);
      // console.log('data.activities', data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    variables: {
      organizationId: organization,
    },
    onCompleted: data => setTrainers(data.trainers),
  });
  return (
    <div>
      <div style={{ display: 'flex', margin: '15px 0', justifyContent: 'flex-end' }}>
        <Select value={getType} style={{ width: 120 }} onChange={handleChange}>
          <Option value="daily">Daily</Option>
          <Option value="weekly">Weekly</Option>
          <Option value="monthly">Monthly</Option>
        </Select>
        <div style={{ margin: '0 20px' }}>
          {getType === 'daily' && (
            <>
              <DatePicker value={startDate} onChange={onStartChange} />
              <DatePicker value={endDate} onChange={onEndChange} />
            </>
          )}
          {getType === 'weekly' && (
            <>
              <DatePicker value={startDate} onChange={onStartChange} />
              <DatePicker value={endDate} onChange={onEndChange} />
            </>
          )}
          {getType === 'monthly' && (
            <>
              <MonthPicker value={startDate} onChange={onStartChange} />
              <MonthPicker value={endDate} onChange={onEndChange} />
            </>
          )}
        </div>
        <Button loading={fetching} onClick={handleApply}>
          Apply
        </Button>
      </div>
      <div style={{ display: 'flex', margin: '15px 0', justifyContent: 'flex-end' }}>
        <Select value={trainer} style={{ width: '100%', marginRight: 20 }} onChange={onTrainerChange} placeholder="Trainer" mode="multiple">
          {trainers &&
            trainers.map(item => (
              <Option key={item._id} value={item.name}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Select value={activity} style={{ width: '100%' }} onChange={onActivityChange} placeholder="Activity" mode="multiple">
          {activities &&
            activities.map(item => (
              <Option key={item._id} value={item.name}>
                {item.name}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
};

export default AnalyticsFilterSelect;
