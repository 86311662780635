import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const ALL_ACTIVITIES_QUERY = gql`
  query allActivities {
    allActivities {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
