import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'component/Loader';
import { Row, Col } from 'antd';
import { Line } from 'react-chartjs-2';
import VideoReportTable from 'component/VideoReportTable/VideoReportTable';
import ViewersLineChart from 'component/ViewersLineChart/ViewersLineChart';
import MinutesPlayedLineChart from 'component/MinutesPlayedLineChart/MinutesPlayedLineChart';
import OrganizationSelect from 'component/OrganizationSelect/OrganizationSelect';
import { getUserData } from 'helper';
import SubscriberReportTable from 'component/SubscriberReportTable/SubscriberReportTable';

const AnalyticsContainer: React.FC<any> = () => {
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [selectedOrg, setSelectedOrg] = useState<any>('all');

  return (
    <div>
      {user.role == 'superadmin' && (
        <Row style={{ margin: '15px 0' }}>
          <Col offset={1} span={10}>
            <OrganizationSelect value={selectedOrg} onChange={val => setSelectedOrg(val)} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '15px' }}>
        <Col span={22} offset={1}>
          <VideoReportTable organization={selectedOrg == 'all' ? null : selectedOrg} />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', backgroundColor: 'white' }}>
        <Col span={22} offset={1}>
          <ViewersLineChart organization={selectedOrg == 'all' ? null : selectedOrg} />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', backgroundColor: 'white' }}>
        <Col span={22} offset={1}>
          <MinutesPlayedLineChart organization={selectedOrg == 'all' ? null : selectedOrg} />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', backgroundColor: 'white' }}>
        <Col span={22} offset={1}>
          <SubscriberReportTable organization={selectedOrg == 'all' ? null : selectedOrg} />
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticsContainer;
